import { state, style, trigger } from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Inject, Output, EventEmitter,ViewChild,ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@ezteach/_services/user.service';
import { Langs } from '@ezteach/shared/services/lang-store.service';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DictionariesService } from '../api/services/dictionaries.service';
import { fromEvent,Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime, first, tap } from 'rxjs/operators';
//import { tryGetAndConvertToDate } from 'src/utils/route.utils';
import { LocalStorageService } from '../_services/local-storage.service';
import { TutorUserSearchResult } from '../api/models/tutor-user-search-result';
import { TutorUserSearchResultPagedApiResponse } from '../api/models/tutor-user-search-result-paged-api-response';
import { SearchService } from '../api/services/search.service';
import { Specialization } from '@ezteach/api/models/specialization';
import * as moment from 'moment';

export interface TutorUserSearchResultWithBookmarkStatus extends TutorUserSearchResult {
  marked: boolean;
}


@UntilDestroy()
@Component({
  selector: 'search-regular',
  templateUrl: './search-regular.component.html',
  animations: [
    trigger('whiteboard', [
      state(
        'disableFullPageMode',
        style({
          position: 'relative',
        }),
      ),
      state(
        'enableFullPageMode',
        style({
          position: 'fixed',
          minWidth: '100%',
          width: '100%',
          zIndex: 999999999,
          height: '100%',
          left: 0,
          top: 0,
        }),
      ),
    ])
  ]
})
export class SearchRegularComponent {
  @Output()
  dateFilterChanged = new EventEmitter<Date>();
  @Output()
  timeFilterChanged = new EventEmitter<string>();
  pageSize = 4;
  pageNumber = 1;
  loaded = false;

  @ViewChild('timeFilterInput', { read: ElementRef, static: true })
  timeFilterInput: ElementRef;


  results: TutorUserSearchResultPagedApiResponse;
  teachers: Array<TutorUserSearchResultWithBookmarkStatus>;

  Name: string | null;
  classesAll: Specialization[];
  DisciplinesNames: string[];
  subjects:any[]
  SpecializationId: number;
  disciplinesControl = new UntypedFormControl();
  discipline: string;
  duration = 5;
  allFiltersMenuOpen=false;
  sortingOpen=false;
  viewSettingsOpen=false;
  message: string;
  specialization;
  isList=false;
  filters: {
    dateFilter: Date;//Дата время начала занятия
    timeFilterMin: string;
    timeFilterMax: string;
    minPrice: number;
    maxPrice: number;
    minAge: number;
    maxAge: number;
    subject: string;
    classId: number;
    sex: string;
    age: string;
    stage: number;
  }

  sort:{
    field: string;
    asc: boolean;
  }

  sortMenuOpen = false;
  
  
  specializationGet;
  disciplinesFiltered: Observable<string[]>;
  autocompletePosition: 'auto' | 'above';

  specializationsControl = new UntypedFormControl();
  specializationsNames = [];

  messageControl = new UntypedFormControl();
  messageCol = 140;
  messageValue = 0;
  specDefault = this.translocoService.translate('Выберите предмет');
  bookmarks: number[];

  
  private currentScrollY: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private localStorageService: LocalStorageService,
    @Inject(WINDOW) private windowRef: Window,
    private dictionariesService: DictionariesService,
    private viewportScroller: ViewportScroller,
    private userService: UserService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.filters= {
      dateFilter: undefined,
      timeFilterMin: undefined,
      timeFilterMax: undefined,
      maxPrice:undefined, 
      minPrice: undefined, 
      maxAge:undefined, 
      minAge: undefined, 
      subject: undefined,
      classId: NaN,
      sex: undefined,
      age: undefined,
      stage: undefined,
    };
    this.sort={field:'registrationDate', asc: true}
    const userdata = this.userService.userData$.value;
    if (!userdata.isStudent) {
      this.router.navigateByUrl('/');
    }
    this.bookmarks = this.localStorageService.get('bookmarks') || [];
    this.route.queryParamMap.subscribe(queryParamMap => {
      this.Name = queryParamMap.get('name');
      this.DisciplinesNames = queryParamMap.getAll('discipline');

      

      this.SpecializationId = parseInt(queryParamMap.get('specialization') as string);
      this.bookmarks = this.localStorageService.get('bookmarks') || [];
      this.getResults();
    });

    fromEvent(this.windowRef, 'scroll')
      .pipe(
        untilDestroyed(this),
        debounceTime(300),
        tap(_ => this.searchService.scrollY$.next(this.windowRef.scrollY)),
      )
      .subscribe();

    this.searchService.scrollY$
      .pipe(
        untilDestroyed(this),
        tap(scrollY => (this.currentScrollY = scrollY)),
        first(),
      )
      .subscribe();

    this.searchService.pageNumber$
      .pipe(
        untilDestroyed(this),
        tap(pageNumber => (this.pageNumber = pageNumber)),
      )
      .subscribe();

    this.getResults();
    this.getSpecializations();
    this.getSubjects()
  }

  ngAfterViewInit() {

    
  }

  onChange() {}

  openSortMenu() {
    this.sortingOpen = true;
  }

  closeSortFilters() {
    this.sortingOpen = false;
  }

  setSort(data) {
    this.sortingOpen = false;
    this.getResults();
  }

  openViewSettings(){
    this.viewSettingsOpen=true;
  }

  closeViewSettings(){
    this.viewSettingsOpen=false;
  }


  onDisciplineChange(e) {
    if (this.filters.subject != '') {
      this.specializationsNames = this.subjects
        .filter(
          item =>
            this.translocoService
              .translate(item.discipline.fullName)
              .toLowerCase()
              .indexOf(this.filters.subject.toLowerCase()) === 0,
        )
        .map(item => item.specializations);
      if (this.specializationsNames.length > 0) {
        this.specDefault = this.translocoService.translate('Любая цель');
        this.specializationsNames = this.specializationsNames[0];
      } else {
        this.specializationsNames = [];
      }
    }

    this.specialization = null;
  }

  setFilter(value: any, name: string){//Устанавливает значение фильтра
    this.filters[name]=value;
    if (name=="classId"){
      this.getSubjects();//Если выбирают класс, перезагружаем предметы
    }
  }

  closeAllFilters() {
    this.allFiltersMenuOpen = false;
  }

  openAllFilterMenu() {
    this.allFiltersMenuOpen = true;
  }

  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }, 0, -6),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }, 0, -6),
    new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'end', overlayY: 'center' }, 0, -6),
  ];

  positionsView = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }, 0, -6),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }, 0, -6),
    new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'end', overlayY: 'center' }, 0, -6),
  ];

  rejectFilters() {
    this.filters = {
      dateFilter: undefined,
      timeFilterMin: undefined,
      timeFilterMax: undefined,
      maxPrice:undefined, 
      minPrice: undefined, 
      maxAge:undefined, 
      minAge: undefined, 
      subject: undefined,
      classId: NaN,
      sex: undefined,
      age: undefined,
      stage: undefined,
    }
    this.sort={field:'registrationDate', asc: true}
    this.getSubjects();//Если выбирают класс, перезагружаем предметы
    this.getResults();    
  }

  showFilters(){
    
    this.getResults();
  }

  setIsList(isList){
    this.isList=isList
    this.closeViewSettings();
  }

  getResults(): void {
    this.pageNumber = 1;
    if (this.filters.classId == 0)
    {
      this.filters.classId=NaN;
    }
    var disciplines: string[]=[];
    if (this.filters.subject!=undefined && this.filters.subject!=''){
      disciplines.push(this.filters.subject)
    }
    
    var date:any;
    var lessonDateTime: Date;  
    var lessonDateTimeMax: Date;  
    if (!this.filters.dateFilter) {//Если фильтр даты не заполнен, то не учитываем его
      lessonDateTime = undefined;
      lessonDateTimeMax=undefined;
    }
    else {
      date=new Date(this.filters.dateFilter);
      if (this.filters.timeFilterMin){
        lessonDateTime=new Date(moment(date).format('YYYY-MM-DD')+" "+this.filters.timeFilterMin);//Если задано время, добавляем
      }
      else{
        lessonDateTime=new Date(moment(date).format('YYYY-MM-DD')+" 8:00");//Иначе на начало дня
      }
      if (this.filters.timeFilterMax){
        lessonDateTimeMax=new Date(moment(date).format('YYYY-MM-DD')+" "+this.filters.timeFilterMax);//Если задано время, добавляем
      }
      else{
        lessonDateTimeMax=new Date(moment(date).format('YYYY-MM-DD')+" 23:00");//Иначе на конец дня
      }
    }
    this.pageNumber;
    this.searchService
      .apiV1SearchTutorsScheduledGet({      
        PriceFrom: this.filters.minPrice,
        PriceTo: this.filters.maxPrice,
        TeachingExperience: this.filters.stage,
        AgeFrom: this.filters.minAge,
        AgeTo: this.filters.maxAge,
        Gender: this.filters.sex,
        LessonDateTime: lessonDateTime,
        LessonDateTimeEnd: lessonDateTimeMax,
        DisciplinesNames:
          this.translocoService.getActiveLang() === Langs.RU
            ? disciplines
            : disciplines.map(x => this.translocoService.translate(x + '_s')),
        SpecializationId: this.filters.classId,
        PageSize: this.pageSize,
        SortBy: this.sort.field
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        console.log(results)
        this.results = results;
        this.bookmarks = this.localStorageService.get('bookmarks') || [];
        
          this.addingBookmarkInfo(results.data);
          this.searchService.pageNumber$.next(this.pageNumber++);
        
        this.loaded = true;
        this.viewportScroller.scrollToPosition([0, this.currentScrollY]);
      });
  }

  addingBookmarkInfo(data: TutorUserSearchResult[]) {
    this.teachers = data.map((teacher: any) => {
      const marked = this.bookmarks.includes(teacher.tutor.id);
      return { ...teacher, marked };
    });
  }

  bookmarksUpdated(bookmarks) {
    this.teachers.map(teacher => (teacher.marked = bookmarks.includes(teacher.tutor.id)));
  }

  dateChanged($event: Date | null): void {
    if ($event) {
      const dateStart = new Date($event.toDateString() + ' ' + this.filters.timeFilterMin);
      this.dateFilterChanged.emit(dateStart);
    }
  }

  timeChanged($event: string): void {
    const newDate = new Date(this.filters.dateFilter.toDateString() + ' ' + $event);
    this.dateFilterChanged.emit(newDate);
  }

  getSpecializations() {
    this.dictionariesService.apiV1DictionariesSpecializationsGet().subscribe(result => {
      this.classesAll=result.data.sort((a,b)=>a.id-b.id);      
    });
  }

  _filter(discipline: string): string[] {
    this.specializationsNames = [];
    this.specialization = null;
    this.specDefault = this.translocoService.translate('Выберите предмет');

    if (discipline.trim() != '') {
      const filterValue = discipline.toLowerCase();
      const data = this.DisciplinesNames.filter(discipline => discipline.toLowerCase().indexOf(filterValue) === 0);

      return data;
    } else {
      return this.DisciplinesNames.sort();
    }
  }

  getSubjects(){
    this.dictionariesService.apiV1DictionariesDisciplineSpecializationsGet().subscribe(response => {
      //this.data = response.data;
      var results=this.filters.classId? response.data.filter(r=>r.specializations.findIndex(s=>s.id==this.filters.classId)>-1) :response.data;
      this.DisciplinesNames = results.map(value => this.translocoService.translate(value.discipline.name));
      this.disciplinesFiltered = this.disciplinesControl.valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        map(discipline => this._filter(discipline)),
      );
      if (this.filters.subject) {
        this.specializationsNames = results
          .filter(item => item.discipline.fullName.toLowerCase().indexOf(this.filters.subject.toLowerCase()) === 0)
          .map(item => item.specializations);
        if (this.specializationsNames.length > 0) {
          this.specializationsNames = this.specializationsNames[0];
        } else {
          this.specializationsNames = [];
        }
      }
      if (this.specializationGet) {
        this.specializationsControl.setValue(this.specializationGet * 1);
      }
    });
  }

  getMore(): void {
    if (this.filters.classId == 0)
      {
        this.filters.classId=NaN;
      }
      var disciplines: string[]=[];
      if (this.filters.subject!=undefined && this.filters.subject!=''){
        disciplines.push(this.filters.subject)
      }
    var date:any;
    var lessonDateTime: Date;  
    var lessonDateTimeMax: Date;  
    if (!this.filters.dateFilter) {//Если фильтр даты не заполнен, то не учитываем его
      lessonDateTime = undefined;
      lessonDateTimeMax = undefined;
    }
    else {
      date=new Date(this.filters.dateFilter);
      if (this.filters.timeFilterMin){
        lessonDateTime=new Date(moment(date).format('YYYY-MM-DD')+" "+this.filters.timeFilterMin);//Если задано время, добавляем
      }
      else{
        lessonDateTime=new Date(moment(date).format('YYYY-MM-DD')+" 8:00");//Иначе на начало дня
      }
      if (this.filters.timeFilterMax){
        lessonDateTimeMax=new Date(moment(date).format('YYYY-MM-DD')+" "+this.filters.timeFilterMax);//Если задано время, добавляем
      }
      else{
        lessonDateTimeMax=new Date(moment(date).format('YYYY-MM-DD')+"23:00");//Иначе на конец дня
      }
    }
    this.searchService.pageNumber$.next(++this.pageNumber);

    this.searchService
      .apiV1SearchTutorsScheduledGet({
        PriceFrom: this.filters.minPrice,
        PriceTo: this.filters.maxPrice,
        TeachingExperience: this.filters.stage,
        AgeFrom: this.filters.minAge,
        AgeTo: this.filters.maxAge,
        Gender: this.filters.sex,
        LessonDateTime: lessonDateTime,
        LessonDateTimeEnd: lessonDateTimeMax,
        DisciplinesNames:
          this.translocoService.getActiveLang() === Langs.RU
            ? disciplines
            : disciplines.map(x => this.translocoService.translate(x + '_s')),
        SpecializationId: this.filters.classId,
        PageSize: this.pageSize,
        PageNumber: this.pageNumber,
        SortBy: this.sort.field
      })
      .subscribe((results: TutorUserSearchResultPagedApiResponse) => {
        this.loaded = true;
        this.results = results;
        // @ts-ignore
        this.teachers = this.teachers.concat(results.data || []);
        this.bookmarks = this.localStorageService.get('bookmarks') || [];
        this.teachers.map(teacher => (teacher.marked = this.bookmarks.includes(teacher.tutor.id)));
        this.searchService.searchTutorHistory$.next(this.teachers);
      });
  }
}
