<div
  *ngIf="loaded"
  class="container-wrapper"
>
  <div
    class="results_none"
    *ngIf="accessDenied; else lessonContainer"
  >
    <div class="global__icon">
      <svg-icon-sprite
        [src]="'icon-no-bookmarks'"
        [width]="'86px'"
        [height]="'80px'"
        [viewBox]="'0 0 86 80'"
      ></svg-icon-sprite>
    </div>
    <div class="global__title"> {{ 'У вас нет доступа к этому событию' | transloco }} .</div>
    <div class="global__text">{{ 'Возможно, организатор не предоставил общий доступ к данному событию' | transloco }}
    </div>
  </div>
  <ng-template #lessonContainer>
    <div
      class="container"
      [ngClass]="{ 'chat-open': chatOpened, mobile: isMobile }"
    >
      <div class="header">
        <div class="header__date">{{ lessonDate | date: 'dd.MM.YYYY' }}</div>
        <div class="datetime-divider"></div>
        <span>{{ lessonDate | date: 'h:mm' }}</span>
      </div>
      <div
        class="video-block-container"
        [ngClass]="{ 'chat-open': chatOpened && !isMobile }"
      >
        <div class="video-block">
          <div
            class="video-wrapper"
            (click)="playbackToggle()"
            [style]="{ display: isVideoAvailable ? 'block' : 'none' }"
          >
            <video
              class="video"
              [src]="videoLink"
              preload="metadata"
              #videoElement
            ></video>
            <div
              *ngIf="isMobile && !videoPlaying"
              class="play-button__mobile"
            >
              <img src="assets/img/svg/lesson/play.svg" />
            </div>
          </div>
          <div
            class="status-bar"
            *ngIf="isVideoAvailable"
          >
            <mat-slider
              aria-label="progress-bar"
              class="progress-line"
              [value]="progressValue"
              *playbackHover="playbackTime"
              (input)="handleRangeUpdate($event)"
            ></mat-slider>
            <div class="duration-info">
              <div class="current-time">{{ playbackTime }} /&nbsp;</div>
              <div class="total-time">{{ totalDuration }}</div>
            </div>
          </div>
          <div
            class="clock-block"
            *ngIf="!isVideoAvailable"
          >
            <img src="assets/img/svg/lesson/icon-time-60-92929D-new.svg" />
            <div class="global__text"> {{ 'Видео обрабатывается' | transloco }}</div>
          </div>
        </div>
        <div class="controls-block">
          <div class="controls">
            <div class="buttons">
              <div
                class="button"
                (click)="playbackToggle()"
                *ngIf="isVideoAvailable && !isMobile"
                #tooltipVideoBtn
                (mouseover)="createTooltip(tooltipBlockPlay, tooltipVideoBtn)"
                (mouseleave)="removeTooltip()"
              >
                <img
                  *ngIf="!videoPlaying"
                  src="/assets/img/svg/lesson/play.svg"
                  alt="playVideo"
                />
                <img
                  *ngIf="videoPlaying"
                  src="/assets/img/svg/lesson/pause.svg"
                  alt="pauseVideo"
                />
              </div>
              <div
                class="button"
                (click)="toggleMute()"
                #volumeButton
                [style]="{ display: !isMobile && isVideoAvailable ? 'block' : 'none' }"
              >
                <img
                  *ngIf="!muted"
                  src="/assets/img/svg/lesson/sound-on.svg"
                  alt="playVideo"
                />
                <img
                  *ngIf="muted"
                  src="/assets/img/svg/lesson/sound-off.svg"
                  alt="pauseVideo"
                />
              </div>
              <mat-slider
                #volumeSlider
                [style.display]="!isMobile && isVolumePanelVisible ? 'block' : 'none'"
                class="audio-range"
                [(ngModel)]="currentVolume"
                (change)="volumeChange($event)"
              ></mat-slider>
              <div
                class="button"
                *ngIf="isVideoAvailable"
                #tooltipFullScreen
                (mouseover)="createTooltip(tooltipBlockFullscreen, tooltipFullScreen)"
                (mouseleave)="removeTooltip()"
              >
                <img
                  src="/assets/img/svg/lesson/fullscreen.svg"
                  alt="fullscreen"
                  (click)="openFullscreen()"
                />
              </div>
              <div
                class="button"
                (click)="chatViewToggle()"
                #tooltipChat
                (mouseover)="createTooltip(tooltipBlockChat, tooltipChat)"
                (mouseleave)="removeTooltip()"
              >
                <img
                  *ngIf="!chatOpened"
                  src="/assets/img/svg/icon-messages_white.svg"
                  alt="chatOpened"
                />
                <img
                  *ngIf="chatOpened"
                  src="/assets/img/svg/icon-messages-disabled_white.svg"
                  alt="chatClosed"
                />
              </div>
              <div
                class="button"
                *ngIf="isVideoAvailable && !isMobile"
                #tooltipDownload
                (mouseover)="createTooltip(tooltipBlockDownload, tooltipDownload)"
                (mouseleave)="removeTooltip()"
              >
                <a
                  class="download_href"
                  href="{{ videoLink }}"
                  target="_blank"
                >
                  <img
                    src="/assets/img/svg/lesson/download.svg"
                    alt="download-lesson-record"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="chat"
        *ngIf="chatOpened && !isMobile"
      >
        <div
          class="messages-list__wrapper"
          [ngClass]="isOwner ? 'messages-list__wrapper-stats' : ''"
        >
          <div
            class="messages-list"
            *ngFor="let message of messageList"
          >
            <div
              class="messages-list__day-info"
              *ngIf="message.date"
            >
              {{ message.date }}
            </div>
            <div
              class="message"
              [class.my]="message.isSelf"
            >
              <div class="message__image">
                <div class="user-avatar"
                  [style.background]="message.authorAvatar"
                  alt=""
                  *ngIf="message.authorAvatar"
                ></div>
                <span
                  class="ng-star-inserted"
                  *ngIf="!message.authorAvatar"
                >{{ message.authorInitials }}</span>
              </div>
              <div class="message__author">
                <span class="name">{{ message.authorName }}</span>
                <span class="time">{{ message.time }}</span>
              </div>
              <div class="message__text messages-list__message-content">
                <div
                  class="text ng-star-inserted"
                  *ngIf="message.content"
                  [innerHTML]="message.content | linkify"
                ></div>
                <div class="messages-list__message-content__attachments">
                  <div *ngFor="let attach of message.attachments">
                    <div>
                      <div class="group-lesson-chat-attach">
                        <div *ngIf="isImage(attach); else itisDocument">
                          <a
                            href="{{ getAttachUrl(attach) }}"
                            target="_blank"
                          >
                            <div class="group-lesson-chat-attach__imgfile">
                              <img
                                class="group-lesson-chat-attach__imgfile__img"
                                (load)="imageCompleted()"
                                (error)="imageCompleted()"
                                [src]="getPreviewUrl(attach)"
                              />
                              <div
                                class="group-lesson-chat-attach__imgfile__dummy"
                                *ngIf="!imgLoaded"
                              ></div>
                              <div
                                class="group-lesson-chat-attach__download"
                                *ngIf="imgLoaded"
                              >
                                <img src="/assets/img/svg/icon-cloud.svg" />
                              </div>
                            </div>
                          </a>
                        </div>
                        <ng-template #itisDocument>
                          <a
                            href="{{ getAttachUrl(attach) }}"
                            target="_blank"
                          >
                            <div class="group-lesson-chat-attach__docfile">
                              <span class="group-lesson-chat-attach__docfile__icon">
                                <img src="/assets/img/svg/icon-file.svg" />
                              </span>
                              <span class="group-lesson-chat-attach__docfile__info">
                                <span class="group-lesson-chat-attach__docfile__name">{{ getFileName(attach) }}</span>
                                <span class="group-lesson-chat-attach__docfile__size">{{
                                  getFileSize(attach) | fileSize
                                  }}</span>
                              </span>
                              <span class="group-lesson-chat-attach__docfile__cloud">
                                <img src="/assets/img/svg/icon-cloud.svg" />
                              </span>
                            </div>
                          </a>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="isOwner"
          class="messages-list-statistic"
        >
          <ezteach-btn
            [type]="buttonType.notFilled"
            class="messages-list-statistic__btn"
            width="100%"
            (click)="getMembersStat()"
          >
            <div content>
              {{ 'Список присутствовавших' | transloco }}
            </div>
          </ezteach-btn>
          <ezteach-btn
            [type]="buttonType.notFilled"
            class="messages-list-statistic__btn"
            width="100%"
            (click)="getChatStat()"
          >
            <div content>
              {{ 'Выгрузить чат' | transloco }}
            </div>
          </ezteach-btn>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div
  class="chat-mobile"
  *ngIf="chatOpened && isMobile"
>
  <div class="messages-list__wrapper">
    <div
      class="messages-list"
      *ngFor="let message of messageList"
    >
      <div
        class="messages-list__day-info"
        *ngIf="message.date"
      >
        {{ message.date }}
      </div>
      <div
        class="message"
        [class.my]="message.isSelf"
      >
        <div class="message__image">
          <div class="user-avatar"
            [style.background]="message.authorAvatar"
            alt=""
            *ngIf="message.authorAvatar"
          ></div>
          <span
            class="ng-star-inserted"
            *ngIf="!message.authorAvatar"
          >{{ message.authorInitials }}</span>
        </div>
        <div class="message__author">
          <span class="name">{{ message.authorName }}</span>
          <span class="time">{{ message.time }}</span>
        </div>
        <div class="message__text messages-list__message-content">
          <div
            class="text ng-star-inserted"
            *ngIf="message.content"
            [innerHTML]="message.content"
          ></div>
          <div class="messages-list__message-content__attachments">
            <div *ngFor="let attach of message.attachments">
              <div>
                <div class="group-lesson-chat-attach">
                  <div *ngIf="isImage(attach); else itisDocument">
                    <a
                      href="{{ getAttachUrl(attach) }}"
                      target="_blank"
                    >
                      <div class="group-lesson-chat-attach__imgfile">
                        <img
                          class="group-lesson-chat-attach__imgfile__img"
                          (load)="imageCompleted()"
                          (error)="imageCompleted()"
                          [src]="getPreviewUrl(attach)"
                        />
                        <div
                          class="group-lesson-chat-attach__imgfile__dummy"
                          *ngIf="!imgLoaded"
                        ></div>
                        <div
                          class="group-lesson-chat-attach__download"
                          *ngIf="imgLoaded"
                        >
                          <img src="/assets/img/svg/icon-cloud.svg" />
                        </div>
                      </div>
                    </a>
                  </div>
                  <ng-template #itisDocument>
                    <a
                      href="{{ getAttachUrl(attach) }}"
                      target="_blank"
                    >
                      <div class="group-lesson-chat-attach__docfile">
                        <span class="group-lesson-chat-attach__docfile__icon">
                          <img src="/assets/img/svg/icon-file.svg" />
                        </span>
                        <span class="group-lesson-chat-attach__docfile__info">
                          <span class="group-lesson-chat-attach__docfile__name">{{ getFileName(attach) }}</span>
                          <span class="group-lesson-chat-attach__docfile__size">{{
                            getFileSize(attach) | fileSize
                            }}</span>
                        </span>
                        <span class="group-lesson-chat-attach__docfile__cloud">
                          <img src="/assets/img/svg/icon-cloud.svg" />
                        </span>
                      </div>
                    </a>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
