import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UserService } from '@ezteach/_services/user.service';
import { ChatLesson } from '@ezteach/api/models/chat-lesson';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { BlockApiResponse, BlockApiService, BlockExerciseTypeEnum, BlockTaskTypeEnum } from '@ezteach/blocks/services/block-api.service';
import { SelectedBlockService } from '@ezteach/blocks/services/selected-block.service';
import { CalendarService } from '@ezteach/calendar';
import { AddHomeworkPopupComponent } from '@ezteach/group-lesson/components/group-lesson-toolbar/add-homework-popup/add-homework-popup.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface CourseInfoOverview {
  id?: number;
  iconSrc: string;
  name: string;
  description?: string;
  count?: number;
  authorId?: number;
  author?: string;
}

export interface TaskInfo {
  id: number;
  name: string;
}

export interface BlockLesson extends ChatLesson {
  order?: number;
  historyLink?: string;
  type?: BlockExerciseTypeEnum;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
})
export class BlockComponent implements OnInit, OnDestroy {
  @ViewChild('createLessonButton') createLessonButton: ElementRef;
  @Output()
  onAddLesson = new EventEmitter<ElementRef>();
  user: any;
  buttonType = ButtonType;
  block: CourseInfoOverview = null;

  details: CourseInfoOverview[] = [
    {
      iconSrc: 'assets/img/svg/blocks/lessons.svg',
      name: 'Событий',
      count: 12,
    },
    {
      iconSrc: 'assets/img/svg/blocks/participants.svg',
      name: 'Участников',
      count: 12,
    },
    {
      iconSrc: 'assets/img/svg/blocks/certificate.svg',
      name: 'Сертификат',
    },
  ];

  tasks: TaskInfo[] = []; /*[
    {
      iconSrc: 'assets/img/svg/whiteboard/files/pdf.svg',
      name: 'Тема первого урока',
    },
    {
      iconSrc: 'assets/img/svg/whiteboard/files/pdf.svg',
      name: 'Тема второго урока',
    },
    {
      iconSrc: 'assets/img/svg/whiteboard/files/pdf.svg',
      name: 'Тема третьего урока',
    },
  ];*/

  lessons: BlockLesson[] = [
    {
      lessonFinishDate: '2023-11-13T17:27:56.278646+00:00',
      lessonStartDate: '2023-11-13T17:27:02.988147+00:00',
      subject: 'Тема события №1',
    },
    {
      lessonFinishDate: '2023-11-13T17:27:56.278646+00:00',
      lessonStartDate: '2023-11-13T17:27:02.988147+00:00',
      subject: 'Тема события №2',
    },
    {
      lessonFinishDate: '2023-11-13T17:27:56.278646+00:00',
      lessonStartDate: '2023-11-13T17:27:02.988147+00:00',
      subject: 'Тема события №3',
    },
  ];

  isMobile = false;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  editMode = false;

  constructor(
    private route: ActivatedRoute,
    private selectedBlockService: SelectedBlockService,
    private userService: UserService,
    private router: Router,
    private blockApiService: BlockApiService,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private calendarService: CalendarService,
  ) { }
  ngOnDestroy(): void {
    this.selectedBlockService.selectedBlock$.next(null);
  }

  ngOnInit(): void {
    const blockId = this.route.snapshot.queryParams.blockId;
    if (blockId && +blockId === 26812) {
      this.lessons = this.selectedBlockService.getBlockLessons(blockId);
      this.block = this.selectedBlockService.getBlock(blockId);
      this.tasks = [];
    }

    if (blockId && blockId !== 26812) {
      this.selectedBlockService.selectedBlock$.next(blockId);
      this.blockApiService
        .getBlock(blockId)
        .pipe(untilDestroyed(this))
        .subscribe(x => {
          this.mapBlockResponse(x.body.data);
        });
    }

    this.userService.userData$.pipe(untilDestroyed(this)).subscribe(x => {
      this.user = x;
    });

    this.mediaQueryService?.match$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.isMobile = x;
        }),
      )
      .subscribe();

    this.selectedBlockService.refreshBlock$
      .pipe(
        switchMap(x => this.blockApiService.getBlock(x)),
        untilDestroyed(this),
      )
      .subscribe(x => this.mapBlockResponse(x.body.data));
  }

  backToBlocks() {
    this.selectedBlockService.selectedBlock$.next(null);
    this.router.navigate(['/mylessons/blocks']);
  }

  recommend() { }

  edit() {
    // временно заглушка для теста
    if (this.block?.id === 26812) return;
    this.editMode = true;
  }

  save() { }

  cancelEdit() {
    this.editMode = false;
  }

  addLesson() {
    this.onAddLesson.emit(this.createLessonButton);
  }

  mapBlockResponse(data: any) {
    this.block = {
      id: data.id,
      iconSrc: data.preview,
      name: data.name,
      description: data.description,
      authorId: data.creator.id,
      author: data.creator.name
    };

    this.lessons = data.exercises.map(x => ({
      id: x.id,
      subject: x.title,
      type: x.type,
      scheduledLesson: x.scheduledLesson,
    }));
    this.tasks = data.tasks
      .sort((a, b) => a.id - b.id)
      .map(x => ({
        id: x.id,
        name: x.title,
      }));
    this.cdr.detectChanges();
  }

  removeLesson($event) {
    const requests = [];
    requests.push(this.blockApiService
      .deleteBlockExercise(this.block.id, $event.id)
      .pipe(untilDestroyed(this)));

    if (!!$event?.scheduledLesson?.id?.toString()?.length) {
      requests.push(this.calendarService
        .apiV1CalendarLessonsLessonIdCancelPatch({ lessonId: $event?.scheduledLesson?.id })
        .pipe(untilDestroyed(this)));
    }

    forkJoin(requests).pipe(
      tap(response => {
        if (!response?.[0]) {
          return;
        }
        const deleteBlockExerciseResponse = response?.[0] as StrictHttpResponse<BlockApiResponse>;
        this.mapBlockResponse(deleteBlockExerciseResponse?.body?.data);
      })
    ).subscribe();
  }

  removeTask(taskId: number) {
    this.blockApiService
      .deleteBlockTask(this.block.id, taskId)
      .pipe(untilDestroyed(this))
      .subscribe(x => {
        this.mapBlockResponse(x.body.data);
      });
  }

  addHomework() {
    this.matDialog
      .open(AddHomeworkPopupComponent, {
        panelClass: this.isMobile ? 'homework-mobile' : '',
        data: { isBlock: true, isMobile: this.isMobile },
      })
      .afterClosed()
      .pipe(
        switchMap(x => {
          if (!!x) {
            const request = {
              blockId: this.block.id,
              title: x.name,
              description: x.description,
              type: BlockTaskTypeEnum.None,
              metaData: JSON.stringify({
                homeworkTemplateId: x.id,
              }),
            };
            return this.blockApiService.addBlockTask(request);
          } else {
            return of(null);
          }
        }),
        switchMap((x: any) => {
          if (!!x) {
            return this.blockApiService.getBlock(this.block.id);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(x => {
        if (x) {
          this.mapBlockResponse(x.body.data);
        }
      });
  }
}
