import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { ActivatedRoute } from '@angular/router';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { UserService } from '@ezteach/_services/user.service';
import { ChatLesson } from '@ezteach/api/models';
import { ChatLessonMemberRole } from '@ezteach/api/models/chat-lesson-member-permisson';
import { ChatMessage } from '@ezteach/api/models/chat-message';
import { ChatMessageAttachment, ChatMessageAttachmentUrls } from '@ezteach/api/models/chat-message-attachment';
import { AnalyticsService } from '@ezteach/api/services/analytics.service';
import { ChatService } from '@ezteach/api/services/chat.service';
import { FilesService } from '@ezteach/api/services/files.service';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { SelectedBlockService } from '@ezteach/blocks/services/selected-block.service';
import { IMessage } from '@ezteach/group-lesson/components/group-lesson-chat/group-lesson-chat.component';
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';
import { UserInitialsPipe } from '@ezteach/shared/pipes/user-initials.pipe';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, fromEvent, of } from 'rxjs';
import { catchError, filter, first, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import tippy from 'tippy.js';

enum PlaybackTimeType {
  current = 'current',
  total = 'total',
}

enum TimeoutState {
  null = 'null',
  start = 'start',
}

@UntilDestroy()
@Component({
  selector: 'ezteach-video-view',
  templateUrl: './video-view.component.html',
  styleUrls: ['./video-view.component.scss'],
  providers: [UserAvatarBackgroundPipe, UserInitialsPipe],
})
export class VideoViewComponent implements OnInit {
  lessonDate: string;
  file = false;
  loaded = false;
  chatOpened = true;
  messageList: IMessage[] = [];
  messageDates: string[] = [];
  myId: number;
  videoLink: string;
  lessonDuration: string;
  videoPlaying = false;
  totalDuration: string;
  muted = false;
  progressValue = 0;
  currentVolume: number;
  playbackTime: string;
  timeoutClosePanelID: any;
  isVolumePanelVisible = false;
  imgLoaded: boolean;
  isVideoAvailable = true;
  accessDenied = false;

  currentVolume$: BehaviorSubject<number> = new BehaviorSubject<number>(50);

  videoElementRef: ElementRef;
  volumeButton: ElementRef;
  volumeSlider: MatSlider;
  isMobile = false;

  buttonType = ButtonType;
  lessonId: number;
  isOwner = false;
  volumeSliderEntered = false;
  volumeButtonEntered = false;

  isBlockLesson = false;

  @ViewChild('videoElement') set setVideoElement(content: ElementRef) {
    this.videoElementRef = content;
    if (this.videoElementRef) {
      this.currentVolume = this.videoElementRef.nativeElement.volume * 100;
      this.initalizeEventsForVideoElement(content);
    }
  }

  @ViewChild('volumeButton') set setVolumeButton(content: ElementRef) {
    this.volumeButton = content;
    if (this.volumeButton) {
      this.initializeVolumeButton();
    }
  }

  @ViewChild('volumeSlider') set setVolumeSliderRef(content: MatSlider) {
    this.volumeSlider = content;
    if (this.volumeSlider) {
      this.initializeVolumeSlider();
    }
  }
  tooltipIsCreated = false;
  currentTooltip: any;
  tooltipBlockPlay: string;
  tooltipBlockDownload: string;
  tooltipBlockChat: string;
  tooltipBlockFullscreen: string;

  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private lessonsService: LessonsService,
    private chatService: ChatService,
    private filesService: FilesService,
    @Inject(WINDOW) private windowRef: Window,
    private breakPointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService,
    private readonly userAvatarPipe: UserAvatarBackgroundPipe,
    private readonly userInitialsPipe: UserInitialsPipe,
    private userService: UserService,
    private selectedBlockService: SelectedBlockService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.translocoService.events$
      .pipe(
        filter(e => e.type === 'translationLoadSuccess'),
        first(),
        tap(() => {
          this.tooltipBlockPlay = this.translocoService.translate('Смотреть');
          this.tooltipBlockDownload = this.translocoService.translate('Скачать');
          this.tooltipBlockChat = this.translocoService.translate('Чат');
          this.tooltipBlockFullscreen = this.translocoService.translate('Полноэкранный режим');
        })
      ).subscribe()
      this.tooltipBlockPlay = this.translocoService.translate('Смотреть');
      this.tooltipBlockDownload = this.translocoService.translate('Скачать');
      this.tooltipBlockChat = this.translocoService.translate('Чат');
      this.tooltipBlockFullscreen = this.translocoService.translate('Полноэкранный режим');
    const blockLessonId = this.route.snapshot.queryParams.blockLessonId;

    if (blockLessonId) {
      const lesson = this.selectedBlockService.getBlockLesson(+blockLessonId);
      this.loaded = true;
      this.accessDenied = false;
      this.videoLink = lesson.historyLink;
      this.isBlockLesson = true;
      this.chatOpened = false;
      return;
    }

    this.loaded = false;
    this.accessDenied = false;
    this.me();
    const lessonId = parseInt(this.route.snapshot.paramMap.get('id'));
    this.lessonId = lessonId;
    this.updateLink(lessonId);

    this.loadLesson(lessonId)
      .pipe(
        tap(l => {
          this.lessonDate = l.lessonStartDate;
          this.lessonDuration = this.duration(l);
          const memberMe = l.members.find(x => x.user.id === this.myId);
          this.isOwner = memberMe?.role === ChatLessonMemberRole.Owner;
        }),
        switchMap(l => this.loadLessonMessages(l.chatRoomId)),
        tap(m => {
          this.messageList = m.reverse().map(message => this.getUpdatedMessage(message));
        }),
        catchError(err => {
          this.accessDenied = true;
          return of('');
        }),
      )
      .subscribe(_ => {
        this.loaded = true;
      });

    this.currentVolume$
      .pipe(
        untilDestroyed(this),
        tap(v => this.isMuted(v)),
      )
      .subscribe(v => (this.currentVolume = v));

    this.breakPointObserver
      .observe('(max-width: 1279.9px)')
      .pipe(untilDestroyed(this), pluck('matches'))
      .subscribe(matches => {
        this.isMobile = matches;
        if (this.isMobile) {
          this.chatOpened = false;
        }
      });
      
  }

  initalizeEventsForVideoElement(content: ElementRef) {
    fromEvent(content.nativeElement, 'loadedmetadata')
      .pipe(
        untilDestroyed(this),
        map(v => v as Event),
        tap(v => {
          const durationInSeconds = (v.target as HTMLVideoElement).duration;
          this.specifyPlaybackTime(durationInSeconds, PlaybackTimeType.total);
          this.specifyPlaybackTime(0, PlaybackTimeType.current);
        }),
      )
      .subscribe();

    fromEvent(content.nativeElement, 'timeupdate')
      .pipe(
        untilDestroyed(this),
        tap(() => this.handleProgress()),
      )
      .subscribe();
  }

  initializeVolumeSlider() {
    fromEvent(this.volumeSlider._elementRef.nativeElement, 'mouseenter')
      .pipe(
        untilDestroyed(this),
        filter(() => !this.muted),
        tap(() => {
          this.volumeSliderEntered = true;
        }),
      )
      .subscribe();

    fromEvent(this.volumeSlider._elementRef.nativeElement, 'mouseleave')
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.volumeSliderEntered = false;
          this.countdownTimerState(TimeoutState.start);
        }),
      )
      .subscribe();
  }

  initializeVolumeButton() {
    fromEvent(this.volumeButton.nativeElement, 'mouseenter')
      .pipe(
        untilDestroyed(this),
        filter(() => !this.muted && !this.volumeSliderEntered),
        tap(() => {
          this.volumeButtonEntered = true;
          this.isVolumePanelVisible = true;
          this.countdownTimerState(TimeoutState.null);
        }),
      )
      .subscribe();

    fromEvent(this.volumeButton.nativeElement, 'mouseleave')
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.volumeButtonEntered = false;
          this.countdownTimerState(TimeoutState.start);
        }),
      )
      .subscribe();
  }

  isImage(attachment) {
    return attachment.attachmentTypeId === 'Picture';
  }

  getFileName(attachment) {
    return attachment.sourceFileName;
  }

  getFileSize(attachment) {
    return attachment.fileSize;
  }

  getAttachUrl(attachment) {
    if (attachment.fileName) {
      //небезопасно. Хранить какой-то уникальный идентификатор сессии который будет на беке транспонироваться в токен доступа.
      return attachment.fileName + '?access_token=' + this.oauthService.getAccessToken();
    }
    return null;
  }

  imageCompleted() {
    this.imgLoaded = true;
  }

  getPreviewUrl(attachment) {
    if (attachment.previewFileName) {
      //небезопасно. Нужно видимо хранить какой-то уникальный идентификатор сессии который будет на беке транспонироваться в токен доступа.
      return attachment.previewFileName + '?access_token=' + this.oauthService.getAccessToken(); //this.sanitizer.bypassSecurityTrustResourceUrl(attachment.currentValue.previewFileName + '?access_token=' + this.oauthService.getAccessToken());
    }
    return null;
  }

  countdownTimerState(state) {
    if (state === TimeoutState.null) {
      this.deleteTimeout();
      return;
    }

    this.timeoutClosePanelID = setTimeout(() => {
      if (!this.volumeSliderEntered && !this.volumeButtonEntered) {
        this.isVolumePanelVisible = false;
      }
      this.deleteTimeout();
    }, 1000);
  }

  deleteTimeout() {
    if (this.timeoutClosePanelID) {
      this.windowRef.clearTimeout(this.timeoutClosePanelID);
    }
  }

  handleRangeUpdate(event) {
    this.progressValue = event.value;
    this.videoElementRef.nativeElement.currentTime =
      (this.videoElementRef.nativeElement.duration * +this.progressValue) / 100;
  }

  volumeChange(event) {
    this.currentVolume$.next(event.value);
    this.setVolume(event.value);
  }

  isMuted(value: number) {
    if (value === 0) {
      this.muted = true;
      return;
    }
    this.muted = false;
  }

  handleProgress() {
    this.progressValue =
      (this.videoElementRef.nativeElement.currentTime / this.videoElementRef.nativeElement.duration) * 100;
    this.specifyPlaybackTime(this.videoElementRef.nativeElement.currentTime, PlaybackTimeType.current);
  }

  specifyPlaybackTime(value: number, type: PlaybackTimeType): void {
    const playbackState = moment.duration(value, 'seconds');
    if (type === PlaybackTimeType.total) {
      this.totalDuration = moment.utc(playbackState.as('milliseconds')).format('HH:mm:ss');
    }
    if (type === PlaybackTimeType.current) {
      this.playbackTime = moment.utc(playbackState.as('milliseconds')).format('HH:mm:ss');
    }
  }

  me() {
    this.myId = this.userService.userData$.value.id;
  }

  loadLesson(lessonId): Observable<ChatLesson> {
    return this.lessonsService
      .apiV1LessonsHistoryLessonIdGet({
        lessonId,
      })
      .pipe(map(r => r.data));
  }

  loadLessonMessages(chatRoomId): Observable<ChatMessage[]> {
    return this.chatService
      .apiV1ChatChatRoomIdMessagesGet({
        chatRoomId,
        PageNumber: 1,
        PageSize: 100,
        DescendingOrder: true,
      })
      .pipe(map(d => d.data));
  }

  async loadLessonThenLoadMessages(lessonId) {
    this.updateLink(lessonId);
    this.accessDenied = false;
    this.lessonsService
      .apiV1LessonsHistoryLessonIdGet({
        lessonId,
      })
      .pipe(
        switchMap(response => {
          this.lessonDate = response.data.lessonStartDate;

          this.lessonDuration = this.duration(response.data);

          return this.chatService.apiV1ChatChatRoomIdMessagesGet({
            chatRoomId: response.data.chatRoomId,
            PageNumber: 1,
            PageSize: 100,
            DescendingOrder: true,
          });
        }),
        tap(({ data }) => {
          this.messageList = data.reverse().map(message => this.getUpdatedMessage(message));
        }),
      )
      .subscribe(
        _ => { },
        err => {
          this.accessDenied = err.status === 403;
        },
      );
  }

  getUpdatedMessage(sourceMessage: ChatMessage): IMessage {
    const message = {} as IMessage;
    message.id = Guid.create();
    message.isSelf = sourceMessage.sentByMember.memberId === this.myId;
    message.authorAvatar = this.userAvatarPipe.transform(sourceMessage.sentByMember);
    message.authorInitials = this.userInitialsPipe.transform(sourceMessage.sentByMember);
    message.authorName = sourceMessage.sentByMember.name;
    message.content = sourceMessage.messageText;
    message.time = moment(Date.parse(sourceMessage.publicationDate)).format('HH:mm');
    message.date = !this.messageDates.find(
      date => date === moment(Date.parse(sourceMessage.publicationDate)).format('DD MMM'),
    )
      ? moment(Date.parse(sourceMessage.publicationDate)).format('DD MMM')
      : '';
    if (!this.messageDates.find(date => date === message.date)) {
      this.messageDates.push(message.date);
    }
    message.attachments = [...sourceMessage.attachments];
    return message;
  }

  download($event: ChatMessageAttachment) {
    if ($event) {
      this.chatService
        .apiV1ChatMessagesMessageIdAttachmentsAttachmentUrlsGet({
          messageId: $event.chatMessageId,
          attachmentId: $event.id,
        })
        .pipe(
          tap((x: ChatMessageAttachmentUrls) => {
            window.open(x.fileName, '_blank');
          }),
          catchError(e => {
            console.log(e);
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  updateLink(id) {
    this.filesService
      .apiV1FilesLessoncontentOwnerEntityIdContentTypeGet({
        ownerEntityId: id,
        contentType: 'media',
      })
      .pipe(
        untilDestroyed(this),
        catchError(_ => {
          this.isVideoAvailable = false;
          return EMPTY;
        }),
      )
      .subscribe(request => {
        this.videoLink = request.data;
      });
  }

  chatViewToggle() {
    this.removeTooltip();
    this.chatOpened = !this.chatOpened;
    this.tooltipBlockChat = this.translocoService.translate(this.chatOpened ? 'Закрыть чат' : 'Чат');
  }

  duration(lesson) {
    const duration = moment(lesson.lessonFinishDate).diff(moment(lesson.lessonStartDate)) / 1000;
    const hours = Math.floor((duration / (60 * 60)) % 24);
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);
    const time = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

    return time;
  }

  playbackToggle() {
    this.removeTooltip();
    this.videoElementRef.nativeElement[this.videoPlaying ? 'pause' : 'play']();
    this.videoPlaying = !this.videoPlaying;
    this.tooltipBlockPlay = this.translocoService.translate(this.videoPlaying ? 'Пауза' : 'Смотреть');
  }

  openFullscreen() {
    this.videoElementRef.nativeElement.requestFullscreen();
  }

  toggleMute() {
    if (!this.muted) {
      this.muted = true;
      this.setVolume(0);
      this.isVolumePanelVisible = false;
      return;
    }

    this.muted = false;
    this.setVolume(this.currentVolume);
  }

  setVolume(value: number) {
    // добавляем деление на 100 потому что для volume валидно значение от 0 до 1;
    this.videoElementRef.nativeElement.volume = value / 100;
  }

  getMembersStat() {
    this.analyticsService
      .getMembers(this.lessonId)
      .pipe(
        tap(x => {
          this.downLoadCsvBlob(x.body);
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  getChatStat() {
    this.analyticsService
      .getChat(this.lessonId)
      .pipe(
        tap(x => {
          this.downLoadCsvBlob(x.body);
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  downLoadCsvBlob(data) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    window.open(window.URL.createObjectURL(blob), '_blank');
  }
  getTooltipOptions(tooltipContent: string) {
    return {
      content: tooltipContent,
      allowHTML: true,
      theme: 'tooltip',
    };
  }
  createTooltip(tooltipContent: string, el: Element) {
    if (!el) {
      return;
    }
    if (this.tooltipIsCreated) {
      return;
    }
    this.currentTooltip = tippy(el, this.getTooltipOptions(tooltipContent));
    this.tooltipIsCreated = true;
  }

  removeTooltip() {
    if (!this.currentTooltip) {
      return;
    }
    this.currentTooltip.destroy();
    this.currentTooltip = undefined;
    this.tooltipIsCreated = false;
  }
}
