//import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { SignalrCalendarService } from '@ezteach/_services/signalr.calendar.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from '@ezteach/_services/user.service';
import { LessonFormatEnum, ScheduledLessonStatusEnum, Tutor } from '@ezteach/api/models';
import { LessonPaymentTypeEnum } from '@ezteach/api/models/lesson-payment-type-enum';
import { UsersService } from '@ezteach/api/services/users.service';
import { CalendarEventTypeEnum, IDateRange } from '@ezteach/calendar/models';
import { CalendarPopupService, CalendarService } from '@ezteach/calendar/services';
import { CalendarApiService } from '@ezteach/calendar/services/calendar-api.service';
import {
  AccessToArchiveLesson,
  ChatLessonPrivacy,
  ChatLessonPublisherPolicy,
  SelectedPublishingPolicy,
} from '@ezteach/group-lesson/services/group-lesson.service';
import { IProfileData } from '@ezteach/profile/models';
import { ProfileApiService } from '@ezteach/profile/services/profile-api.service';
import { Langs, LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { Delay, delay } from '@ezteach/utils';
import { Calendar, DateSelectArg, DayHeaderContentArg, EventApi } from '@fullcalendar/core';
import kkLocale from '@fullcalendar/core/locales/kk';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as cuid from 'cuid';
import { pipe } from 'fp-ts/lib/function';
import { getOrElse, map } from 'fp-ts/lib/Option';
import Inputmask from 'inputmask';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { append, compose, concat, not, path, pathEq, pathOr, propEq, range, filter as Rfilter } from 'ramda';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  delay as Rxdelay,
  map as rxMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import tippy, { Instance, Props } from 'tippy.js';
import { GroupLessonOptions, PopupMode } from '../create-meeting-popup';
import { dateTimeToApiText, fixFullcalendarBugOffset, fromCalendarPseudoLocalToUtc } from '../utils/calendar.utils';
import { DAY_NAMES_SHORT_EN, DAY_NAMES_SHORT_KK, DAY_NAMES_SHORT_RU } from './calendar.local';

interface ICalendarCreateEvent {
  startDate: Date;
  endDate: Date;
  isPreCreate: boolean;
  preId: string;
  calendarEventType: CalendarEventTypeEnum;
}

export interface ICreateCalendarMeetingPayload extends IDateRange {
  id: string;
}

const isPreCreate = pathOr(false, ['event', '_def', 'extendedProps', 'props', 'isPreCreate']);

@UntilDestroy()
@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Input() user;
  @Input() type;
  userProfile: IProfileData;

  private _meetingsToCreate: ICalendarCreateEvent[] = [];
  private _meetingsToCreateTimeChangeSource = new Subject<ICreateCalendarMeetingPayload>();
  private _popupPositionUpdateSource = new Subject<void>();
  private _popupCreateMeetingOpenSource = new Subject<ICalendarCreateEvent>();
  private _calendarScrollerElement: HTMLElement;
  // флаг который предотвращает лишние создание событий в календаре при многократном клике по ячейке selectHook
  private _isCreatingEvent = false;
  // объект используемый студентом для хранения данные о занятии на которое он будет регистрироваться
  private studentSelectedItemEvent: EventApi = undefined;

  //fix: перенести. Нужно только для локализации
  private readonly teacherText = this.translocoService.translate('Организатор');
  private readonly classDateText = this.translocoService.translate('Дата события');
  private readonly classTimeText = this.translocoService.translate('Время события');
  private readonly classCostText = this.translocoService.translate('Стоимость');
  private readonly sendRequestForClassText = this.translocoService.translate('Отправить запрос');
  private readonly cancelChangesText = this.translocoService.translate('Отменить');
  //private readonly copyLinkToClassText = this.translocoService.translate('Скопировать');
  private readonly studentText = this.translocoService.translate('Ученик');
  private readonly linkToClassText = this.translocoService.translate('Ссылка на занятие');
  private readonly acceptFreeClassText = this.translocoService.translate('Принять');
  private readonly declineClassText = this.translocoService.translate('Отказать');
  private readonly acceptPayableText = this.translocoService.translate('Принять с оплатой');
  private readonly startClassText = this.translocoService.translate('Начать событие');

  private readonly todayNameKey = 'Эта неделя';

  loaded = false;
  preShown = -1;
  userdata;
  fixedElementPosition = false;
  private preEvent = null;
  calendar;
  selectable = true;
  editable = false;
  editMode = false;
  tutor;
  widget = false;
  timeGrid = {
    timeGridWeek: 'Недели',
    dayGridMonth: 'Месяц',
  };

  typeView = 'timeGridWeek';
  $typeView = new BehaviorSubject(this.typeView);

  dialogRef: MatDialogRef<any>;

  isMobile = false;

  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  get canPayout() {
    return this.userProfile?.data?.bankCardBindings?.bankCards?.length > 0;
  }

  private mutationObserver: MutationObserver;
  private readonly selectHook$ = new Subject<DateSelectArg>();
  private tippyInstance: Instance<Props>;

  constructor(
    private calendarService: CalendarService,
    private usersService: UsersService,
    private signalrCalendarService: SignalrCalendarService,
    private signalrService: SignalrService,
    private readonly profileApi: ProfileApiService,
    private _cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private calendarPopupService: CalendarPopupService,
    private route: ActivatedRoute,
    //private clipboard: Clipboard,
    public dialog: MatDialog,
    private zone: NgZone,
    private router: Router,
    private renderer: Renderer2,
    private calendarApiService: CalendarApiService,
    @Inject(WINDOW) private windowRef: Window,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private documentRef: Document,
    private langStoreService: LangStoreService,
    private datePipe: DatePipe,
    private userService: UserService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.preShown = params['showTooltip'];
    });
  }

  /* Try to find html element */
  private async locateElement(selector: string, tries: number = 5): Promise<HTMLElement> {
    for (const _ of range(1, tries)) {
      await delay(500);
      const nativeElement = document.querySelector<HTMLElement>(selector);
      if (nativeElement) {
        return nativeElement;
      }
    }

    return null;
  }

  /* Opens create meeting popup and proceed with creating new meeting */
  private async openCreateMeetingPopup(event: ICalendarCreateEvent) {
    this.preEvent = event;//Сохраняем event;
    const nativeElement: HTMLElement = await this.locateElement(`.${event.preId}`);
    if (!nativeElement) {
      console.warn(`[CalendarComponent.openCreateMeetingPopup()]: Unable to locate element with class ${event.preId}`);
      return;
    }
    const containerEl = this._calendarScrollerElement;
    /* Stream of data when meeting time changes */
    const startWithInitialTime = startWith<ICreateCalendarMeetingPayload>({
      start: event.startDate as Date,
      end: event.endDate as Date,
      id: event.preId,
    });
    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = this._meetingsToCreateTimeChangeSource
      .asObservable()
      .pipe(
        /* Proceed only with needed events */
        filter(({ id }) => id === event.preId),
        /* Update local state data */
        startWithInitialTime,
        shareReplay(1),
      );
    const updatePosition$ = this._popupPositionUpdateSource.asObservable();

    const tutor = this.userdata.tutor as Tutor;
    const disciplines = tutor.disciplineSpecializations.map(d => d.discipline);

    const groupLessonSettings: GroupLessonOptions = {
      selectedDisciplineId: disciplines.length > 0 ? disciplines[0].id : 0,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy: {
        publisher: ChatLessonPublisherPolicy.Any,
        lessonPrivacy: ChatLessonPrivacy.Public,
        accessToArchiveLesson: AccessToArchiveLesson.MembersOnly,
        lessonWillbeRecorded: true
      },
    };
    const currentMeetings = this.calendar
      .getEvents()
      .filter((x: any) => x._def.extendedProps.props.preId !== event.preId);
    this.calendarPopupService
      .openCreateMeetingPopup(
        nativeElement,
        {
          meetingTimeChange$,
          updatePosition$,
          groupLessonOptions: groupLessonSettings,
          canEditTime: true,
          currentMeetings,
          popupMode: PopupMode.Create,
        },
        containerEl,
      )
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => { this.calendar.refetchEvents(); this._isCreatingEvent = false; });

    const byPreIdNotEquals = compose(not, propEq('preId', event.preId));
    this._meetingsToCreate = Rfilter<ICalendarCreateEvent>(byPreIdNotEquals, this._meetingsToCreate);
  }

  async openCreatedMeetingPopup(
    nativeElement: HTMLElement,
    joinIdentity: string,
    inviteIdentity: string,
    disciplineId: number,
    startDate: Date,
    endDate: Date,
    lessonId: string,
    selectedPublishingPolicy?: SelectedPublishingPolicy,
    subject?: string,
    homeWorkTemplateId?: number,
    invitedUsers?: any[]
  ) {
    if (!nativeElement) {
      return;
    }
    const tutor = this.userdata.tutor as Tutor;
    const startWithInitialTime = startWith<ICreateCalendarMeetingPayload>({
      start: new Date(startDate),
      end: new Date(endDate),
      id: '',
    });
    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = this._meetingsToCreateTimeChangeSource
      .asObservable()
      .pipe(
        /* Update local state data */
        startWithInitialTime,
        shareReplay(1),
      );

    const updatePosition$ = this._popupPositionUpdateSource.asObservable();
    const groupLessonSettings: GroupLessonOptions = {
      lessonId: +lessonId,
      joinIdentity,
      inviteIdentity,
      selectedDisciplineId: disciplineId,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy,
      meetingIsCreated: true,
      subject,
      homeWorkTemplateId,
      invitedUsers
    };
    const popupRef = this.calendarPopupService.openCreateMeetingPopup(
      nativeElement,
      {
        meetingTimeChange$,
        updatePosition$,
        groupLessonOptions: groupLessonSettings,
        canEditTime: true,
        popupMode: PopupMode.Edit,
      },
      this._calendarScrollerElement,
    );
    const popupRefCloseData = await popupRef.afterClosed().toPromise();

    if (popupRefCloseData === null && joinIdentity) {
      this.cancelLesson(lessonId);
    }

    if (popupRefCloseData === null && !joinIdentity) {
      this.removeSlot(lessonId);
    }

    this.calendar.refetchEvents();
  }

  /* Configure and trigger popup */
  private configureNewMeeting(start: Date, end: Date) {
    const meeting: ICalendarCreateEvent = {
      preId: cuid(),
      isPreCreate: true,
      startDate: start,
      endDate: end,
      calendarEventType: CalendarEventTypeEnum.Available,
    };
    /* Add meeting to temporary array */
    this._meetingsToCreate = append(meeting, this._meetingsToCreate);
    this._popupCreateMeetingOpenSource.next(meeting);
  }

  renderEvent(srcEvent) {
    let display = 'auto';
    let editable = false;
    let isForeigner = false;

    if (this.user && srcEvent.calendarEventType === CalendarEventTypeEnum.Available && !this.editMode) {
      display = 'background';
    }

    const isMe = !this.user || this.userdata.id === this.user?.id;
    const amITutor = this.userdata.isTutor;

    if (isMe && amITutor) {
      editable = this.editMode;
    } else {
      if (srcEvent.calendarEventType === CalendarEventTypeEnum.ScheduledLesson) {
        const srcEventUser = this.userdata.isStudent ? srcEvent?.studentUser : srcEvent?.tutorUser;
        const isItMineEvent = srcEventUser?.id === this.userdata.id;

        editable = isItMineEvent;
        isForeigner = !isItMineEvent;
      }
    }

    return {
      id: srcEvent.id,
      title: srcEvent.title ?? '',
      start: moment(srcEvent.startDate).toDate(),
      end: moment(srcEvent.endDate).toDate(),
      display,
      extendedProps: {
        eventType: srcEvent.calendarEventType,
        description: srcEvent.description,
        isPast: srcEvent.isPast,
        isForeigner,
        statusId: srcEvent.statusId,
        props: srcEvent,
      },
      editable,
    };
  }

  loadEvents(info, success, failure) {
    if (!this.calendar) {
      return;
    }

    const start = info.start;
    const end = info.end;
    let mode = 'Cabinet';
    let member = null;
    if (this.user && this.user !== this.userdata.id) {
      mode = 'Scheduling';
      member = this.user;
    }
    this.calendarApiService.loadEvents(start, end, member, mode).subscribe(result => {
      const srcEvents = concat(result.data, this._meetingsToCreate);
      const destEvents = [];
      if (typeof srcEvents == typeof []) {
        srcEvents.forEach(srcEvent => {
          destEvents.push(this.renderEvent(srcEvent));
        });
        success(destEvents);
      }

      if (this.user && this.userdata.id !== this.user) {
        this.calendar.setOption('editable', false);
      }
    });
  }

  editSlots() {
    if (this.editMode) {
      this.calendarPopupService.hideCurrentPopup();
    }
    if (this.userdata.isTutor) {
      this.editMode = !this.editMode;
      this.calendar.changeView(this.typeView);
      if (this.editMode) {
        this.calendar.setOption('selectable', true);
      } else {
        this.calendar.setOption('selectable', false);
      }

      this.calendar.refetchEvents();
    }
  }

  saveSlot(id, start, end) {
    const startDateText = dateTimeToApiText(fromCalendarPseudoLocalToUtc(start));
    const endDateText = dateTimeToApiText(fromCalendarPseudoLocalToUtc(end));

    this.calendarService
      .apiV1CalendarSlotsEventIdPut({
        eventId: id,
        body: {
          Id: id,
          StartDate: startDateText,
          EndDate: endDateText,
        },
      })
      .subscribe(
        result => {
          this.calendar.refetchEvents();
        },
        error => {
          this.calendar.refetchEvents();
        },
      );
  }

  removeSlot(eventId) {
    this.calendarService
      .apiV1CalendarSlotsEventIdDelete({
        eventId,
      })
      .subscribe(result => {
        this.calendar.refetchEvents();
      });
  }

  createLesson(date, start, end, discipline) {
    this.calendarService
      .apiV1CalendarLessonsPost({
        body: {
          LessonDate: date,
          LessonStartTime: start,
          LessonEndTime: end,
          TutorUserId: this.tutor.id,
          DisciplineId: discipline,
        },
      })
      .subscribe(
        result => {
          this.calendar.refetchEvents();
          this.preEvent = null;
        },
        error => {
          this.calendar.refetchEvents();
          this.preEvent = null;
        },
      );
  }

  saveLesson(id, start, end) {
    const lessonDateStartUtc = fromCalendarPseudoLocalToUtc(start);
    const lessonDateStartUtcText = dateTimeToApiText(lessonDateStartUtc.clone().startOf('day'));
    const lessonDateEndUtc = fromCalendarPseudoLocalToUtc(end);

    this.calendarService
      .apiV1CalendarLessonsLessonIdPut({
        lessonId: id,
        body: {
          Id: id,
          LessonDate: lessonDateStartUtcText,
          LessonStartTime: lessonDateStartUtc.format('HH:mm'),
          LessonEndTime: lessonDateEndUtc.format('HH:mm'),
        },
      })
      .subscribe(
        result => {
          this.calendar.refetchEvents();
        },
        error => {
          this.calendar.refetchEvents();
        },
      );
  }

  editLesson(id, date, start, end) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdPut({
        lessonId: id,
        body: {
          Id: id,
          LessonDate: date,
          LessonStartTime: start,
          LessonEndTime: end,
        },
      })
      .subscribe(result => {
        this.calendar.refetchEvents();
      });
  }

  //go to lesson
  goToLesson(joinIdentity) {
    this.router.navigate(['/wait'], {
      queryParams: { id: joinIdentity },
      relativeTo: this.route,
    });
  }

  acceptLesson(id) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdAcceptPatch({
        lessonId: id,
        paymentType: LessonPaymentTypeEnum.Payable,
      })
      .subscribe(result => {
        this.calendar.refetchEvents();
        this.goToLesson(result.data?.joinIdentity);
      });
  }

  acceptFreeLesson(id) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdAcceptPatch({
        lessonId: id,
        paymentType: LessonPaymentTypeEnum.Free,
      })
      .subscribe(result => {
        this.calendar.refetchEvents();
      });
  }

  cancelLesson(id) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdCancelPatch({
        lessonId: id,
      })
      .subscribe(result => {
        this.calendar.refetchEvents();
      });
  }

  updateNowIndicator() {
    const str = moment(Date.now()).format('HH:mm');
    if (document.querySelector('.fc-timegrid-now-indicator-line')) {
      const element = document.querySelector('.fc-timegrid-now-indicator-line') as HTMLElement;
      element.dataset.nowIndicator = str;
    }
  }

  updateUserProfile(): Observable<IProfileData> {
    return this.profileApi.getProfile().pipe(
      untilDestroyed(this),
      tap(x => {
        this.userProfile = x;
      }),
    );
  }

  scrollTop() {
    if (!this._calendarScrollerElement) {
      return;
    }
    this._calendarScrollerElement.scrollTop -= 100;
    this._popupPositionUpdateSource.next();
  }

  scrollBottom() {
    if (!this._calendarScrollerElement) {
      return;
    }
    this._calendarScrollerElement.scrollTop += 100;
    this._popupPositionUpdateSource.next();
  }

  @Delay(500)
  onContainerResize() {
    this._popupPositionUpdateSource.next();
  }

  ngOnDestroy() {
    this.calendarPopupService.hideCurrentPopup();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  ngOnInit() {
    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
      if (this.isMobile) {
        if (document.querySelector('.fc-scrollgrid-section-sticky > td')) {
          this.renderer.removeClass(document.querySelector('.fc-scrollgrid-section-sticky > td'), 'sticky');
        }
        if (document.querySelector('.fc-header-toolbar')) {
          this.renderer.removeClass(document.querySelector('.fc-header-toolbar'), 'sticky');
        }
      }
    });


    this.zone.runOutsideAngular(() => {
      fromEvent(this.windowRef, 'scroll')
        .pipe()
        .subscribe(x => {
          if (
            this.isMobile &&
            !this.widget &&
            document.querySelector('.calendar').getBoundingClientRect().top <=
            7 * parseFloat(getComputedStyle(document.documentElement).fontSize)
          ) {
            if (!this.fixedElementPosition) {
              this.zone.run(() => {
                this.fixedElementPosition = true;
                this.renderer.addClass(document.querySelector('.fc-scrollgrid-section-sticky > td'), 'sticky');
                this.renderer.addClass(document.querySelector('.fc-header-toolbar'), 'sticky');
              });
            }
          }
          if (
            this.isMobile &&
            !this.widget &&
            document.querySelector('.calendar').getBoundingClientRect().top >
            7 * parseFloat(getComputedStyle(document.documentElement).fontSize)
          ) {
            if (this.fixedElementPosition) {
              this.zone.run(() => {
                this.fixedElementPosition = false;
                this.renderer.removeClass(document.querySelector('.fc-scrollgrid-section-sticky > td'), 'sticky');
                this.renderer.removeClass(document.querySelector('.fc-header-toolbar'), 'sticky');
              });
            }
          }
        });
    });

    setTimeout(() => {
      this.$typeView.subscribe(viewType => {
        this.calendar.changeView(viewType);
      });
    }, 1000);

    this.updateUserProfile().subscribe();

    if (this.type === 'widget') {
      this.widget = true;
    }

    this.userdata = this.userService.userData$.value;
    if (this.userdata.isTutor) {
      this.selectable = true;
      this.editable = true;
    }

    if (this.user) {
      this.selectable = true;

      this.usersService
        .apiV1UsersUserIdGet({
          userId: this.user,
          mode: 'public',
        })
        .subscribe(response => {
          this.tutor = response.data;
        });
    }
    /* Stream to open new Create meeting popup */
    this._popupCreateMeetingOpenSource
      .asObservable()
      .pipe(
        untilDestroyed(this),
        debounceTime(250),
        switchMap(meeting => {
          this._isCreatingEvent = false;
          /* Action to open new popup */
          const openPopup = tap(() => {
            this.openCreateMeetingPopup(meeting);
          });
          const maybeOpenedPopup = this.calendarPopupService.openedPopup;

          return pipe(
            maybeOpenedPopup,
            map(currentPopup => {
              /* After current popup closed open new  */
              const closed$ = currentPopup.afterClosed().pipe(openPopup);
              /* In short time close current popup */
              const closing$ = of(true).pipe(
                Rxdelay(50),
                tap(() => { currentPopup.close(); this._isCreatingEvent = false; }),
              );

              return merge(closing$, closed$);
            }),
            /* If none just open new popup */
            getOrElse(() => of(true).pipe(openPopup)),
          );
        }),
      )
      .subscribe(() => {
        this.calendar.refetchEvents();
      });

    this.signalrCalendarService.connect(this.user);
    this.signalrCalendarService.connectionEstablished.subscribe(data => {
      this.signalrCalendarService.enter();

      this.signalrCalendarService.onCalendarEventCanceled.subscribe(data => {
        this.calendar.refetchEvents();
      });

      this.signalrCalendarService.onCalendarEventAccepted.subscribe(data => {
        this.calendar.refetchEvents();
      });

      this.signalrCalendarService.onCalendarAvailabilityPreferenceCreated.subscribe(data => {
        this.calendar.refetchEvents();
      });

      this.signalrCalendarService.onCalendarAvailabilityPreferenceDeleted.subscribe(data => {
        this.calendar.refetchEvents();
      });

      this.signalrCalendarService.onCalendarEventCreated.subscribe(data => {
        this.calendar.refetchEvents();
      });

      this.signalrCalendarService.onCalendarEventUpdated.subscribe(data => {
        this.calendar.refetchEvents();
      });
      this.signalrService.onUserNotificationCreated.subscribe(data => {
        this.calendar.refetchEvents();
      });
    });

    this.createCalendar();

    this.calendarPopupService.createNewEvent$.pipe(untilDestroyed(this)).subscribe(() => {
      // Проверяет если это групповое событие, то не запускает refetchEvents,
      // потому что в этом случае теряется элемент относительно которого позиционируется попап.
      if (this.calendarPopupService.isGroupLesson$.value) {
        return;
      }
      this.calendar.refetchEvents();
    });

    this.subscribeTranslate();
    this.selectHook$
      .pipe(
        tap(x => {
          if (this.userdata.isTutor) {
            this.calendar.unselect();
          }
        }),
        debounceTime(0),
        distinctUntilChanged(
          (prev, curr) =>
            this.tippyInstance &&
            prev.start.getTime() === curr.start.getTime() &&
            prev.end.getTime() === curr.end.getTime(),
        ),
        untilDestroyed(this),
      )
      .subscribe(x => {
        if (!this._isCreatingEvent) {
          this.selectHook(x);
        }
      });

    this.calendarApiService.refetchCalendarEvents$
      .pipe(
        untilDestroyed(this),
        tap(_ => this.calendar.refetchEvents()),
      )
      .subscribe();
  }

  createCalendar() {
    const self = this;
    const currentTime = moment().subtract(3, 'hours').format('HH:mm:ss');
    setTimeout(() => {
      const calendarEl = document.querySelector('.calendar_el') as HTMLElement;
      self.calendar = new Calendar(calendarEl, {
        timeZone: 'local',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'title',
          right: 'today prev,next',
        },
        views: {
          dayGridMonth: {
            buttonText: 'Месяц',
          },
          timeGridWeek: {
            buttonText: 'Неделя',
            duration: self.deviceService.isMobile() ? { days: 3 } : null,
          },
          myButton: {
            buttonText: 'slots',
          },
        },
        firstDay: 1,
        nowIndicator: true,
        // slotMinTime: '09:00:00',
        // slotMaxTime: '19:00:00',
        scrollTime: currentTime,
        locales: [kkLocale],
        locale: this.langStoreService.getCurrentLang(),
        slotLabelInterval: {
          hours: 1,
        },
        contentHeight: self.type === 'widget' ? '63rem' : 'auto',
        height: self.type === 'widget' ? '63rem' : 'auto',
        allDaySlot: false,
        slotDuration: '00:30:00',
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        longPressDelay: 300,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        dayHeaderContent(x) {
          // отвечает за отображение названия дней в режиме dayGridMonth
          const lg = self.langStoreService.getCurrentLang();
          return self.getHeaderContent(lg, x);
        },
        buttonText: {
          today: this.translocoService.translate(this.todayNameKey),
        },
        buttonIcons: {
          prev: 'left-single-arrow',
          next: 'right-single-arrow',
        },

        initialView: self.typeView,
        titleFormat(date) {
          // кастомные заголовки форматов сделаны для того чтобы переводить заголовок календаря

          // если диапазон где год и месяц равны
          if (date.start.year === date.end?.year && date.start.month === date.end?.month) {
            return self.datePipe.transform(date.date.marker, 'LLL yyyy');
          }

          // если год равен но месяцы разные
          if (date.start.year === date.end?.year && date.start.month !== date.end?.month) {
            return (
              self.datePipe.transform(date.start.marker, 'LLL') +
              date.defaultSeparator +
              self.datePipe.transform(date.end?.marker, 'LLL yyyy')
            );
          }

          // если переходный год
          return (
            self.datePipe.transform(date.start.marker, 'LLL yyyy') +
            date.defaultSeparator +
            self.datePipe.transform(date.end?.marker, 'LLL yyyy')
          );
        },
        selectable: self.selectable,
        editable: self.editable,
        eventResizableFromStart: true,
        unselectAuto: false,
        viewDidMount(view) {
          self._calendarScrollerElement = document.querySelector('.calendar .fc-scroller-liquid-absolute');
          const top = document.querySelector('.fc-top') as HTMLDivElement;
          const bottom = document.querySelector('.fc-bottom') as HTMLDivElement;

          if (self.type === 'widget') {
            document.querySelector('.calendar').classList.add('inserted');
          }

          if (top) {
            top.style.display = view.view.type === self.typeView ? 'block' : 'none';
          }
          if (bottom) {
            bottom.style.display = view.view.type === self.typeView ? 'block' : 'none';
          }
          if (self.isMobile) {
            document.querySelectorAll('colgroup').forEach(node => node.classList.add('mobile-time-range'));
          }
        },
        datesSet() {
          if (self.calendar.view.type !== 'dayGridMonth') {
            document.querySelector('.calendar').classList.remove('dayGridMonth');
            document.querySelectorAll('.fc-col-header-cell').forEach(item => {
              const date = (item as HTMLElement).dataset.date;
              item.querySelector('.fc-col-header-cell-cushion').innerHTML =
                '<b>' +
                moment(date).format('DD') +
                '</b>' +
                '<span>' +
                self.datePipe.transform(date, 'EEEEEE') +
                '</span>';
            });
          } else {
            document.querySelector('.calendar').classList.add('dayGridMonth');
          }
          self.calendar.updateSize();
        },
        select: info => this.onSelectHook(info),
        selectAllow: info => this.selectAllowHook(info),
        eventAllow: (dropInfo, draggedEvent) => this.eventAllowHook(dropInfo, draggedEvent),
        events: (info, success, failure) => {
          self.loadEvents(info, success, failure);
        },
        eventClick: info => this.eventClickHook(info),
        eventResize: info => this.eventResizeHook(info),
        eventResizeStart: info => this.eventResizeStartHook(info),
        eventResizeStop: info => this.eventResizeStopHook(info),
        eventDrop: info => this.eventDropHook(info),
        eventClassNames: info => this.eventClassNamesHook(info),
        eventContent: info => this.eventContentHook(info),
        eventMouseEnter: info => this.eventMouseEnterHook(info),
        eventMouseLeave: info => this.eventMouseLeaveHook(info),
      });
      self.calendar.render();
      self.calendar.refetchEvents();

      setInterval(() => {
        self.updateNowIndicator();
      }, 1000);
      this.editSlots();
      self._cdr.detectChanges();
    }, 200);
  }

  /*
   * Hook добавляет кастомное представление для событий календаря
   */
  eventContentHook(info) {
    const arrayOfDomNodes = [];


    if (info.event._def.extendedProps.eventType === 'Available') {
      const content = document.createElement('div');
      content.classList.add('fc-content');

      const time = document.createElement('div');
      time.classList.add('fc-time');
      time.innerHTML = info.timeText;
      content.appendChild(time);

      if (this.userdata.isTutor) {
        const menu = document.createElement('div');
        menu.classList.add('fc-menu');
        menu.innerHTML = '';
        arrayOfDomNodes.push(menu);
      }

      arrayOfDomNodes.push(content);
    }


    if (['ScheduledLesson', 'InvitationScheduledLessons'].includes(info.event._def.extendedProps.eventType)) {
      const content = document.createElement('div');
      content.classList.add('fc-content');

      const contentWrapper = document.createElement('div');
      contentWrapper.classList.add('fc-content-wrapper');

      const contentLegend = document.createElement('div');
      contentLegend.classList.add('fc-content-wrapper-legend');

      const title = document.createElement('div');
      title.classList.add('fc-title');
      title.innerHTML = this.translocoService.translate(info.event._def.title);

      contentWrapper.appendChild(contentLegend);
      contentWrapper.appendChild(title);

      const start = moment(info.event._def.extendedProps.props.startDate);
      const end = moment(info.event._def.extendedProps.props.endDate);
      const duration = moment.duration(end.diff(start));
      if (duration.asMinutes() > 30) {
        const time = document.createElement('div');
        time.classList.add('fc-time');
        time.innerHTML = info.timeText;
        contentWrapper.appendChild(time);
      }

      content.appendChild(contentWrapper);

      const isGroupLesson = info.event._def.extendedProps.props.lessonFormat === LessonFormatEnum.Group;

      if (info.event._def.extendedProps.isForeigner) {
        // Если для участника будет сильно отличаться слот то нужно добавлять элементы тут
      } else {
        if (
          !info.event._def.extendedProps.isPast &&
          info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Initiated &&
          !isGroupLesson
        ) {
          const menu = document.createElement('div');
          menu.classList.add('fc-menu-pending');
          menu.innerHTML = '';
          arrayOfDomNodes.push(menu);
        }
        if (
          !info.event._def.extendedProps.isPast &&
          info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Accepted &&
          !isGroupLesson
        ) {
          const approved = document.createElement('div');
          approved.classList.add('fc-approved');
          arrayOfDomNodes.push(approved);
        }
        if (
          !info.event._def.extendedProps.isPast &&
          info.event._def.extendedProps.statusId !== ScheduledLessonStatusEnum.Initiated &&
          info.event._def.extendedProps.statusId !== ScheduledLessonStatusEnum.Accepted &&
          !isGroupLesson
        ) {
          const menu = document.createElement('div');
          menu.classList.add('fc-menu');
          menu.innerHTML = '';
          arrayOfDomNodes.push(menu);
        }
        if (
          !info.event._def.extendedProps.isPast &&
          isGroupLesson &&
          info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Initiated
        ) {
          const menu = document.createElement('div');
          menu.classList.add('fc-menu');
          menu.innerHTML = '';
          const startBlock = document.createElement('a');
          startBlock.href = window.location.origin + '/wait?id=' + info.event._def.extendedProps.props.joinIdentity;
          startBlock.classList.add('fc-start-block');
          startBlock.innerHTML = this.translocoService.translate('Перейти');
          arrayOfDomNodes.push(menu, startBlock);
        }
        if (
          !info.event._def.extendedProps.isPast &&
          isGroupLesson &&
          info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.LessonStarted
        ) {
          const startBlock = document.createElement('a');
          startBlock.href = window.location.origin + '/wait?id=' + info.event._def.extendedProps.props.joinIdentity;
          startBlock.classList.add('fc-start-block');
          startBlock.innerHTML = this.translocoService.translate('Перейти');
          arrayOfDomNodes.push(startBlock);
          content.classList.add('started');
        }
      }


      arrayOfDomNodes.push(content);
      if (this.preShown != -1 && info.event._def.extendedProps.props.id == this.preShown) {
        const link = window.location.origin + '/wait?id=' + info?.event?._def?.extendedProps?.props?.joinIdentity;
        tippy(content, {
          content: 'Загружаем ...',
          interactive: true,
          allowHTML: true,
          maxWidth: 'none',
          hideOnClick: true,
          trigger: 'click',
          appendTo: content,
          onCreate: instance => {
            this.tippyInstance = instance;
            instance.show();
          },
          onHide: instance => {
            this.calendar.unselect();
          },
          onHidden: instance => {
            this.tippyInstance = null;
            instance.destroy();
          },
          onShow: instance => {
            let resultHtml = '';
            resultHtml = `<div class="open-popup-container" style="width:400px" id="preShownEvent">
    <div class="discipline">${info.event._def.extendedProps.props.title}

      <span
        class="close" id="closePopup"

      >
        <img
          src="assets/img/svg/icon-close_dark.svg"
          alt="closeButton"
        />
      </span>
    </div>
    <div class="theme">
      ${info.event._def.extendedProps.props.subject}
    </div>
    <div class="info">
      <div class="date-time">
        <div class="icon">
          <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1.6h-.833V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234A.784.784 0 0 0 10.5.8v.8h-5V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234.784.784 0 0 0-.244.566v.8H3c-.663 0-1.299.253-1.768.703A2.352 2.352 0 0 0 .5 4v9.6c0 .636.263 1.247.732 1.697.47.45 1.105.703 1.768.703h10c.663 0 1.299-.253 1.768-.703.469-.45.732-1.06.732-1.697V4c0-.637-.263-1.247-.732-1.697A2.554 2.554 0 0 0 13 1.6ZM3 3.2h.833V4c0 .212.088.416.244.566.157.15.369.234.59.234a.851.851 0 0 0 .589-.234A.784.784 0 0 0 5.5 4v-.8h5V4c0 .212.088.416.244.566.156.15.368.234.59.234a.851.851 0 0 0 .589-.234.784.784 0 0 0 .244-.566v-.8H13c.221 0 .433.084.59.234.155.15.243.354.243.566v3.2H2.167V4c0-.212.087-.416.244-.566A.851.851 0 0 1 3 3.2Zm10 11.2H3a.851.851 0 0 1-.59-.234.784.784 0 0 1-.243-.566V8.8h11.666v4.8a.784.784 0 0 1-.244.566.852.852 0 0 1-.589.234Z"
              fill="#678AFF"
            />
            <path
              d="M4.8 12.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM11.167 10.5H7.833a.851.851 0 0 0-.589.234A.784.784 0 0 0 7 11.3c0 .212.088.416.244.566.156.15.368.234.59.234h3.333a.851.851 0 0 0 .589-.234A.784.784 0 0 0 12 11.3a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234Z"
              fill="#678AFF"
            />
          </svg>
        </div>
        <div
          class="date"
          *ngIf="lessonDate"
        >
          ${this.datePipe.transform(info.event._def.extendedProps.props.startDate, 'dd MMM EEEE')}
        </div>
        <div
          class="time"
          *ngIf="lessonDateTime"
        >
          ${moment(info.event._def.extendedProps.props.startDate).format('HH:mm')}
        </div>
      </div>
      <div
        class="type-privacy"
        *ngIf="data?.lessonFormatId !== 'Scheduled'"
      >
        <div class="type">
          ${this.translocoService.translate(info.event._def.extendedProps.props.publishingPolicy?.publisher === 'Any' ? 'Семинар' : 'Лекция')}
        </div>

        <div class="publicLesson">
          ${this.translocoService.translate(info.event._def.extendedProps.props.publishingPolicy?.lessonPrivacy != "Private" ? 'Открытое занятие' : 'Закрытое занятие')}
        </div>
      </div>
    </div>

    <div class="members-wrapper">
      <div
        class="members"
        *ngIf="tutorName"
      >
        <div class="icon">
          <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
              stroke="#678AFF"
              stroke-width="2"
            />
            <circle
              cx="8"
              cy="4"
              r="3"
              stroke="#678AFF"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="text-title">
          ${this.translocoService.translate('Репетитор')}
        </div>
      </div>
      <div class="members-list">
        ${info.event._def.extendedProps.props.tutorUser.name}
      </div>
    </div>`+ (info.event._def.extendedProps.props?.invitedUsers?.length > 1 ?

                `<div
      class="members-wrapper"
      *ngIf="countMembers > 0"
    >
      <div class="members">
        <div class="icon">
          <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
              stroke="#678AFF"
              stroke-width="2"
            />
            <circle
              cx="8"
              cy="4"
              r="3"
              stroke="#678AFF"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="text-title">
          ${this.translocoService.translate(info.event._def.extendedProps.props?.invitedUsers?.length > 1 ? 'Участники ' + info.event._def.extendedProps.props?.invitedUsers?.length : 'Участники')}
        </div>
      </div>
      <div class="members-list">
        ${this.translocoService.translate("Всего участников") + " " + info.event._def.extendedProps.props?.invitedUsers?.length}
      </div>
    </div>`: "") +

              `<div
      class="control"
    >
      <button id="lessonbutton"
        [type]="buttonType.filled"
        [width]="'100%'"
        [height]="'38px'"
      >
        <div content>${this.translocoService.translate('Перейти')}</div>
      </button>
    </div>
  </div>`

            /*resultHtml =
              '<div class="cal_popup">' +
              '<div class="cal_popup__title">' +
              info.event._def.extendedProps.props.title +
              '</div>' +
              `<div class="cal_popup__val"><span>${this.teacherText}</span>` +
              info.event._def.extendedProps.props.tutorUser.name +
              '</div>' +
              '<div class="cal_popup__val date">' +
              '<div class="cal_popup__view">' +
              `<img src="../../../../assets/img/svg/calendar-outline.svg" alt="calendar-outline.svg">
                <div>
                  ${this.datePipe.transform(info.event._def.extendedProps.props.startDate, 'dd MMM EEEE')}
                </div>` +
              '</div>' +
              '</div>' +
              `<div class="cal_popup__val time">
                <div class="cal_popup__view">
                  <img src="../../../../assets/img/svg/clock-outline.svg" alt="clock-outline.svg">
                  <div>
                    ${moment(info.event._def.extendedProps.props.startDate).format('HH:mm')}  &nbsp \u2013 &nbsp
                    ${moment(info.event._def.extendedProps.props.endDate).format('HH:mm')}
                  </div>

                </div>
             </div>

            <div class="cal_popup__link-block" title="${this.copyLinkToClassText}">
              ${link.length > 25 ? link.slice(0, 25) + '...' : link}
              <svg class="cal_popup__link-img" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 7.125H11.875V4.48875C11.8729 3.92879 11.6495 3.39236 11.2536 2.99641C10.8576 2.60045 10.3212 2.37709 9.76125 2.375H4.48875C3.92879 2.37709 3.39236 2.60045 2.99641 2.99641C2.60045 3.39236 2.37709 3.92879 2.375 4.48875V9.76125C2.37709 10.3212 2.60045 10.8576 2.99641 11.2536C3.39236 11.6495 3.92879 11.8729 4.48875 11.875H7.125V14.25C7.125 14.8799 7.37522 15.484 7.82062 15.9294C8.26602 16.3748 8.87011 16.625 9.5 16.625H14.25C14.8799 16.625 15.484 16.3748 15.9294 15.9294C16.3748 15.484 16.625 14.8799 16.625 14.25V9.5C16.625 8.87011 16.3748 8.26602 15.9294 7.82062C15.484 7.37522 14.8799 7.125 14.25 7.125ZM7.125 9.5V10.2917H4.48875C4.34807 10.2917 4.21316 10.2358 4.11369 10.1363C4.01422 10.0368 3.95833 9.90193 3.95833 9.76125V4.48875C3.95833 4.34807 4.01422 4.21316 4.11369 4.11369C4.21316 4.01422 4.34807 3.95833 4.48875 3.95833H9.76125C9.90193 3.95833 10.0368 4.01422 10.1363 4.11369C10.2358 4.21316 10.2917 4.34807 10.2917 4.48875V7.125H9.5C8.87011 7.125 8.26602 7.37522 7.82062 7.82062C7.37522 8.26602 7.125 8.87011 7.125 9.5Z" fill="#678AFF"/>
              </svg>
            </div>
            <div class="cal_popup__info">
              <div class="info__icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.9997 0C3.13422 0 0 3.13422 0 6.9997C0 10.8652 3.13422 14 6.9997 14C10.8652 14 14 10.8652 14 6.9997C14 3.13422 10.8652 0 6.9997 0ZM8.45689 10.8486C8.09659 10.9908 7.80978 11.0987 7.59467 11.1733C7.38015 11.248 7.13067 11.2853 6.84681 11.2853C6.41067 11.2853 6.07111 11.1787 5.82933 10.9659C5.58756 10.7532 5.46726 10.4836 5.46726 10.1559C5.46726 10.0284 5.47615 9.89807 5.49393 9.76533C5.5123 9.63259 5.54133 9.48326 5.58104 9.31556L6.032 7.72267C6.0717 7.56978 6.10607 7.42459 6.13333 7.28948C6.16059 7.15318 6.17363 7.02815 6.17363 6.91437C6.17363 6.7117 6.13156 6.56948 6.048 6.48948C5.96326 6.40948 5.80385 6.37037 5.56622 6.37037C5.45007 6.37037 5.33037 6.38756 5.2077 6.4237C5.08622 6.46104 4.98074 6.49481 4.89422 6.528L5.01333 6.03733C5.30844 5.91704 5.59111 5.81393 5.86074 5.72859C6.13037 5.64207 6.38519 5.59941 6.62519 5.59941C7.05837 5.59941 7.39259 5.70489 7.62785 5.91348C7.86193 6.12267 7.97985 6.39467 7.97985 6.72889C7.97985 6.79822 7.97156 6.9203 7.95556 7.09452C7.93956 7.26933 7.90933 7.42874 7.86548 7.57511L7.41689 9.16326C7.38015 9.29067 7.34756 9.43644 7.31793 9.59941C7.28889 9.76237 7.27467 9.88682 7.27467 9.97037C7.27467 10.1813 7.32148 10.3253 7.4163 10.4018C7.50993 10.4782 7.67407 10.5167 7.90637 10.5167C8.016 10.5167 8.13867 10.4972 8.27733 10.4593C8.41481 10.4213 8.51437 10.3876 8.57719 10.3585L8.45689 10.8486ZM8.37748 4.40237C8.1683 4.59674 7.91644 4.69393 7.62193 4.69393C7.328 4.69393 7.07437 4.59674 6.86341 4.40237C6.65363 4.208 6.54756 3.97156 6.54756 3.69541C6.54756 3.41985 6.65422 3.18281 6.86341 2.98667C7.07437 2.78993 7.328 2.69215 7.62193 2.69215C7.91644 2.69215 8.16889 2.78993 8.37748 2.98667C8.58667 3.18281 8.69156 3.41985 8.69156 3.69541C8.69156 3.97215 8.58667 4.208 8.37748 4.40237Z"
                    fill="#678AFF"/>
                </svg>
              </div>
            ${this.translocoService.translate(copyLinkMessage)}</div>
            `;*/
            instance.setContent(resultHtml);
          },
          onShown() {
            document.getElementById("preShownEvent").scrollIntoView();
            document.querySelector('#closePopup').addEventListener('click', () => {

              var elements = document.querySelectorAll('div[id^="tippy"]');
              if (elements.length > 0) {
                elements[0].remove()
              }
            });
            document.querySelector('#lessonbutton').addEventListener('click', () => {
              window.location.href = link;
            });
          },
        });
      }
    }

    return {
      domNodes: arrayOfDomNodes,
    };
  }
  /*
   * Hook добавляет кастомное стили для событий календаря
   */
  eventClassNamesHook(info) {
    const isGroupLesson = info.event._def.extendedProps.props.lessonFormat === LessonFormatEnum.Group;
    /* Classlist for pre created meeting */
    if (
      pathEq(['event', '_def', 'extendedProps', 'eventType'], 'Available', info) &&
      this.editMode &&
      isPreCreate(info)
    ) {
      //  для привязки попапа
      return ['fc-event-slot-edit', info.event._def.extendedProps.props.preId];
    }
    if (info.event._def.extendedProps.eventType === 'Available') {
      return [
        'fc-event-slot',
        'fc-event-slot-available',
        info.event._def.extendedProps.isPast ? 'fc-event-slot-available-past' : '',
        info.event._def.extendedProps.isPast ? 'fc-event-past' : '',
      ];
    }

    // групповое событие
    if (info.event._def.extendedProps.eventType === 'ScheduledLesson' && isGroupLesson) {
      return ['fc-event-lesson', 'fc-event-group', info.event._def.extendedProps.isPast ? 'fc-event-past' : ''];
    }
    if (
      info.event._def.extendedProps.eventType === 'ScheduledLesson' &&
      !isGroupLesson &&
      info.event._def.title === 'null'
    ) {
      return ['fc-event-lesson', 'fc-event-private-lesson'];
    }
    // 1 на 1 событие
    if (
      info.event._def.extendedProps.eventType === 'ScheduledLesson' &&
      !isGroupLesson &&
      info.event._def.title !== 'null'
    ) {
      return ['fc-event-lesson', 'fc-event-onelesson'];
    }
    return [];
  }

  eventAllowHook(dropInfo, draggedEvent) {
    const evts = this.calendar.getEvents();
    let available = false;
    let overlapped = false;

    if (this.editMode) {
      evts
        .filter(v => v.extendedProps.eventType === 'Available')
        .forEach(evt => {
          available = true;
          return;
        });
    } else {
      evts
        .filter(v => v.extendedProps.eventType === 'Available')
        .forEach(evt => {
          if (dropInfo.start >= evt.start && dropInfo.end <= evt.end) {
            available = true;
            return;
          }
        });
      if (this.userdata.isTutor) {
        available = false;
      }
    }

    if (available) {
      evts
        .filter(
          v =>
            v.id !== draggedEvent.id &&
            v.extendedProps.eventType === 'ScheduledLesson' &&
            (v.extendedProps.statusId === ScheduledLessonStatusEnum.Accepted || !v.extendedProps.isForeigner),
        )
        .forEach(evt => {
          if (dropInfo.end > evt.start && dropInfo.start < evt.end) {
            overlapped = true;
            return;
          }
        });
    }

    let date = true;
    if (draggedEvent._def.extendedProps.eventType === 'Available') {
      date = true;
    } else {
      date = dropInfo.start >= new Date() && draggedEvent.start >= new Date();
    }

    return available && !overlapped && date;
  }

  selectHook(info) {
    this._isCreatingEvent = true;
    if (this.editMode && this.userdata.isTutor) {
      this.configureNewMeeting(info.start, info.end);

      this.calendar.unselect();
    }
  }

  onSelectHook(info) {
    if (this.userdata.isTutor) {
      this.selectHook$.next(info);
      return;
    }

    this.eventClickHook({ ...info, event: this.studentSelectedItemEvent });
  }

  selectAllowHook(info) {
    const evts = this.calendar.getEvents();
    let available = false;
    if (this.userdata.isTutor) {
      available = this.editMode;
    } else {
      evts.forEach(evt => {
        if (info.start >= evt.start && info.end <= evt.end && evt.extendedProps.eventType === 'Available') {
          available = true;
          return;
        }
      });
    }
    return available && info.start >= new Date();
  }

  eventClickHook(info) {
    //const clipboard = this.clipboard;
    const link = window.location.origin + '/wait?id=' + info?.event?._def?.extendedProps?.props?.joinIdentity;
    //const copyLinkMessage = $localize`Скопируйте эту ссылку для группового занятия`;
    //const linkCopiedText = this.translocoService.translate('Ссылка скопирована');
    const isGroupLesson = info.event._def.extendedProps.props.lessonFormat === LessonFormatEnum.Group;
    /* Do nothing if is pre created meeting */
    if (isPreCreate(info)) {
      if (this.preEvent != null) {
        this.openCreateMeetingPopup(this.preEvent)
      }
      return;
    }
    if (
      this.userdata.isStudent &&
      ((info.event._def.extendedProps.eventType === 'ScheduledLesson' && info.event._def.extendedProps.isForeigner)
        || (info.event._def.extendedProps.eventType === 'InvitationScheduledLessons' && !info.event._def.extendedProps.isForeigner)) &&
      !info.event._def.extendedProps.isPast
    ) {
      if (info.event._def.title === 'null') {
        return;
      }
      if (!info.el) {
        this.calendar.unselect();
        return;
      }
      tippy(info.el, {
        content: 'Загружаем ...',
        interactive: true,
        allowHTML: true,
        maxWidth: 'none',
        hideOnClick: true,
        trigger: 'click',
        appendTo: document.body,
        onCreate: instance => {
          this.tippyInstance = instance;
          instance.show();
        },
        onHide: instance => {
          this.calendar.unselect();
        },
        onHidden: instance => {
          this.tippyInstance = null;
          instance.destroy();
        },
        onShow: instance => {
          let resultHtml = '';
          resultHtml = `<div class="open-popup-container">
  <div class="discipline">${info.event._def.extendedProps.props.title}

    <span
      class="close" id="closePopup"

    >
      <img
        src="assets/img/svg/icon-close_dark.svg"
        alt="closeButton"
      />
    </span>
  </div>
  <div class="theme">
    ${info.event._def.extendedProps.props.subject}
  </div>
  <div class="info">
    <div class="date-time">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.6h-.833V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234A.784.784 0 0 0 10.5.8v.8h-5V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234.784.784 0 0 0-.244.566v.8H3c-.663 0-1.299.253-1.768.703A2.352 2.352 0 0 0 .5 4v9.6c0 .636.263 1.247.732 1.697.47.45 1.105.703 1.768.703h10c.663 0 1.299-.253 1.768-.703.469-.45.732-1.06.732-1.697V4c0-.637-.263-1.247-.732-1.697A2.554 2.554 0 0 0 13 1.6ZM3 3.2h.833V4c0 .212.088.416.244.566.157.15.369.234.59.234a.851.851 0 0 0 .589-.234A.784.784 0 0 0 5.5 4v-.8h5V4c0 .212.088.416.244.566.156.15.368.234.59.234a.851.851 0 0 0 .589-.234.784.784 0 0 0 .244-.566v-.8H13c.221 0 .433.084.59.234.155.15.243.354.243.566v3.2H2.167V4c0-.212.087-.416.244-.566A.851.851 0 0 1 3 3.2Zm10 11.2H3a.851.851 0 0 1-.59-.234.784.784 0 0 1-.243-.566V8.8h11.666v4.8a.784.784 0 0 1-.244.566.852.852 0 0 1-.589.234Z"
            fill="#678AFF"
          />
          <path
            d="M4.8 12.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM11.167 10.5H7.833a.851.851 0 0 0-.589.234A.784.784 0 0 0 7 11.3c0 .212.088.416.244.566.156.15.368.234.59.234h3.333a.851.851 0 0 0 .589-.234A.784.784 0 0 0 12 11.3a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234Z"
            fill="#678AFF"
          />
        </svg>
      </div>
      <div
        class="date"
        *ngIf="lessonDate"
      >
        ${this.datePipe.transform(info.event._def.extendedProps.props.startDate, 'dd MMM EEEE')}
      </div>
      <div
        class="time"
        *ngIf="lessonDateTime"
      >
        ${moment(info.event._def.extendedProps.props.startDate).format('HH:mm')}
      </div>
    </div>

    <div
      class="type-privacy"
      *ngIf="data?.lessonFormatId !== 'Scheduled'"
    >
      <div class="type">
        ${this.translocoService.translate(info.event._def.extendedProps.props.publishingPolicy?.publisher === 'Any' ? 'Семинар' : 'Лекция')}
      </div>

      <div class="publicLesson">
        ${this.translocoService.translate(info.event._def.extendedProps.props.publishingPolicy?.lessonPrivacy != "Private" ? 'Открытое занятие' : 'Закрытое занятие')}
      </div>
    </div>
  </div>

  <div class="members-wrapper">
    <div
      class="members"
      *ngIf="tutorName"
    >
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
            stroke="#678AFF"
            stroke-width="2"
          />
          <circle
            cx="8"
            cy="4"
            r="3"
            stroke="#678AFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text-title">
        ${this.translocoService.translate('Репетитор')}
      </div>
    </div>
    <div class="members-list">
      ${info.event._def.extendedProps.props.tutorUser.name}
    </div>
  </div>`+ (info.event._def.extendedProps.props?.invitedUsers?.length > 1 ?

              `<div
    class="members-wrapper"
    *ngIf="countMembers > 0"
  >
    <div class="members">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
            stroke="#678AFF"
            stroke-width="2"
          />
          <circle
            cx="8"
            cy="4"
            r="3"
            stroke="#678AFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text-title">
        ${this.translocoService.translate(info.event._def.extendedProps.props?.invitedUsers?.length > 1 ? 'Участники ' + info.event._def.extendedProps.props?.invitedUsers?.length : 'Участники')}
      </div>
    </div>
    <div class="members-list">
      ${this.translocoService.translate("Всего участников") + " " + info.event._def.extendedProps.props?.invitedUsers?.length}
    </div>
  </div>`: "") +

            `<div
    class="control"
  >
    <button id="lessonbutton"
      [type]="buttonType.filled"
      [width]="'100%'"
      [height]="'38px'"
    >
      <div content>${this.translocoService.translate('Перейти')}</div>
    </button>
  </div>
</div>`

          /*resultHtml =
            '<div class="cal_popup">' +
            '<div class="cal_popup__title">' +
            info.event._def.extendedProps.props.title +
            '</div>' +
            `<div class="cal_popup__val"><span>${this.teacherText}</span>` +
            info.event._def.extendedProps.props.tutorUser.name +
            '</div>' +
            '<div class="cal_popup__val date">' +
            '<div class="cal_popup__view">' +
            `<img src="../../../../assets/img/svg/calendar-outline.svg" alt="calendar-outline.svg">
              <div>
                ${this.datePipe.transform(info.event._def.extendedProps.props.startDate, 'dd MMM EEEE')}
              </div>` +
            '</div>' +
            '</div>' +
            `<div class="cal_popup__val time">
              <div class="cal_popup__view">
                <img src="../../../../assets/img/svg/clock-outline.svg" alt="clock-outline.svg">
                <div>
                  ${moment(info.event._def.extendedProps.props.startDate).format('HH:mm')}  &nbsp \u2013 &nbsp
                  ${moment(info.event._def.extendedProps.props.endDate).format('HH:mm')}
                </div>

    <span
      class="close" id="closePopup"
      
    >
      <img
        src="assets/img/svg/icon-close_dark.svg"
        alt="closeButton"
      />
    </span>
  </div>
  <div class="theme">
    ${info.event._def.extendedProps.props.subject}
  </div>
  <div class="info">
    <div class="date-time">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.6h-.833V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234A.784.784 0 0 0 10.5.8v.8h-5V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234.784.784 0 0 0-.244.566v.8H3c-.663 0-1.299.253-1.768.703A2.352 2.352 0 0 0 .5 4v9.6c0 .636.263 1.247.732 1.697.47.45 1.105.703 1.768.703h10c.663 0 1.299-.253 1.768-.703.469-.45.732-1.06.732-1.697V4c0-.637-.263-1.247-.732-1.697A2.554 2.554 0 0 0 13 1.6ZM3 3.2h.833V4c0 .212.088.416.244.566.157.15.369.234.59.234a.851.851 0 0 0 .589-.234A.784.784 0 0 0 5.5 4v-.8h5V4c0 .212.088.416.244.566.156.15.368.234.59.234a.851.851 0 0 0 .589-.234.784.784 0 0 0 .244-.566v-.8H13c.221 0 .433.084.59.234.155.15.243.354.243.566v3.2H2.167V4c0-.212.087-.416.244-.566A.851.851 0 0 1 3 3.2Zm10 11.2H3a.851.851 0 0 1-.59-.234.784.784 0 0 1-.243-.566V8.8h11.666v4.8a.784.784 0 0 1-.244.566.852.852 0 0 1-.589.234Z"
            fill="#678AFF"
          />
          <path
            d="M4.8 12.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM11.167 10.5H7.833a.851.851 0 0 0-.589.234A.784.784 0 0 0 7 11.3c0 .212.088.416.244.566.156.15.368.234.59.234h3.333a.851.851 0 0 0 .589-.234A.784.784 0 0 0 12 11.3a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234Z"
            fill="#678AFF"
          />
        </svg>
      </div>
      <div
        class="date"
        *ngIf="lessonDate"
      >
        ${this.datePipe.transform(info.event._def.extendedProps.props.startDate, 'dd MMM EEEE')}
      </div>
      <div
        class="time"
        *ngIf="lessonDateTime"
      >
        ${moment(info.event._def.extendedProps.props.startDate).format('HH:mm')}
      </div>
    </div>

          <div class="cal_popup__link-block" title="${this.copyLinkToClassText}">
            ${link.length > 25 ? link.slice(0, 25) + '...' : link}
            <svg class="cal_popup__link-img" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.25 7.125H11.875V4.48875C11.8729 3.92879 11.6495 3.39236 11.2536 2.99641C10.8576 2.60045 10.3212 2.37709 9.76125 2.375H4.48875C3.92879 2.37709 3.39236 2.60045 2.99641 2.99641C2.60045 3.39236 2.37709 3.92879 2.375 4.48875V9.76125C2.37709 10.3212 2.60045 10.8576 2.99641 11.2536C3.39236 11.6495 3.92879 11.8729 4.48875 11.875H7.125V14.25C7.125 14.8799 7.37522 15.484 7.82062 15.9294C8.26602 16.3748 8.87011 16.625 9.5 16.625H14.25C14.8799 16.625 15.484 16.3748 15.9294 15.9294C16.3748 15.484 16.625 14.8799 16.625 14.25V9.5C16.625 8.87011 16.3748 8.26602 15.9294 7.82062C15.484 7.37522 14.8799 7.125 14.25 7.125ZM7.125 9.5V10.2917H4.48875C4.34807 10.2917 4.21316 10.2358 4.11369 10.1363C4.01422 10.0368 3.95833 9.90193 3.95833 9.76125V4.48875C3.95833 4.34807 4.01422 4.21316 4.11369 4.11369C4.21316 4.01422 4.34807 3.95833 4.48875 3.95833H9.76125C9.90193 3.95833 10.0368 4.01422 10.1363 4.11369C10.2358 4.21316 10.2917 4.34807 10.2917 4.48875V7.125H9.5C8.87011 7.125 8.26602 7.37522 7.82062 7.82062C7.37522 8.26602 7.125 8.87011 7.125 9.5Z" fill="#678AFF"/>
            </svg>
          </div>
          <div class="cal_popup__info">
            <div class="info__icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.9997 0C3.13422 0 0 3.13422 0 6.9997C0 10.8652 3.13422 14 6.9997 14C10.8652 14 14 10.8652 14 6.9997C14 3.13422 10.8652 0 6.9997 0ZM8.45689 10.8486C8.09659 10.9908 7.80978 11.0987 7.59467 11.1733C7.38015 11.248 7.13067 11.2853 6.84681 11.2853C6.41067 11.2853 6.07111 11.1787 5.82933 10.9659C5.58756 10.7532 5.46726 10.4836 5.46726 10.1559C5.46726 10.0284 5.47615 9.89807 5.49393 9.76533C5.5123 9.63259 5.54133 9.48326 5.58104 9.31556L6.032 7.72267C6.0717 7.56978 6.10607 7.42459 6.13333 7.28948C6.16059 7.15318 6.17363 7.02815 6.17363 6.91437C6.17363 6.7117 6.13156 6.56948 6.048 6.48948C5.96326 6.40948 5.80385 6.37037 5.56622 6.37037C5.45007 6.37037 5.33037 6.38756 5.2077 6.4237C5.08622 6.46104 4.98074 6.49481 4.89422 6.528L5.01333 6.03733C5.30844 5.91704 5.59111 5.81393 5.86074 5.72859C6.13037 5.64207 6.38519 5.59941 6.62519 5.59941C7.05837 5.59941 7.39259 5.70489 7.62785 5.91348C7.86193 6.12267 7.97985 6.39467 7.97985 6.72889C7.97985 6.79822 7.97156 6.9203 7.95556 7.09452C7.93956 7.26933 7.90933 7.42874 7.86548 7.57511L7.41689 9.16326C7.38015 9.29067 7.34756 9.43644 7.31793 9.59941C7.28889 9.76237 7.27467 9.88682 7.27467 9.97037C7.27467 10.1813 7.32148 10.3253 7.4163 10.4018C7.50993 10.4782 7.67407 10.5167 7.90637 10.5167C8.016 10.5167 8.13867 10.4972 8.27733 10.4593C8.41481 10.4213 8.51437 10.3876 8.57719 10.3585L8.45689 10.8486ZM8.37748 4.40237C8.1683 4.59674 7.91644 4.69393 7.62193 4.69393C7.328 4.69393 7.07437 4.59674 6.86341 4.40237C6.65363 4.208 6.54756 3.97156 6.54756 3.69541C6.54756 3.41985 6.65422 3.18281 6.86341 2.98667C7.07437 2.78993 7.328 2.69215 7.62193 2.69215C7.91644 2.69215 8.16889 2.78993 8.37748 2.98667C8.58667 3.18281 8.69156 3.41985 8.69156 3.69541C8.69156 3.97215 8.58667 4.208 8.37748 4.40237Z"
                  fill="#678AFF"/>
              </svg>
            </div>
          ${this.translocoService.translate(copyLinkMessage)}</div>
          `;*/
          instance.setContent(resultHtml);
        },
        onShown(instance) {
          document.querySelector('#closePopup').addEventListener('click', (e) => {
            var elements = document.querySelectorAll('div[id^="tippy"]');
            if (elements.length > 0) {
              elements[0].remove()
            }
          });
          document.querySelector('#lessonbutton').addEventListener('click', () => {
            window.location.href = link;
          });
        },
      });
    }
    if (
      this.userdata.isStudent &&
      info.event._def.extendedProps.eventType === 'ScheduledLesson' &&
      !info.event._def.extendedProps.isForeigner &&
      !info.event._def.extendedProps.isPast
    ) {
      if (!info.el) {
        this.calendar.unselect();
        return;
      }

      tippy(info.el, {
        content: 'Загружаем ...',
        interactive: true,
        allowHTML: true,
        maxWidth: 'none',
        hideOnClick: true,
        trigger: 'click',
        appendTo: document.body,
        onCreate: instance => {
          this.tippyInstance = instance;
          instance.show();
        },
        onHide: instance => {
          this.calendar.unselect();
        },
        onHidden: instance => {
          this.tippyInstance = null;
          instance.destroy();
        },
        onShow: instance => {
          let resultHtml = '';
          resultHtml =
            '<div class="cal_popup">' +
            '<div class="cal_popup__title">' +
            info.event._def.extendedProps.props.title +
            '</div>' +
            `<div class="cal_popup__val"><span>${this.teacherText}</span>` +
            info.event._def.extendedProps.props.tutorUser.name +
            '</div>' +
            `<div class="cal_popup__val date"><span>${this.classDateText}</span><div class="edit"><input type="text" id="date" value="` +
            moment(info.event._def.extendedProps.props.startDate).format('DD.MM.YYYY') +
            '" /></div><div class="view">' +
            moment(info.event._def.extendedProps.props.startDate).format('DD.MM.YYYY');

          if (this.user) {
            resultHtml = resultHtml + '<a class="edit-button"><img src="/assets/img/svg/icon-edit.svg" /></a>';
          }

          resultHtml =
            resultHtml +
            '</div></div>' +
            '<div class="cal_popup__val time">' +
            `<span>${this.translocoService.translate(this.classTimeText)}</span>` +
            '<div class="edit">' +
            '<input type="text" id="time_from" value="' +
            moment(info.event._def.extendedProps.props.startDate).format('HH:mm') +
            '" /> - <input type="text" id="time_to" value="' +
            moment(info.event._def.extendedProps.props.endDate).format('HH:mm') +
            '" /></div><div class="view j">' +
            moment(info.event._def.extendedProps.props.startDate).format('HH:mm') +
            ' - ' +
            moment(info.event._def.extendedProps.props.endDate).format('HH:mm');

          if (this.user) {
            resultHtml = resultHtml + '<a class="edit-button"><img src="/assets/img/svg/icon-edit.svg" /></a>';
          }

          resultHtml += '</div></div>';

          if (info.event._def.extendedProps.props.lessonPaymentType === LessonPaymentTypeEnum.Payable) {
            resultHtml +=
              '<div class="cal_popup__val time">' +
              `<span>${this.translocoService.translate(this.classCostText)}</span>` +
              '<div class="view">' +
              info.event._def.extendedProps.props.price +
              '₽</div></div>';
          }

          resultHtml +=
            '<div class="cal_popup_error"></div>' +
            `<button class="btn btn--full btn--red-border cancelLesson"><span class="btn__label">${this.translocoService.translate(
              'Отменить событие',
            )}</span></button>` +
            '</div>';
          instance.setContent(resultHtml);

          setTimeout(() => {
            Inputmask('99:99').mask(document.querySelector('#time_from'));
            Inputmask('99:99').mask(document.querySelector('#time_to'));
            Inputmask('99.99.9999').mask(document.querySelector('#date'));

            if (document.querySelector('.cal_popup .time .edit-button')) {
              document.querySelector('.cal_popup .time .edit-button').addEventListener('click', event => {
                document.querySelector('.cal_popup .cal_popup__val.time').classList.add('editing');
                (document.querySelector('.cal_popup .saveLesson') as HTMLElement).style.display = 'block';

                setTimeout(() => {
                  const from = document.querySelector('.cal_popup #time_from') as HTMLInputElement;
                  from.focus();
                  from.select();
                }, 50);
              });
            }
            if (document.querySelector('.cal_popup .date .edit-button')) {
              document.querySelector('.cal_popup .date .edit-button').addEventListener('click', event => {
                document.querySelector('.cal_popup .cal_popup__val.date').classList.add('editing');
                (document.querySelector('.cal_popup .saveLesson') as HTMLElement).style.display = 'block';

                setTimeout(() => {
                  const date = document.querySelector('.cal_popup #date') as HTMLInputElement;
                  date.focus();
                  date.select();
                }, 50);
              });
            }

            if (document.querySelector('.tippy-content .saveLesson')) {
              document.querySelector('.tippy-content .saveLesson').addEventListener('click', event => {
                const id: number = +info.event._def.publicId;
                const date = (document.querySelector('.cal_popup #date') as HTMLInputElement).value;
                const start = moment(info.event._def.extendedProps.props.startDate).utc().format('HH:mm');
                const end = moment(info.event._def.extendedProps.props.endDate).utc().format('HH:mm');

                this.calendarService
                  .apiV1CalendarLessonsLessonIdPut({
                    lessonId: id,
                    body: {
                      Id: id,
                      LessonDate: date,
                      LessonStartTime: start,
                      LessonEndTime: end,
                    },
                  })
                  .subscribe(
                    result => {
                      this.calendar.refetchEvents();
                      this.calendar.unselect();
                      instance.hide();
                    },
                    error => {
                      const errorObject = document.querySelector('.tippy-content .cal_popup_error') as HTMLElement;
                      errorObject.innerHTML = this.translocoService.translate('Выбранное время недоступно');
                      errorObject.style.display = 'block';
                      setTimeout(() => {
                        errorObject.style.display = 'none';
                      }, 1000);
                    },
                  );
              });
            }

            if (document.querySelector('.tippy-content .cancelLesson')) {
              document.querySelector('.tippy-content .cancelLesson').addEventListener('click', event => {
                this.cancelLesson(info.event._def.publicId);
                instance.hide();
              });
            }
          }, 100);
        },
      });
    }
    if (
      this.userdata.isStudent &&
      !info.event._def.extendedProps.isPast &&
      info.event._def.extendedProps.eventType === 'Available'
    ) {
      this.showRequestForEventDialog(info?.start, info?.end, info.el);
    }
    if (
      this.userdata.isTutor &&
      info.event._def.extendedProps.props.tutorUser.id === this.userdata.id &&
      ['ScheduledLesson', 'InvitationScheduledLessons'].includes(info.event._def.extendedProps.eventType) &&
      !info.event._def.extendedProps.isPast &&
      isGroupLesson &&
      info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Initiated
    ) {
      // @ts-ignore
      if ((event as PointerEvent).target.classList.contains('fc-menu')) {
        this.openCreatedMeetingPopup(
          info.el,
          info.event._def.extendedProps.props.joinIdentity,
          info.event._def.extendedProps.props.inviteIdentity,
          info.event._def.extendedProps.props.disciplineId,
          info.event._def.extendedProps.props.startDate,
          info.event._def.extendedProps.props.endDate,
          info.event._def.publicId,
          info.event._def.extendedProps.props.publishingPolicy,
          info.event._def.extendedProps.props.subject,
          info.event._def.extendedProps.props.homeWorkTemplateId,
          info.event._def.extendedProps.props.invitedUsersInfo
        );
      }
    }
    if (
      this.userdata.isTutor &&
      info.event._def.extendedProps.props.tutorUser.id === this.userdata.id &&
      ['ScheduledLesson', 'InvitationScheduledLessons'].includes(info.event._def.extendedProps.eventType) &&
      !info.event._def.extendedProps.isPast &&
      !isGroupLesson
    ) {
      if (!info.el) {
        this.calendar.unselect();
        return;
      }

      tippy(info.el, {
        content: 'Загружаем ...',
        interactive: true,
        allowHTML: true,
        maxWidth: 'none',
        hideOnClick: true,
        trigger: 'click',
        appendTo: document.body,
        onCreate: instance => {
          this.tippyInstance = instance;
          instance.show();
        },
        onHide: instance => {
          this.calendar.unselect();
        },
        onHidden: instance => {
          this.tippyInstance = null;
          instance.destroy();
        },
        onShow: instance => {
          let resultHtml = '';

          const isGroupLesson = info.event._def.extendedProps.props.lessonFormat === LessonFormatEnum.Group;
          resultHtml =
            '<div class="cal_popup">' +
            '<div class="cal_popup__title">' +
            this.translocoService.translate(info.event._def.extendedProps.props.title) +
            '</div>' +
            `<div class="cal_popup__val"><span>${this.translocoService.translate(this.classTimeText)}</span>` +
            moment(info.event._def.extendedProps.props.startDate).format('HH:mm') +
            ' - ' +
            moment(info.event._def.extendedProps.props.endDate).format('HH:mm') +
            '</div>';

          if (!isGroupLesson) {
            resultHtml +=
              `<div class="cal_popup__val"><span>${this.translocoService.translate(this.studentText)}</span>` +
              info.event._def.extendedProps.props.studentUser.name +
              '</div>';

            if (info.event._def.extendedProps.props.lessonPaymentType === LessonPaymentTypeEnum.Payable) {
              resultHtml +=
                `<div class="cal_popup__val time"><span>${this.translocoService.translate(
                  this.classCostText,
                )}</span><div class="view">` +
                info.event._def.extendedProps.props.price +
                '₽</div></div>';
            }
          }

          if (isGroupLesson) {
            resultHtml =
              resultHtml +
              `<div class="cal_popup__link"><span>${this.linkToClassText}</span>` +
              window.location.origin +
              '/wait?id=' +
              info.event._def.extendedProps.props.joinIdentity +
              '</div>';
          }

          // Initiated
          if (info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Initiated) {
            resultHtml =
              resultHtml +
              `
                <button class="btn btn--full btn--blue acceptLesson">
                  <span class="btn__label">${this.translocoService.translate(this.acceptPayableText)} ${info.event._def.extendedProps.props.price
              }₽</span>
                </button>
              ` +
              `<button class="btn btn--full btn--blue acceptFreeLesson"><span class="btn__label">${this.translocoService.translate(
                this.acceptFreeClassText,
              )}</span></button>` +
              `<button class="btn btn--full btn--red-border cancelLesson"><span class="btn__label">${this.translocoService.translate(
                this.declineClassText,
              )}</span></button>`;
          }

          // Accepted

          if (info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.Accepted) {
            resultHtml =
              resultHtml +
              `<button class="btn btn--full btn--blue startLesson"><span class="btn__label">${this.translocoService.translate(
                this.startClassText,
              )}</span></button>` +
              `<button class="btn btn--full btn--red-border cancelLesson"><span class="btn__label">${this.translocoService.translate(
                this.cancelChangesText,
              )}</span></button>`;
          }

          // InitiatedGroupLesson
          if (info.event._def.extendedProps.statusId === ScheduledLessonStatusEnum.InitiatedGroupLesson) {
            resultHtml =
              resultHtml +
              `<button class="btn btn--full btn--red-border cancelLesson"><span class="btn__label">${this.translocoService.translate(
                this.cancelChangesText,
              )}</span></button>`;
          }

          resultHtml = resultHtml + '</div>';

          // instance._isFetching = false;
          instance.setContent(resultHtml);

          setTimeout(() => {
            this.updateUserProfile()
              .pipe(rxMap(() => this.canPayout))
              .subscribe(canPayout => {
                const emptyCardsElement = document.querySelector('.tippy-content .cal_popup__warning') as HTMLElement;
                if (emptyCardsElement) {
                  if (canPayout) {
                    emptyCardsElement.classList.add('cal_popup__warning__disabled');
                  } else {
                    emptyCardsElement.classList.remove('cal_popup__warning__disabled');
                  }
                }

                const buttonElement = document.querySelector('.tippy-content .acceptLesson') as HTMLButtonElement;
                if (buttonElement) {
                  buttonElement.disabled = !canPayout;
                }
              });

            if (document.querySelector('.tippy-content .linkToPersonalCabinet')) {
              document.querySelector('.tippy-content .linkToPersonalCabinet').addEventListener('click', event => {
                instance.hide();
                this.router.navigate(['/profile/cards']);
              });
            }

            if (document.querySelector('.tippy-content .startLesson')) {
              document.querySelector('.tippy-content .startLesson').addEventListener('click', event => {
                this.goToLesson(info.event._def.extendedProps.props.joinIdentity);
                instance.hide();
              });
            }

            if (document.querySelector('.tippy-content .acceptLesson')) {
              document.querySelector('.tippy-content .acceptLesson').addEventListener('click', event => {
                this.acceptLesson(info.event._def.publicId);
                instance.hide();
              });
            }

            if (document.querySelector('.tippy-content .acceptFreeLesson')) {
              document.querySelector('.tippy-content .acceptFreeLesson').addEventListener('click', event => {
                this.acceptFreeLesson(info.event._def.publicId);
                instance.hide();
              });
            }

            if (document.querySelector('.tippy-content .cancelLesson')) {
              document.querySelector('.tippy-content .cancelLesson').addEventListener('click', event => {
                this.cancelLesson(info.event._def.publicId);
                instance.hide();
              });
            }
          }, 100);
        },
      });
    }

    if (
      this.userdata.isTutor &&
      info.event._def.extendedProps.props.tutorUser.id === this.userdata.id &&
      !info.event._def.extendedProps.isPast &&
      info.event._def.extendedProps.eventType === 'Available'
    ) {
      this.openCreatedMeetingPopup(
        info.el,
        info.event._def.extendedProps.props.joinIdentity,
        info.event._def.extendedProps.props.inviteIdentity,
        info.event._def.extendedProps.props.disciplineId,
        info.event._def.extendedProps.props.startDate,
        info.event._def.extendedProps.props.endDate,
        info.event._def.publicId,
        info.event._def.subject,
        undefined,
        undefined,
        info.event._def.extendedProps.props.invitedUsersInfo
      );
    }
  }

  eventResizeStartHook(info) {
    info.el.classList.add('fc-event-resizing');
  }

  eventResizeStopHook(info) {
    info.el.classList.remove('fc-event-resizing');
  }

  eventResizeHook(info) {
    /* Send event with updated data to the stream, in case is pre created */
    const start: Date = path(['event', '_instance', 'range', 'start'], info);
    const end: Date = path(['event', '_instance', 'range', 'end'], info);

    if (isPreCreate(info)) {
      info.revert();
      return;
    }

    if (info.event.extendedProps.props.isPast) {
      info.revert();
      return;
    }

    if (info.event.extendedProps.eventType === 'ScheduledLesson') {
      this.saveLesson(info.event._def.publicId, start, end);
    }
    if (info.event.extendedProps.eventType === 'Available') {
      this.saveSlot(info.event._def.publicId, start, end);
    }
  }

  eventDropHook(info) {
    const start: Date = path(['event', '_instance', 'range', 'start'], info);
    const end: Date = path(['event', '_instance', 'range', 'end'], info);

    if (isPreCreate(info)) {
      const correctStart = fixFullcalendarBugOffset(start);
      const correctEnd = fixFullcalendarBugOffset(end);
      this.calendarPopupService.hideCurrentPopup();

      if (moment(correctStart).isSameOrAfter(Date.now())) {
        this.configureNewMeeting(correctStart, correctEnd);
      }

      this.calendar.unselect();
      return;
    }

    if (info.event.extendedProps.props.isPast) {
      info.revert();
      return;
    }

    if (info.event.extendedProps.eventType === 'ScheduledLesson') {
      this.saveLesson(info.event._def.publicId, start, end);
    }
    if (info.event.extendedProps.eventType === 'Available') {
      this.saveSlot(info.event._def.publicId, start, end);
    }
  }

  eventMouseEnterHook(info) {
    this.studentSelectedItemEvent = info.event;
    info.el.querySelector('.fc-start-block')?.classList.add('displaying');
    if (!this.userdata.isStudent) {
      info.el.querySelector('.fc-content')?.classList.add('d-none');
    }
  }

  eventMouseLeaveHook(info) {
    info.el.querySelector('.fc-start-block')?.classList.remove('displaying');
    info.el.querySelector('.fc-content')?.classList.remove('d-none');
  }

  subscribeTranslate() {
    this.translocoService.langChanges$.pipe(debounceTime(100), untilDestroyed(this)).subscribe(x => {
      const cal = this.calendar as Calendar;
      if (cal) {
        this.updateTodayText();
      }
    });

    const timeOutId = setTimeout(x => {
      this.mutationObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          this.updateTodayText();
        });
      });
      //Дополнительный вызов для перевода на устройствах на android
      this.updateTodayText();
      const el = this.documentRef.getElementsByClassName('fc-today-button');
      const config = { attributes: true, childList: true, subtree: true };
      this.mutationObserver.observe(el[0], config);
      clearTimeout(timeOutId);
    }, 200);
  }

  updateTodayText() {
    // Добавлен setTimeout, потому что в некоторых устройствах Android
    // не успевает загрузится файл с ключами
    const timeOutId = setTimeout(_ => {
      // https://fullcalendar.io/docs/dynamic-options Dynamic setting of some options is simply not yet implemented:
      const el = this.documentRef.getElementsByClassName('fc-today-button');
      if (el.length > 0) {
        const nextText = this.translocoService.translate('Эта неделя');
        if (nextText !== el[0].innerHTML) {
          el[0].innerHTML = nextText;
        }
      }
      clearTimeout(timeOutId);
    }, 300);
  }

  private getHeaderContent(lg: Langs, x: DayHeaderContentArg): any {
    if (this.calendar.view.type === 'dayGridMonth') {
      if (lg === Langs.RU) {
        return DAY_NAMES_SHORT_RU[x.date.getDay()];
      }
      if (lg === Langs.EN) {
        return DAY_NAMES_SHORT_EN[x.date.getDay()];
      }
      if (lg === Langs.KK) {
        return DAY_NAMES_SHORT_KK[x.date.getDay()];
      }
    }
  }

  private showRequestForEventDialog(infoStart: Date, infoEnd: Date, element: Element) {
    if (!infoStart || !infoEnd) {
      return;
    }

    if (!this.userdata.isStudent) {
      return;
    }

    const range = new Date(infoEnd).getTime() - new Date(infoStart).getTime();
    if (range === 900000) {
      const start = infoStart;
      let end = infoEnd;

      const evts = this.calendar.getEvents();
      let available = false;

      const fr = new Date(start);
      end = new Date(fr.getTime() + 2700000);

      evts.forEach(evt => {
        if (start >= evt.start && end <= evt.end && evt.extendedProps.eventType === 'Available') {
          available = true;
        }
      });
      if (available) {
        this.calendar.unselect();
        this.calendar.select(start, end);
      }

      if (available) {
        return;
      }
    }

    const holder = element ?? document.querySelector('.fc-highlight');
    if (!holder) {
      this.calendar.unselect();
      return;
    }

    if (!!this.tippyInstance) {
      this.tippyInstance.destroy();
      this.calendar.select(infoStart, infoEnd);
      return;
    }

    tippy(holder, {
      content: 'Загружаем ...',
      interactive: true,
      allowHTML: true,
      maxWidth: 'none',
      hideOnClick: true,
      trigger: 'click',
      appendTo: document.body,
      onCreate: instance => {
        this.tippyInstance = instance;
        instance.show();
      },
      onHide: instance => {
        this.calendar.unselect();
      },
      onHidden: instance => {
        this.tippyInstance = null;
        instance?.destroy();
      },
      onShow: instance => {
        let resultHtml = '';
        resultHtml = '<div class="cal_popup">' + '<div class="cal_popup__vals">';
        let x = 0;
        this.tutor.tutor.disciplineSpecializations.forEach(item => {
          if (x === 0) {
            resultHtml =
              resultHtml +
              '<label><input type="radio" checked name="discipline" value="' +
              item.discipline.id +
              '"> ' +
              this.translocoService.translate(item.discipline.fullName) +
              '</label>';
          } else {
            resultHtml =
              resultHtml +
              '<label><input type="radio" name="discipline" value="' +
              item.discipline.id +
              '"> ' +
              this.translocoService.translate(item.discipline.fullName) +
              '</label>';
          }
          x++;
        });

        const calculatePrice = (endTime: number, startTime: number) => {
          const duration = (endTime - startTime) / 60000;
          const price = Math.round((this.tutor.tutor.scheduledLessonHourlyRate / 45) * duration * 100) / 100;

          return price;
        };

        const price = calculatePrice(infoEnd.getTime(), infoStart.getTime());

        resultHtml =
          resultHtml +
          '</div>' +
          `<div class="cal_popup__val"><span>${this.translocoService.translate(this.teacherText)}</span>` +
          this.tutor.name +
          '</div>' +
          `<div class="cal_popup__val date"><span>${this.translocoService.translate(
            this.classDateText,
          )}</span><div class="edit"><input type="text" id="date" value="` +
          moment(infoStart).format('DD.MM.YYYY') +
          '" /></div><div class="view j1">' +
          moment(infoStart).format('DD.MM.YYYY') +
          (this.userdata.isTutor ? '<a class="edit-button"><img src="/assets/img/svg/icon-edit.svg" /></a>' : '') +
          '</div></div>' +
          `<div class="cal_popup__val time"><span>${this.translocoService.translate(
            this.translocoService.translate(this.classTimeText),
          )}</span><div class="edit"><input type="text" id="time_from" value="` +
          moment(infoStart).format('HH:mm') +
          '" /> - <input type="text" id="time_to" value="' +
          moment(infoEnd).format('HH:mm') +
          '" /></div><div class="view">' +
          moment(infoStart).format('HH:mm') +
          ' - ' +
          moment(infoEnd).format('HH:mm') +
          (this.userdata.isTutor ? '<a class="edit-button"><img src="/assets/img/svg/icon-edit.svg" /></a>' : '<a class="edit-button"><img src="/assets/img/svg/icon-edit.svg" /></a>') +
          '</div></div>' +
          `<div class="cal_popup__val time"><span>${this.translocoService.translate(
            this.classCostText,
          )}</span><div id="price" class="view">` +
          price +
          '₽</div></div>' +
          '<div class="cal_popup_error"></div>' +
          `<button class="btn btn--full btn--blue createLesson"><span class="btn__label">${this.translocoService.translate(
            this.sendRequestForClassText,
          )}</span></button>` +
          `<button class="btn btn--full btn--red-border cancelLesson"><span class="btn__label">${this.translocoService.translate(
            this.cancelChangesText,
          )}</span></button>` +
          '</div>';

        instance.setContent(resultHtml);

        const onClickTimeEditButtonEventListener = (event) => {
          document.querySelector('.cal_popup .cal_popup__val.time').classList.add('editing');

          setTimeout(() => {
            const from = document.querySelector('.cal_popup #time_from') as HTMLInputElement;
            from.focus();
            from.select();
          }, 50);
        };

        const onClickDateEditButtonEventListener = (event) => {
          document.querySelector('.cal_popup .cal_popup__val.date').classList.add('editing');

          setTimeout(() => {
            const date = document.querySelector('.cal_popup #date') as HTMLInputElement;
            date.focus();
            date.select();
          }, 50);
        };

        const onClickCreateLessonEventListener = (event, begintime: any, endtime: any) => {
          const date = moment(infoStart).utc().format('DD.MM.YYYY');
          const start = moment(moment(infoStart).utc().format('YYYY-MM-DD') + " " + begintime.value).utc().format('HH:mm');
          const end = moment(moment(infoStart).utc().format('YYYY-MM-DD') + " " + endtime.value).utc().format('HH:mm');
          const discipline = (
            document.querySelector('.tippy-content .cal_popup__vals input:checked') as HTMLInputElement
          ).value;
          const DisciplineId: number = +discipline;
          if (end > start) {
            this.calendarService
              .apiV1CalendarLessonsPost({
                body: {
                  LessonDate: date,
                  LessonStartTime: start,
                  LessonEndTime: end,
                  TutorUserId: this.tutor.id,
                  DisciplineId,
                },
              })
              .subscribe(
                result => {
                  this.calendar.refetchEvents();
                  this.calendar.unselect();
                  if (!instance?.state?.isDestroyed) {
                    instance?.hide();
                  }
                },
                error => {
                  const errorObject = document.querySelector('.tippy-content .cal_popup_error') as HTMLElement;
                  errorObject.innerHTML = this.translocoService.translate('Выбранное время недоступно');
                  errorObject.style.display = 'block';
                  setTimeout(() => {
                    errorObject.style.display = 'none';
                  }, 1000);
                },
              );
          }
          else {
            const errorObject = document.querySelector('.tippy-content .cal_popup_error') as HTMLElement;
            errorObject.innerHTML = this.translocoService.translate('Начало позже конца');
            errorObject.style.display = 'block';
            setTimeout(() => {
              errorObject.style.display = 'none';
            }, 5000);
          }
        };

        const onClickCancelLessonEventListener = (event) => {
          if (!instance?.state?.isDestroyed) {
            instance?.hide();
          }
          this.calendar.unselect();
        };

        const onChangeTime = (event) => {
          if (!!window.moment) {
            window.moment = moment;
          }

          var begintime: any = document.querySelector('#time_from');
          var endtime: any = document.querySelector('#time_to');

          if (!begintime?.value?.toString().length || !endtime?.value?.toString().length) {
            return;
          }

          if (begintime?.value?.toString()?.indexOf('_') >= 0 || endtime?.value?.toString()?.indexOf('_') >= 0) {
            return;
          }

          const start = moment(moment(infoStart).utc().format('YYYY-MM-DD') + " " + begintime.value);
          const end = moment(moment(infoStart).utc().format('YYYY-MM-DD') + " " + endtime.value);

          if (!start?.isValid() || !end?.isValid()) {
            return;
          }

          const startTime = start?.utc()?.toDate()?.getTime();
          const endTime = end?.utc()?.toDate()?.getTime();

          if (endTime < startTime) {
            return;
          }

          try {
            const price = calculatePrice(end.utc().toDate().getTime(), start.utc().toDate().getTime());
            const priceElement = document.querySelector('#price') as HTMLElement;
            priceElement.innerHTML = `${price}₽`;
          }
          catch (_) {
            return;
          }
        };

        setTimeout(() => {
          Inputmask('99:99').mask(document.querySelector('#time_from'));
          Inputmask('99:99').mask(document.querySelector('#time_to'));
          Inputmask('99.99.9999').mask(document.querySelector('#date'));

          if (document.querySelector('.cal_popup .time .edit-button')) {
            document.querySelector('.cal_popup .time .edit-button').addEventListener('click', onClickTimeEditButtonEventListener);
          }

          if (document.querySelector('.cal_popup .date .edit-button')) {
            document.querySelector('.cal_popup .date .edit-button').addEventListener('click', onClickDateEditButtonEventListener);
          }

          if (document.querySelector('.tippy-content .createLesson')) {
            var begintime: any = document.querySelector('#time_from');
            var endtime: any = document.querySelector('#time_to');
            window.moment = moment;
            document.querySelector('.tippy-content .createLesson').addEventListener('click', event =>
              onClickCreateLessonEventListener(event, begintime, endtime));
          }
          if (document.querySelector('.tippy-content .cancelLesson')) {
            document.querySelector('.tippy-content .cancelLesson').addEventListener('click', onClickCancelLessonEventListener);
          }

          document.querySelector('#time_from')?.addEventListener('input', onChangeTime);
          document.querySelector('#time_to')?.addEventListener('input', onChangeTime);

        }, 300);

        this._cdr.detectChanges();
      },
    });
  }
}
