<div class="messages__title">
  <div *ngIf="roomUser">
    <a
      routerLink="/teachers/{{ roomUser.id }}"
      *ngIf="userdata.isStudent; else tutorTitleComponent"
      class="message_user"
    >
      <span class="message_user__image">
        <div
          class="avatar-image"
          *ngIf="roomUser | userAvatar; else showUserInitials"
          [style.background]="roomUser | userAvatarBackground"
        ></div>
        <ng-template #showUserInitials>
          <span>{{ roomUser | userInitials }}</span>
        </ng-template>
      </span>
      <span class="message_user__name">{{ roomUser?.name }}</span>
    </a>
    <ng-template #tutorTitleComponent>
      <span class="message_user">
        <span class="message_user__image">
          <div
            class="avatar-image"
            *ngIf="roomUser | userAvatar; else showTutorInitials"
            [style.background]="roomUser | userAvatarBackground"
          ></div>
          <ng-template #showTutorInitials>
            <span>{{ roomUser | userInitials }}</span>
          </ng-template>
        </span>
        <span class="message_user__name">{{ roomUser?.name }}</span>
      </span>
    </ng-template>
  </div>
</div>
<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="icon-menu"
  (click)="getStat()"
>
  <svg-icon-sprite
    [src]="'icon-menu'"
    [width]="'24px'"
    [height]="'24px'"
    [viewBox]="'-8 0 24 24'"
  ></svg-icon-sprite>
</button>
<mat-menu
  #menu="matMenu"
  class="messages_menu"
  xPosition="before"
>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('images')"
  >
    <img src="/assets/img/svg/icon-images.svg" />
    <span>{{ 'Фотографии' | transloco }} {{ stat.imagesCount }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('files')"
  >
    <img src="/assets/img/svg/icon-files.svg" />
    <span>{{ 'Файлы' | transloco }} {{ stat.filesCount }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="stat"
    class="messages_menu__item"
    (click)="openModal('links')"
  >
    <img src="/assets/img/svg/icon-links.svg" />
    <span>{{ 'Ссылки' | transloco }} {{ stat.linksCount }}</span>
  </button>
</mat-menu>