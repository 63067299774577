import { Injectable } from '@angular/core';
import { ActiveService } from '@ezteach/_services/active.service';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { ChatLesson, ChatLessonStatusEnum } from '@ezteach/api/models';
import { BatchChangePublishingPermissionRequest } from '@ezteach/api/models/batch-change-publishing-permission-request';
import {
  ChatLessonMember,
  ChatLessonMemberPublishingStateValue,
  RequestChangePublishingState,
  RequestChangePublishingStateWithId,
} from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
import { LessonProlongData } from '@ezteach/api/models/prolong-data';
import { LessonsService } from '@ezteach/api/services';
import { ChatLessonMemberPublishingState } from '@ezteach/group-lesson/models/chat-lesson-member-publishing-state';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StreamManager } from 'openvidu-browser';
import { BehaviorSubject, EMPTY, Subject, Subscription, forkJoin, of, timer } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RightStatusChange, UserRole, UserRoles } from '../group-lesson.component';
import { GroupLessonMemberManagerService } from './group-lesson-member-manager.service';
import { GroupLessonPermissionService } from './group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonPublishingStateService } from './group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonReactionService } from './group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonSignalrService } from './group-lesson-signalr-service/group-lesson-signalr-service';
import { GroupLessonWhiteBoardService } from './group-lesson-whiteboard-service/group-lesson-whiteboard-service';
import { MEDIA_DEVICES_AVAILABLE, OpenViduService } from './open-vidu.service';

export enum ChatLessonPublisherPolicy {
  //Владелец
  Owner = 'Owner',
  //Все участники
  Any = 'Any',
}

export enum ChatLessonPrivacy {
  Public = 'Public',
  Private = 'Private',
}

export enum AccessToArchiveLesson {
  All = 'All',
  MembersOnly = 'MembersOnly',
}

export interface SelectedPublishingPolicy {
  publisher: ChatLessonPublisherPolicy;
  lessonWillbeRecorded: boolean;
  lessonPrivacy: ChatLessonPrivacy;
  accessToArchiveLesson: AccessToArchiveLesson;
}

interface MemberCache {
  member: ChatLessonMember;
  expiresIn: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GroupLessonService {
  destroySubject$: Subject<void> = new Subject();
  chatLesson$ = new BehaviorSubject<ChatLesson>(null);
  chatLessonSubject?: string;
  chatLessonStatus$ = new Subject<ChatLessonStatusEnum>();
  fullScreen$ = new Subject();
  chatOpen$ = new BehaviorSubject<boolean>(false);
  parcipantsOpen$ = new BehaviorSubject<boolean>(false);
  toolbarDropDownIsOpen = new BehaviorSubject<boolean>(false);
  memberId: number = null;
  role: UserRole;
  queryChatLessonId?: string = null;
  timeDuration$: Subject<number> = new Subject();
  isAway$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  memberContainerIsChanged$ = new BehaviorSubject<void>(null);

  readonly publisherStateChangeLoad$ = new Subject<void>();
  readonly publisherStateChange$ = new Subject<void>();
  isSpeech: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  timerSubscription: Subscription;
  isProlongModalVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  canProlong$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  canProlongData$: BehaviorSubject<LessonProlongData> = new BehaviorSubject<LessonProlongData>(null);
  cachedMembers: MemberCache[] = [];
  memberListIsShown$ = new Subject<boolean>();
  memberListIsShown = true;
  firstLoad = true;
  firstChatLoad = true;
  lessonIsDestroyed = new BehaviorSubject<boolean>(false);
  virtualKeyboardDisplayed$ = new BehaviorSubject<boolean>(false);
  readonly onRequestMediaState$ = new Subject<RequestChangePublishingState>();
  readonly disableMediaNotify$ = new Subject<ChatLessonMemberPublishingStateEnum>();
  private videoPermission: boolean;
  private audioPermission: boolean;
  private disconnectTimeouts: { [key: number]: any } = {};

  constructor(
    private openViduService: OpenViduService,
    private lessonsService: LessonsService,
    private signalrService: SignalrService,
    private groupLessonPermissionService: GroupLessonPermissionService,
    private groupLessonSignalrService: GroupLessonSignalrService,
    private activeService: ActiveService,
    private groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private groupLessonWaitService: GroupLessonWaitService,
    private groupLessonWhiteBoardService: GroupLessonWhiteBoardService,
    private groupLessonReactionService: GroupLessonReactionService,
    private localStorageService: LocalStorageService,
  ) {
    this.subscribeGroupLessonSignalrService();
    this.openViduService.stopSharingBrowserAccessEvent$.subscribe(() =>
      this.changePublishingState(ChatLessonMemberPublishingStateEnum.Screen, false, ''),
    );
    this.subscribeGroupLessonWhiteBoardService();
    this.subscribeOpenViduService();
    this.subscribeChatLesson();
    this.subscribeMediaPermission();
  }


  // TODO пока что так, найти лучшее решение
  // функция вызвается после дисконекта интернета когда сессия была разорвана
  // если в это время человек говорил, нужно восстановить звук
  async refreshAudioAfterSessionLeave() {
    const _audioEnabled = this.openViduService.audioEnabled;
    if (_audioEnabled && this.openViduService.session?.connection) {
      // audioStatusChange работает как toggle тогда надо флаг сменить на false после он странет true
      this.openViduService.audioEnabled = false;
      // указывает что это первое включение микрофона, оно создает сесссию, после уже работает с существующей
      this.openViduService.lazyAudioPublisher = true;

      this.openViduService.audioStatusChange(() => {
        // пока так в конце убираем флаг что бы, микрофон вкл/вкл работал уже с существующим подключением 
        this.openViduService.lazyAudioPublisher = false;
      });

    } else {
      console.log('Сессия не готова для восстановления аудио');
    }
  }

  /*
   * преподаватель ѝтартует занятие
   */
  ownerStartLesson(chatLesson: ChatLesson, fromNetworkDisconnect: boolean) {
    this.role = UserRoles.Owner;
    this.groupLessonSignalrService.connect(chatLesson.id);
    this.chatLesson$.next(chatLesson);
    const ownerMember = chatLesson.members.filter(member => member.role === ChatLessonMemberRole.Owner)[0];
    if (ownerMember) {
      this.groupLessonMemberManagerService.addOwner(ownerMember);
      this.addOrReplaceCache(ownerMember);
    }

    // console.log('Group-lesson.service', 'ownerStartLesson', 'Appended Owner to cache');

    const allMembers = chatLesson.members.map(a => a);

    chatLesson.members = chatLesson.members.filter(member => member.isOnline === true);
    this.setPermission(chatLesson.members);
    this.groupLessonMemberManagerService.addMembers(chatLesson.members);


    allMembers.forEach(member => {
      this.addOrReplaceCache(member);
    });

    // console.log('ownerStartLesson', 'Appended All Members to cache', allMembers);

    let publishingState: ChatLessonMemberPublishingStateValue[];
    publishingState = this.groupLessonWaitService.publishingState;

    if (fromNetworkDisconnect) {
      publishingState = [];
      if (this.openViduService.audioEnabled) {
        publishingState.push({
          name: ChatLessonMemberPublishingStateEnum.Audio,
          arg: '',
        });
      }
      if (this.openViduService.videoEnabled) {
        publishingState.push({
          name: ChatLessonMemberPublishingStateEnum.Video,
          arg: '',
        });
      }
    }

    if (publishingState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Screen) !== undefined) {
      const index = publishingState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Screen);
      if (index !== -1) {
        publishingState.splice(index, 1);
      }
    }
    if (publishingState.length === 0) {
      publishingState = [{ name: ChatLessonMemberPublishingStateEnum.None, arg: '' }];
    }

    if (this.localStorageService.get('audioDeviceId')) {
      this.openViduService.setPublisherSettings({
        ...this.openViduService.publisherProperties,
        audioSource: this.localStorageService.get('audioDeviceId'),
      });
    }
    if (this.localStorageService.get('videoDeviceId')) {
      this.openViduService.setPublisherSettings({
        ...this.openViduService.publisherProperties,
        videoSource: this.localStorageService.get('videoDeviceId'),
      });
    }
    const memberIndex = chatLesson.members.findIndex(x => x.memberId === this.memberId);
    if (memberIndex !== -1) {
      chatLesson.members[memberIndex].publishingState = publishingState;
    }
    if (chatLesson.lessonStatusId !== 'Started') {
      forkJoin([
        this.lessonsService.apiV1LessonsLessonIdGet({
          lessonId: +this.queryChatLessonId,
        }),
        this.lessonsService.apiV1LessonsLessonIdMediaTokenPost({
          lessonId: chatLesson.id,
          body: { publishingState, connectionId: '' },
        }),
        this.lessonsService.changePublishingState({
          lessonId: chatLesson.id,
          newPublishingState: publishingState,
        }),
      ])
        .pipe(
          tap(([lesson, token, publishingState]) => {
            this.openViduService.connectionId = token.data?.connectionId;
            this.initTimer(lesson.data);
            this.setPermission(lesson.data.members);
            const membersState = lesson.data.members.map(x => {
              return {
                memberId: x.memberId,
                previousValue: [],
                currentValue: x.publishingState,
              };
            });
            this.groupLessonPublishingStateService.setMembersState(membersState);
            this.groupLessonPublishingStateService.setMemberState({
              memberId: this.memberId,
              previousValue: [],
              currentValue: publishingState.body.data.publishingState,
            });
            this.openViduService.joinSession(token.data.token, UserRoles.Owner, token.data.isPublishing);
            this.chatLesson$.next(lesson.data);
            this.updateViduState();
          }),
          switchMap(() => this.openViduService.getSessionConnected()),
          tap(() => {
            if (fromNetworkDisconnect) {
              // TODO придумать что делать в случае если fromNetworkDisconnect

              this.refreshAudioAfterSessionLeave();
            }
          }),
          untilDestroyed(this),
        )
        .subscribe();
    } else {
      this.initTimer(chatLesson);
      forkJoin([
        this.lessonsService.apiV1LessonsLessonIdMediaTokenPost({
          lessonId: chatLesson.id,
          body: { publishingState, connectionId: '' },
        }),
        this.lessonsService.changePublishingState({
          lessonId: chatLesson.id,
          newPublishingState: this.getCurrentPublishState(chatLesson),
        }),
      ])
        .pipe(
          tap(([token, publishingState]) => {
            this.openViduService.connectionId = token.data?.connectionId;

            this.groupLessonPublishingStateService.setMemberState({
              memberId: this.memberId,
              previousValue: [],
              currentValue: publishingState.body.data.publishingState,
            });
            let membersState = chatLesson.members
              .filter(x => x.memberId !== this.memberId)
              .map(x => {
                return {
                  memberId: x.memberId,
                  previousValue: [],
                  currentValue: x.publishingState,
                };
              });
            const user = chatLesson.members.find(x => x.memberId === this.memberId);
            const currentMemberState = [
              {
                memberId: user.memberId,
                previousValue: [],
                currentValue: publishingState.body.data.publishingState,
              },
            ];
            membersState = [...membersState, ...currentMemberState];
            this.groupLessonPublishingStateService.setMembersState(membersState);
            this.openViduService.joinSession(token.data.token, UserRoles.Owner, token.data.isPublishing);
            this.updateViduState();
          }),
          switchMap(() => this.openViduService.getSessionConnected()),
          tap(() => {
            if (fromNetworkDisconnect) {
              // TODO придумать что делать в случае если fromNetworkDisconnect
              this.refreshAudioAfterSessionLeave();
            }
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  /*
   * ѝтудент заходит на занятие
   */
  memberStartLesson(chatLesson: ChatLesson, fromNetworkDisconnect: boolean) {
    this.role = UserRoles.Member;
    this.groupLessonSignalrService.connect(chatLesson.id);
    this.chatLesson$.next(chatLesson);

    this.setPermission(chatLesson.members);

    const allMembers = chatLesson.members.map(a => a);

    chatLesson.members = chatLesson.members.filter(
      member => member.role === ChatLessonMemberRole.Owner || member.isOnline === true,
    );

    this.groupLessonMemberManagerService.addMembers(chatLesson.members);

    allMembers.forEach(member => {
      this.addOrReplaceCache(member);
    });

    // console.log('memberStartLesson');

    const currentMember = chatLesson.members.find(x => x.memberId === this.memberId);
    const publishingPermissions = currentMember?.publishingPermissions;

    const ownerMember = chatLesson.members.filter(member => member.role === ChatLessonMemberRole.Owner)[0];
    if (ownerMember) {
      this.groupLessonMemberManagerService.addOwner(ownerMember);
      this.addOrReplaceCache(ownerMember);
      this.isAway$.next(!ownerMember.isOnline);
    }

    // console.log('memberStartLesson', 'Appended Owner to cache', ownerMember);

    let publishingState: ChatLessonMemberPublishingStateValue[];
    publishingState = this.groupLessonWaitService.publishingState;

    const _audioEnabled = this.openViduService.audioEnabled;

    if (fromNetworkDisconnect) {
      publishingState = [];
      if (_audioEnabled) {
        publishingState.push({
          name: ChatLessonMemberPublishingStateEnum.Audio,
          arg: '',
        });
      }
      if (this.openViduService.videoEnabled) {
        publishingState.push({
          name: ChatLessonMemberPublishingStateEnum.Video,
          arg: '',
        });
      }
    }

    if (
      publishingState.length === 0 ||
      publishingPermissions.filter(
        x => x === ChatLessonMemberPublishingPermission.Audio || x === ChatLessonMemberPublishingPermission.Video,
      ).length === 0
    ) {
      publishingState = [{ name: ChatLessonMemberPublishingStateEnum.None, arg: '' }];
    }

    if (this.localStorageService.get('audioDeviceId')) {
      this.openViduService.setPublisherSettings({
        ...this.openViduService.publisherProperties,
        audioSource: this.localStorageService.get('audioDeviceId'),
      });
    }
    if (this.localStorageService.get('videoDeviceId')) {
      this.openViduService.setPublisherSettings({
        ...this.openViduService.publisherProperties,
        videoSource: this.localStorageService.get('videoDeviceId'),
      });
    }
    const memberIndex = chatLesson.members.findIndex(x => x.memberId === this.memberId);
    if (memberIndex !== -1) {
      chatLesson.members[memberIndex].publishingState = publishingState;
    }
    this.initTimer(chatLesson);
    return forkJoin([
      this.lessonsService.apiV1LessonsLessonIdMediaTokenPost({
        lessonId: chatLesson.id,
        body: { publishingState, connectionId: '' },
      }),
      this.lessonsService.changePublishingState({
        lessonId: chatLesson.id,
        newPublishingState: this.getCurrentPublishState(chatLesson),
      }),
    ])
      .pipe(
        tap(([x, publishingState]) => {
          this.openViduService.connectionId = x.data?.connectionId;
          this.openViduService.joinSession(x.data.token, UserRoles.Member, x.data.isPublishing);
          const membersState = chatLesson.members.map(x => {
            return {
              memberId: x.memberId,
              previousValue: [],
              currentValue: x.publishingState,
            };
          });
          this.groupLessonPublishingStateService.setMembersState(membersState);
          this.groupLessonPublishingStateService.setMemberState({
            memberId: this.memberId,
            previousValue: [],
            currentValue: publishingState.body.data.publishingState,
          });
          this.updateViduState();
          this.activeService.update();
        }),
        switchMap(() => this.openViduService.getSessionConnected()),
        tap(() => {
          if (fromNetworkDisconnect) {
            // TODO придумать что делать в случае если fromNetworkDisconnect
            this.refreshAudioAfterSessionLeave();
          }
        }),
      )
      .subscribe();
  }

  // Получает текущие публикации пользователѝ
  getCurrentPublishState(chatLesson: ChatLesson): ChatLessonMemberPublishingStateValue[] {
    const user = chatLesson.members.find(x => x.memberId === this.memberId);
    return user.publishingState ?? [{ name: ChatLessonMemberPublishingStateEnum.None, arg: '' }];
  }

  // обновление ѝоѝтоѝние при подключении учаѝтника
  updateViduState() {
    const memberState = this.groupLessonPublishingStateService.getMemberState(this.memberId);
    if (
      memberState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Audio) !== -1 &&
      this.groupLessonPermissionService.chatLessonMember$.value?.publishingPermissions?.indexOf(
        ChatLessonMemberPublishingPermission.Audio,
      ) !== -1
    ) {
      this.groupLessonPermissionService.audioEnabled$.next(true);
      this.openViduService.enableAudio();
    }
    if (
      memberState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Video) !== -1 &&
      this.groupLessonPermissionService.videoPermission
    ) {
      this.groupLessonPermissionService.videoEnabled$.next(true);
      this.openViduService.enableVideo();
    }
  }

  setChatLesson() { }

  setMemberId(value: number) {
    this.memberId = value;
    this.openViduService.memberId = value;
  }

  setQueryChatLessonId(value: string | null) {
    this.queryChatLessonId = value;
  }

  setToolbarDropDownStatus(value: boolean) {
    this.toolbarDropDownIsOpen.next(value);
  }

  updateLessonMembers() {
    this.lessonsService
      .apiV1LessonsLessonIdGet({ lessonId: +this.queryChatLessonId })
      .pipe(
        takeUntil(this.destroySubject$),
        tap(x => {
          this.updatePermission(x.data.members);
        }),
      )
      .subscribe();
  }

  async leaveSession() {
    await this.openViduService.leaveSession();
    this.canProlong$.next(false);
  }

  audioStatusChange() {
    this.openViduService.audioStatusChange();

    if (!this.openViduService.lazyAudioPublisher) {
      this.changePublishingState(ChatLessonMemberPublishingStateEnum.Audio, this.openViduService.audioEnabled, '');
    }
  }

  videoStatusChange() {
    this.openViduService.videoStatusChange();
    if (!this.openViduService.lazyVideoPublisher) {
      this.changePublishingState(ChatLessonMemberPublishingStateEnum.Video, this.openViduService.videoEnabled, '');
    }
  }

  enableWhiteBoardMode() {
    this.groupLessonWhiteBoardService.whiteBoardInitStart$.next(true);
  }

  disableWhiteBoardMode() {
    this.groupLessonWhiteBoardService.whiteBoardClosed$.next(true);
    this.changePublishingState(ChatLessonMemberPublishingStateEnum.Whiteboard, false, '');
  }

  changePublishingState(type: ChatLessonMemberPublishingStateEnum, enabled: boolean, arg: string) {
    this.groupLessonPublishingStateService.addOrUpdateMemberPublishingStateService({
      memberId: this.memberId,
      type,
      enabled,
      arg,
    });
    this.updatePublisherStateAndSwitchPublisher(this.groupLessonPublishingStateService.getMemberState(this.memberId));
  }

  private updatePublisherStateAndSwitchPublisher(newPublishingState: ChatLessonMemberPublishingStateValue[]) {
    this.openViduService.publisherStateChangingValue$.next(true);
    this.lessonsService
      .changePublishingState({
        lessonId: this.chatLesson$.value.id,
        newPublishingState,
      })
      .pipe(
        takeUntil(this.destroySubject$),
        switchMap((x: any) => {
          if (x.body.data.updateToken && this.role === UserRoles.Member) {
            return this.lessonsService.apiV1LessonsLessonIdMediaTokenPost({
              lessonId: this.chatLesson$.value.id,
              body: {
                publishingState: newPublishingState,
                connectionId: this.openViduService.connectionId,
              },
            });
          } else {
            return of(null);
          }
        }),
        tap(x => {
          if (x?.data) {
            this.openViduService.connectionId = x.data?.connectionId;
            if (this.role === UserRoles.Member) {
              const updatedPublisherEnd = () => {
                this.openViduService.publisherStateChangingValue$.next(false);
              };
              this.openViduService.updatePublisherSubscriber(
                x.data.token,
                UserRoles.Member,
                x.data.isPublishing,
                updatedPublisherEnd,
              );
            } else {
              this.openViduService.publisherStateChangingValue$.next(false);
            }
          } else {
            this.openViduService.publisherStateChangingValue$.next(false);
          }
        }),
        catchError(() => {
          this.openViduService.publisherStateChangingValue$.next(false);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  startShare() {
    const changePublishingState = this.changePublishingState.bind(this);
    this.openViduService.startShare(changePublishingState);
  }

  stopShare(userRole: UserRole) {
    this.openViduService.stopShare(userRole);
    this.changePublishingState(ChatLessonMemberPublishingStateEnum.Screen, false, '');
  }

  subscribeSignals() {
    this.subscribeSignalrService();
  }

  batchAndSetPermissions(userids: number[], data: RightStatusChange) {
    let memberPermissionsInclude = [];
    let memberPermissionsExclude = [];

    data.enabled ? (memberPermissionsInclude = [data.type]) : (memberPermissionsExclude = [data.type]);

    const request: BatchChangePublishingPermissionRequest = {
      users: userids,
      include: memberPermissionsInclude,
      exclude: memberPermissionsExclude,
    };
    this.lessonsService
      .batchChangePublishinPermission({
        lessonId: this.chatLesson$.value.id,
        body: request,
      })
      .pipe(
        takeUntil(this.destroySubject$),
        tap(x => {
          this.updatePermission(x.body.data);
          this.groupLessonSignalrService.callTest(x.body.data);
        }),
      )
      .subscribe();
  }

  isMediaDevicesAccess(v) {
    return JSON.stringify(v) === `${JSON.stringify(MEDIA_DEVICES_AVAILABLE)}`;
  }

  handOn() {
    this.lessonsService
      .chatLessonReactionsPost({
        lessonId: this.chatLesson$.value.id,
        body: { type: ReactionTypeEnum.RaiseHand, show: true },
      })
      .pipe(takeUntil(this.destroySubject$))
      .subscribe();
  }

  handOff() {
    this.lessonsService
      .chatLessonReactionsPost({
        lessonId: this.chatLesson$.value.id,
        body: { type: ReactionTypeEnum.RaiseHand, show: false },
      })
      .pipe(takeUntil(this.destroySubject$))
      .subscribe();
  }

  private subscribeSignalrService() {
    this.signalrService.onLessonStatusChanged
      .pipe(
        takeUntil(this.destroySubject$),
        tap((x: any) => {
          if (x?.lessonId === +this.queryChatLessonId) {
            this.chatLessonStatus$.next(x.lessonStatusId);
          }
        }),
      )
      .subscribe();
  }

  //todo: Отправить методы кеша в отдельный сервис.
  public addOrReplaceCache(member: ChatLessonMember) {
    let index = this.cachedMembers.findIndex(c => c.member.memberId == member.memberId);
    const memberCache: MemberCache = {
      member,
      expiresIn: new Date().getTime() + 5 * 1000 * 60,
    };
    if (index >= 0) {
      this.cachedMembers[index] = memberCache;
    } else {
      this.cachedMembers.push(memberCache);
    }
  }

  //todo: Отправить методы кеша в отдельный сервис.
  private isCached(memberId: number) {
    const cachedMember = this.cachedMembers.find(c => c.member.memberId == memberId);
    return cachedMember?.expiresIn >= new Date().getTime();
  }

  //todo: Отправить методы кеша в отдельный сервис.
  private tryGetFromCache(memberId: number) {
    return this.cachedMembers.find(c => c.member.memberId == memberId);
  }

  private tryToAddMember(member: ChatLessonMember) {
    if (member.memberId == this.memberId) {
      return;
    }

    if (!this.groupLessonMemberManagerService.tryAddMember(member)) {
      return;
    }
    this.groupLessonPermissionService.addToAllLessonMembers(member);
    const memberState = {
      memberId: member.memberId,
      previousValue: [],
      currentValue: member.publishingState,
    };
    this.groupLessonPublishingStateService.setMemberState(memberState);
  }

  private synchronizeOnlineMembersExceptMeAndOwner(members: ChatLessonMember[]) {
    console.log('synchronizeOnlineMembersExceptMeAndOwner ', members)
    this.synchronizeMemberStates(members);
    this.synchronizeMembersList(members);
  }

  private synchronizeMemberStates(members: ChatLessonMember[]) {
    const currentStates = this.groupLessonPublishingStateService.getMembersState();

    const currentStatesWithId = currentStates.map(publishingState => this.composeStatesNameArrayById(publishingState));

    const membersStatesWithId = members.map(member => this.composeStatesNameArrayById(member));

    const statesEqual = membersStatesWithId.every(memberState => {
      return (
        JSON.stringify(memberState?.states) ===
        JSON.stringify(currentStatesWithId.find(member => member.memberId === memberState.memberId)?.states)
      );
    });

    if (!statesEqual) {
      const membersState = members.map(x => {
        return {
          memberId: x.memberId,
          previousValue: [],
          currentValue: x.publishingState,
        };
      });
      this.groupLessonPublishingStateService.setMembersState(membersState);
    }
  }

  private composeStatesNameArrayById(memberState: ChatLessonMemberPublishingState | ChatLessonMember) {
    const propertyName = !!memberState['currentValue'] ? 'currentValue' : 'publishingState';

    return {
      memberId: memberState.memberId,
      states: this.extractStateName(memberState[propertyName]),
    };
  }

  private extractStateName(states: ChatLessonMemberPublishingStateValue[]) {
    return states.reduce((states, current) => [...states, current.name], []);
  }

  private synchronizeMembersList(members: ChatLessonMember[]): void {
    const memberList = members.filter(member => member.isOnline === true && member.role != ChatLessonMemberRole.Owner);
    this.groupLessonMemberManagerService.synchronizeMembers(memberList);
  }

  private subscribeOpenViduService() {
    this.openViduService.videoPemissionDisabled$
      .pipe(
        tap(x => {
          this.changePublishingState(ChatLessonMemberPublishingStateEnum.Video, false, '');
        }),
      )
      .subscribe();

    this.openViduService.audioPemissionDisabled$
      .pipe(
        tap(x => {
          this.changePublishingState(ChatLessonMemberPublishingStateEnum.Audio, false, '');
        }),
      )
      .subscribe();

    this.openViduService.screenPemissionDisabled$
      .pipe(
        tap(x => {
          this.changePublishingState(ChatLessonMemberPublishingStateEnum.Screen, false, '');
        }),
      )
      .subscribe();
  }

  private subscribeGroupLessonSignalrService() {
    if (this.groupLessonSignalrService.onConnectionEstablished) {
      this.groupLessonSignalrService.onConnectionEstablished
        .pipe(
          switchMap(() =>
            this.lessonsService.apiV1LessonsLessonIdGet({
              lessonId: +this.queryChatLessonId,
            }),
          ),
          tap(({ data }) => this.synchronizeOnlineMembersExceptMeAndOwner(data.members)),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onReconnected) {
      this.groupLessonSignalrService.onReconnected
        .pipe(
          switchMap(() =>
            this.lessonsService.apiV1LessonsLessonIdGet({
              lessonId: +this.queryChatLessonId,
            }),
          ),
          tap(({ data }) => this.synchronizeOnlineMembersExceptMeAndOwner(data.members)),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onChatLessonMemberPublishingPermissionChanged) {
      this.groupLessonSignalrService.onChatLessonMemberPublishingPermissionChanged
        .pipe(
          tap(x => {
            this.updatePermission(x);
            const currentUser = x.find(x => x.memberId === this.memberId);
            if (
              currentUser &&
              currentUser.publishingPermissions.filter(
                x =>
                  x === ChatLessonMemberPublishingPermission.Audio || x === ChatLessonMemberPublishingPermission.Video,
              ).length === 0
            ) {
              this.openViduService.disableAudio();
              this.openViduService.disableVideo();
            }
          }),
        )
        .subscribe();
    }
    if (this.groupLessonSignalrService.onChatLessonMemberPublishingStateChanged) {
      this.groupLessonSignalrService.onChatLessonMemberPublishingStateChanged
        .pipe(
          tap(x => {
            this.updatePublishingState(x);
          }),
        )
        .subscribe();
    }
    if (this.groupLessonSignalrService.onMemberJoined) {
      this.groupLessonSignalrService.onMemberJoined
        .pipe(
          tap(x => {
            this.tryToAddMember(x.member);
            this.addOrReplaceCache(x.member);
          }),
        )
        .subscribe();
    }
    if (this.groupLessonSignalrService.onMemberLeft) {
      this.groupLessonSignalrService.onMemberLeft
        .pipe(
          filter(({ reason }) => reason === 2),
          tap(({ memberId }) => {
            this.groupLessonMemberManagerService.removeMemberClient(memberId);
            this.groupLessonPermissionService.removeFromAllLessonMembers(memberId);
          }),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onMemberConnected) {
      this.groupLessonSignalrService.onMemberConnected
        .pipe(
          tap(({ memberId }) => {
            this.groupLessonMemberManagerService.setMemberIsDisconnected(memberId, false);

            const ownerId = this.groupLessonMemberManagerService.owner$.value.member.memberId;
            if (memberId === ownerId) {
              this.isAway$.next(false);
            }

            // если есть отложенное удаление, отменяем его
            if (this.disconnectTimeouts[memberId]) {
              clearTimeout(this.disconnectTimeouts[memberId]);
              delete this.disconnectTimeouts[memberId];
              this.disconnectTimeouts[memberId] = null;
            } else {
              this.processMemberConnected(memberId);
            }
          }),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onMemberDisconnected) {
      this.groupLessonSignalrService.onMemberDisconnected
        .pipe(
          tap(({ memberId }) => {
            if (memberId == this.memberId)
              this.groupLessonSignalrService.restart();

            this.groupLessonMemberManagerService.setMemberIsDisconnected(memberId, true);

            const ownerId = this.groupLessonMemberManagerService.owner$.value.member.memberId;
            if (memberId === ownerId) {
              this.isAway$.next(true);
            } else {
              // устанавливаем таймер на удаление клиента через 7 секунд
              if (this.disconnectTimeouts[memberId] == null)
                this.disconnectTimeouts[memberId] = setTimeout(() => {
                  this.groupLessonMemberManagerService.removeMemberClient(memberId);
                  this.groupLessonReactionService.removeUserReaction(memberId);
                  delete this.disconnectTimeouts[memberId];
                }, 7000);
            }
          }),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onLessonCanBeExtended) {
      this.groupLessonSignalrService.onLessonCanBeExtended
        .pipe(
          tap(data => {
            this.canProlongData$.next(data);
          }),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onLessonExtended) {
      this.groupLessonSignalrService.onLessonExtended
        .pipe(
          tap(data => {
            const chatLessonData: ChatLesson = {
              id: data.lessonId,
              secondsLeft: data.secondsLeft,
            };
            this.initTimer(chatLessonData);
          }),
        )
        .subscribe();
    }

    if (this.groupLessonSignalrService.onModeratorRequestChangePublishingState) {
      this.groupLessonSignalrService.onModeratorRequestChangePublishingState
        .pipe(
          filter(v => this.requestNecessary(v)),
          tap(({ changeState }: RequestChangePublishingStateWithId) => {
            const newState = changeState[0];
            if (!this.isDisableState(newState)) {
              this.onRequestMediaState$.next(newState);
            } else {
              this.changeMediaStateByTypeWithNotify(newState.name);
            }
          }),
        )
        .subscribe();
    }
  }

  private isDisableState(newState: RequestChangePublishingState): boolean {
    return !newState.isOn;
  }

  private changeMediaStateByTypeWithNotify(deviceType: ChatLessonMemberPublishingStateEnum): void {
    if (deviceType === ChatLessonMemberPublishingStateEnum.Video) {
      this.videoStatusChange();
    } else {
      this.audioStatusChange();
    }
    this.disableMediaNotify$.next(deviceType);
  }

  private requestNecessary(member: RequestChangePublishingStateWithId): boolean {
    if (this.checkByMemberId(member.memberId) && member.changeState.length > 0) {
      return !this.mediaBlockByModerator(member.changeState) && this.isCurrentStateDifferent(member.changeState);
    }
    return false;
  }

  private mediaBlockByModerator(newStates: RequestChangePublishingState[]): boolean {
    return newStates.map(newState => !this.checkPermissionMedia(newState.name))[0];
  }

  private checkPermissionMedia(name: ChatLessonMemberPublishingStateEnum): boolean {
    switch (name) {
      case ChatLessonMemberPublishingStateEnum.Audio:
        return this.audioPermission;
      case ChatLessonMemberPublishingStateEnum.Video:
        return this.videoPermission;
      default:
        return false;
    }
  }

  private checkByMemberId(id: number | null): boolean {
    if (this.groupLessonMemberManagerService.isOwner(this.memberId)) {
      return false;
    }
    if (!id) {
      return true;
    }
    return id === this.memberId;
  }

  private isCurrentStateDifferent(newStates: RequestChangePublishingState[]): boolean {
    return newStates.map(newState => {
      const currentState = this.getMediaStateByName(newState.name);
      return currentState !== newState.isOn;
    })[0];
  }

  private getMediaStateByName(name: string): boolean {
    switch (name) {
      case ChatLessonMemberPublishingStateEnum.Audio:
        return this.openViduService.audioEnabled;
      case ChatLessonMemberPublishingStateEnum.Video:
        return this.openViduService.videoEnabled;
      default:
        return false;
    }
  }

  private subscribeGroupLessonWhiteBoardService() {
    this.groupLessonWhiteBoardService.whiteBoardInitEnd$
      .pipe(
        tap(x => {
          this.changePublishingState(ChatLessonMemberPublishingStateEnum.Whiteboard, true, x);
        }),
      )
      .subscribe();
  }

  private subscribeChatLesson(): void {
    this.chatLesson$.pipe(takeUntil(this.destroySubject$)).subscribe(l => (this.chatLessonSubject = l?.subject));
  }

  private processMemberConnected(memberId: number) {
    // console.log('member connected ', memberId)

    let memberCache = this.isCached(memberId) && this.tryGetFromCache(memberId);

    // console.log('memberCache ', memberCache)

    let isOwnerCache = memberCache?.member?.role === ChatLessonMemberRole.Owner;

    if (isOwnerCache) {
      this.isAway$.next(false);
    }

    if (memberCache) {
      if (isOwnerCache) {
        this.groupLessonMemberManagerService.addOwner(memberCache.member);
        // console.log('tryRestoreFromCache', 'Owner restored from cache', memberCache.member);
      } else {
        this.tryToAddMember(memberCache.member);
        // console.log('tryRestoreFromCache', 'Member restored from cache', memberCache.member);
      }
    } else {
      this.lessonsService
        .getChatLessonMember({ memberId })
        .pipe(
          first(),
          tap(({ body: { data: member } }) => {
            // console.log('member fresh ', member)
            let isOwner = member?.role === ChatLessonMemberRole.Owner;
            if (isOwner) {
              this.groupLessonMemberManagerService.addOwner(member);
              this.addOrReplaceCache(member);

              const memberState = {
                memberId: member.memberId,
                previousValue: [],
                currentValue: member.publishingState,
              };

              this.groupLessonPublishingStateService.setMemberState(memberState);
            } else {
              this.tryToAddMember(member);
              this.addOrReplaceCache(member);
            }
          }),
        )
        .subscribe();
    }
  }

  // иѝпользовать из апи занятия
  private setPermission(members: ChatLessonMember[]) {
    const member = members.find(x => x.memberId === this.memberId);
    this.groupLessonPermissionService.setChatLessonMember(member);
    this.groupLessonPermissionService.setAllLessonMembers(members);
  }

  // иѝпользовать из бев ѝокета или апи batch, тк как там только обновленные права юзеров
  private updatePermission(members: ChatLessonMember[]) {
    // console.log('updatePermission ', members)
    const currentValues = [...this.groupLessonPermissionService.allLessonMembers$.value];

    for (let mem of members) {
      const member = currentValues.find(x => x.memberId === mem.memberId);

      if (member) {
        const index = currentValues.indexOf(member);
        currentValues.splice(index, 1);
      }
    }

    const newValues = members.concat(currentValues);
    this.setPermission(newValues);
  }

  private updatePublishingState(member: ChatLessonMember) {
    this.groupLessonPublishingStateService.setMemberState({
      memberId: member.memberId,
      currentValue: member.publishingState,
      previousValue: [],
    });

    this.addOrReplaceCache(member);
  }

  private initTimer(data: ChatLesson) {
    const timerDate = new Date();
    const timeLeft = data.secondsLeft;
    timerDate.setHours(0);
    timerDate.setMinutes(0);
    timerDate.setSeconds(timeLeft);

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    const timer$ = timer(0, 1000);
    this.timerSubscription = timer$.subscribe(() => {
      const hours = timerDate.getHours();
      const minutes = timerDate.getMinutes();
      const seconds = timerDate.getSeconds();
      const lessonTimeEnd = hours + minutes + seconds === 0;
      if (lessonTimeEnd) {
        this.timerSubscription.unsubscribe()
      }
      else {
        this.timeDuration$.next(timerDate.setSeconds(timerDate.getSeconds() - 1));
      }
    });
  }

  getSpeakersByStreams(streams: StreamManager[], speechIds: string[]) {
    streams = streams.filter(x => !!x);
    return streams
      .filter(member => speechIds.includes(member?.stream?.connection?.connectionId))
      .map(el => el.stream?.connection?.connectionId);
  }

  private subscribeMediaPermission() {
    this.groupLessonPermissionService.videoPermission$
      .pipe(
        untilDestroyed(this),
        tap(x => this.videoPermission = x)
      )
      .subscribe();

    this.groupLessonPermissionService.audioPermission$
      .pipe(
        untilDestroyed(this),
        tap(x => this.audioPermission = x)
      )
      .subscribe();
  }

  /* отпиѝатьѝѝ от вѝего когда компонент onDestroy */
  dispose() {
    this.lessonIsDestroyed.next(true);
    this.cachedMembers = [];
    this.chatLesson$.next(null);
    this.toolbarDropDownIsOpen.next(null);
    this.destroySubject$.next();
    this.destroySubject$.complete();
    this.queryChatLessonId = null;
    this.openViduService.setMediaStateDefault();
    this.groupLessonWaitService.publishingState = [{ name: ChatLessonMemberPublishingStateEnum.None, arg: '' }];
    this.disconnectTimeouts = {};
    this.leaveSession();
  }
}
