import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorUserCodes } from '@ezteach/api/constants/api-error-user-codes.constants';
import { ModalSimpleError } from '@ezteach/modals/modal-simple-error/modal-simple-error.component';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';
import { BooleanApiResponse } from '../../api/models/boolean-api-response';
import { BookmarksService } from '../../api/services/bookmarks.service';
import { LessonsService } from '../../api/services/lessons.service';
import { ModalInstantRequestCancel } from '../../modals/instant-request-cancel/modal-instant-request-cancel.component';
import { ModalInstantRequest } from '../../modals/instant-request/modal-instant-request.component';
import { LocalStorageService } from '../../_services/local-storage.service';
import { RequestsService } from '../../_services/requests.service';
import { SignalrService } from '../../_services/signalr.service';
import { UserService } from "@ezteach/_services/user.service";
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';

@UntilDestroy()
@Component({
  selector: 'tutor-user-search-result',
  templateUrl: 'tutor-user-search-result.component.html',
  // styleUrls: ['tutor-user-search-result.component.styl']
  providers: [UserAvatarBackgroundPipe],
})
export class TutorUserSearchResultComponent implements OnInit {
  response: BooleanApiResponse;

  @Input() item;
  @Input() marked: boolean;
  @Input() type: string;
  @Input() message: string;
  @Input() duration: number;
  @Input() discipline: string;

  userdata;
  diplom;
  disciplines;
  bookmarks;
  requestId;
  dialogRef;
  environment;
  rating=0;
  reviewsCount=4;
  freetime='Есть время завтра'

  @Output() bookmarksUpdated = new EventEmitter<number[]>();

  constructor (
    public dialog: MatDialog,
    private userAvatarBackgroundPipe: UserAvatarBackgroundPipe,
    private _cdr: ChangeDetectorRef,
    private bookmarksService: BookmarksService,
    private lessonsService: LessonsService,
    private signalRService: SignalrService,
    public requestsService: RequestsService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    
    if (this.item.tutor.rating==null){
      this.item.tutor.rating=0;
    }
    this.reviewsCount=4;
    this.environment = environment;
    if (this.userdata.isStudent) {
      this.getBookmarks();
    }

    this._cdr.detectChanges();
  }

  sendRequestInstant(item) {
    this.lessonsService
      .apiV1LessonsInstantRequestsPost({
        body: {
          CalleeId: item.id,
          SubjectTitle: this.discipline,
          DurationMinutes: this.duration,
          MessageText: this.message,
        },
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.requestsService.sending$.next(true);

          this.requestsService.update();
          this.requestId = response.data.id;

          this.dialogRef = this.dialog.open(ModalInstantRequest, {
            panelClass: 'modal-instant-request',
            data: {
              requestId: this.requestId,
            },
          });

          this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'cancel') {
              this.requestsService.sending$.next(false);
            }
          });
        },
        error => {
          this.requestsService.sending$.next(false);

          const errorApiResponse = error.error.Error;
          this.dialog.open(ModalSimpleError, {
            panelClass: 'modal-simple-error',
            data: {
              title: this.translocoService.translate('Запрос на событие не отправлен'),
              text:
                this.translocoService.translate(ApiErrorUserCodes[errorApiResponse.Code]) ??
                errorApiResponse.Description,
            },
          });
        },
      );

    this.signalRService.onChatRequestCanceled.subscribe(data => {
      if (data.requestId === this.requestId && this.requestsService.sending$.value) {
        this.dialogRef.close();
        this.requestsService.sending$.next(false);


        this.dialogRef = this.dialog.open(ModalInstantRequestCancel, {
          panelClass: 'modal-instant-request-cancel',
          data: {},
        });
      }
    });

    this.signalRService.onChatRequestAccepted.subscribe((data: any) => {
      this.dialogRef.close();
      const id = data.joinIdentity;
      this.router.navigate(['/wait'], {
        queryParams: { id },
        relativeTo: this.route,
      });
    });
  }

  getBookmarks(): void {
    this.bookmarks = this.localStorageService.get('bookmarks');
    this._cdr.detectChanges();
  }

  getDiplom(item) {
    if (item.documents) {
      let diplom = item.documents
        .filter(item => item.documentTypeId === 'AcademicDegree')
        .map(item => this.translocoService.translate(item.academicDegreeName))
        .join(', ')
        .toLowerCase();
        
      return (diplom = diplom.charAt(0).toUpperCase() + diplom.substring(1)).split('&nbsp;').join(' ');
    } else {
      if (item.userDocuments) {
        let diplom = item.userDocuments
          .filter(item => item.documentTypeId === 'AcademicDegree')
          .map(item => this.translocoService.translate(item.academicDegreeName))
          .join(', ')
          .toLowerCase();
          
        return (diplom = diplom.charAt(0).toUpperCase() + diplom.substring(1)).split('&nbsp;').join(' ');
      } else {
        return '';
      }
    }
  }

  image(item) {
    if (item.avatarFileName) {
      return this.userAvatarBackgroundPipe.transform(item); 
    } else {
      return null;
    }
  }

  initials(item) {
    return item.firstName.charAt(0).toUpperCase() + item.lastName.charAt(0).toUpperCase();
  }

  getDisciplines(tutor) {
    const items = tutor.disciplineSpecializations.map(item => this.translocoService.translate(item.discipline.name)).join(', ');
    return items.charAt(0).toUpperCase() + items.substring(1);
  }

  bookmark(event) {
    event.target.closest('.content').classList.toggle('bookmarked');
    const userdata = this.userService.userData$.value;
    const tutorId = this.item.tutor.id;

    this.bookmarksService
      .apiV1BookmarksTutorTogglePost({
        body: {
          UserId: userdata.id,
          TutorId: tutorId,
        },
      })
      .subscribe((response: BooleanApiResponse) => {
        this.getBookmarks();
        if (!response.data) {
          this.bookmarks.push(tutorId);
        } else {
          this.bookmarks = this.bookmarks.filter(obj => obj !== tutorId);
        }

        this.bookmarksUpdated.emit(this.bookmarks);
        this.localStorageService.set('bookmarks', this.bookmarks);
        this._cdr.detectChanges();
      });
  }
}
