<div class="profile">
  <div class="profile_header">
    <div class="profile_header__left">
      <div class="profile_header__title">{{ 'Личный кабинет' | transloco }}</div>
    </div>
    <div class="profile_header__right">
      <div class="profile_header__title">{{ title | transloco }}</div>
    </div>
  </div>
  <div class="profile_content">
    <div class="profile_content__left">
      <div class="profile_image">
        <img
          src=""
          *ngIf="givenAvatar"
          src="{{ givenAvatar }}"
        />
        <div
          class="profile_image__letters"
          *ngIf="!givenAvatar"
        >
          {{ giverInitials }}
        </div>

        <input
          id="avatar"
          multiple
          type="file"
          (change)="avatarChange($event)"
        />
        <label
          class="button button-avatar"
          for="avatar"
        >
          <svg-icon-sprite
            [src]="'icon-upload-image'"
            [width]="'23px'"
            [height]="'23px'"
            [viewBox]="'0 0 23 23'"
          ></svg-icon-sprite>
        </label>
      </div>
      <div
        class="profile_name"
        *ngIf="userdata"
      >
        {{ userdata.name }}
      </div>
      <div
        class="profile_nav"
        *ngIf="loaded"
      >
        <div class="profile_nav__item">
          <a
            routerLink="/profile-old/common"
            routerLinkActive="active"
            >{{ 'Общая информация' | transloco }}</a
          >
        </div>
        <!--div
          class="profile_nav__item"
          *ngIf="userdata.isStudent"
        >
          <a
            routerLink="/profile-old/patron"
            routerLinkActive="active"
            >{{ 'Данные родителя' | transloco }}
          </a>
        </div-->
        <div
          class="profile_nav__item"
          *ngIf="userdata.isStudent"
        >
          <a
            routerLink="/profile-old/bankcards"
            routerLinkActive="active"
            >{{ 'Финансовая информация' | transloco }}</a
          >
        </div>
        <!--div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/passport"
            routerLinkActive="active"
            >{{ 'Паспортные данные' | transloco }}</a
          >
        </div>
        <div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/specs"
            routerLinkActive="active"
            >{{ 'Специализация' | transloco }}</a
          >
        </div>
        <div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/education"
            routerLinkActive="active"
            >{{ 'Образование' | transloco }}</a
          >
        </div>
        <div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/reference"
            routerLinkActive="active"
            >{{ 'Рекомендательные письма' | transloco }}</a
          >
        </div>
        <div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/work"
            routerLinkActive="active"
            >{{ 'Трудовая история' | transloco }}</a
          >
        </div-->
        <!--div
          class="profile_nav__item"
          *ngIf="userdata.isTutor"
        >
          <a
            routerLink="/profile-old/bank"
            routerLinkActive="active"
            >{{ 'Финансовая информация' | transloco }}</a
          >
        </div>
        <!--div class="profile_nav__item">
          <a href="{{ environment.authUrl }}/auth/changepassword?returnUrl={{ environment.appUrl }}/profile"
            >{{ 'Смена пароля' | transloco }}
          </a>
        </div-->
      </div>
    </div>
    <div
      class="profile_content__right"
      *ngIf="loaded"
    >
      <div
        class="profile_common"
        *ngIf="mod === 'common'"
      >
        <div class="profile_content__title">{{ 'Редактирование профиля' | transloco }}</div>
        <div class="profile_content__subtitle">{{ 'Персональные данные' | transloco }}:</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Имя' | transloco }}</mat-label>
              <input
                matInput
                name="firstName"
                placeholder="Имя"
                maxlength="20"
                [(ngModel)]="currentData.personalDetails.firstName"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Фамилия' | transloco }}</mat-label>
              <input
                matInput
                name="lastName"
                placeholder="Фамилия"
                maxlength="20"
                [(ngModel)]="currentData.personalDetails.lastName"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                name="email"
                placeholder="E-mail"
                [(ngModel)]="currentData.personalDetails.email"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Телефон' | transloco }}</mat-label>
              <input
                matInput
                name="phone"
                placeholder="Телефон"
                prefix="+7 "
                mask="(000) 000-00-00"
                [(ngModel)]="currentData.personalDetails.phone"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Дата рождения' | transloco }}</mat-label>
              <input
                matInput
                (change)="dateChanged($event, birthDate, 'personalDetails.birthDate')"
                [ngModel]="currentData.personalDetails.birthDate | date: 'dd.MM.yyyy'"
                mask="00.00.0000"
                [dropSpecialCharacters]="false"
                #birthDate
              />
              <input
                matInput
                [(ngModel)]="currentData.personalDetails.birthDate"
                [matDatepicker]="picker"
                [min]="minDate"
                [max]="maxDate"
                style="position: absolute; z-index: -1; opacity: 0"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            class="profile_content__col"
            *ngIf="userdata.isStudent"
          >
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Учебное заведение' | transloco }}</mat-label>
              <input
                matInput
                name="educationalInstitutionName"
                maxlength="30"
                placeholder="Учебное заведение"
                [(ngModel)]="currentData.personalDetails.educationalInstitutionName"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.personal"
          >
            <p>{{ 'Заполните корректно все поля' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="savePersonal()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_passport"
        *ngIf="mod === 'passport'"
      >
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <div class="profile_content__title">{{ 'Личная информация' | transloco }}</div>
            <mat-form-field class="profile_content__input">
              <mat-label>Фамилия</mat-label>
              <input
                matInput
                name="lastName"
                (blur)="blurValue($event)"
                maxlength="20"
                value="{{ currentData.passportDetails?.lastName }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Имя' | transloco }}</mat-label>
              <input
                matInput
                name="firstName"
                (blur)="blurValue($event)"
                maxlength="20"
                value="{{ currentData.passportDetails?.firstName }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Отчество' | transloco }}</mat-label>
              <input
                matInput
                name="patronymic"
                (blur)="blurValue($event)"
                maxlength="30"
                value="{{ currentData.passportDetails?.patronymic }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <div class="profile_content__title">{{ 'Адрес регистрации' | transloco }}</div>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Улица, дом, квартира' | transloco }}</mat-label>
              <input
                matInput
                name="addressDetails"
                (blur)="blurValue($event)"
                value="{{ currentData.passportDetails?.registrationAddress?.addressDetails }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Город' | transloco }}</mat-label>
              <input
                matInput
                name="city"
                (blur)="blurValue($event)"
                maxlength="30"
                value="{{ currentData.passportDetails?.registrationAddress?.city }}"
              />
            </mat-form-field>
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Область' | transloco }}</mat-label>
              <input
                matInput
                name="region"
                maxlength="30"
                value="{{ currentData.passportDetails?.registrationAddress?.region }}"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="profile_content__title">{{ 'Подтверждение личности' | transloco }}</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Номер паспорта (без пробелов)' | transloco }}</mat-label>
              <input
                matInput
                name="number"
                (blur)="blurValue($event)"
                mask="0000 000000"
                value="{{ currentData.passportDetails?.number }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Дата выдачи (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
              <input
                matInput
                (change)="dateChanged($event, issueDate, 'passportDetails.issueDate')"
                [ngModel]="currentData.passportDetails.issueDate | date: 'dd.MM.yyyy'"
                mask="00.00.0000"
                [dropSpecialCharacters]="false"
                #issueDate
              />
              <input
                matInput
                [(ngModel)]="currentData.passportDetails.issueDate"
                [matDatepicker]="picker"
                [min]="minDate"
                [max]="maxDate"
                style="position: absolute; z-index: -1; opacity: 0"
              />
              <!--<input matInput name="issueDate" (blur)="blurValueDate($event)" mask value="{{currentData.passportDetails?.issueDate}}" [matDatepicker]="picker">-->
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Кем выдан' | transloco }}</mat-label>
          <input
            matInput
            name="issueAuthority"
            (blur)="blurValue($event)"
            value="{{ currentData.passportDetails?.issueAuthority }}"
          />
        </mat-form-field>

        <div class="profile_content__subtitle">{{ 'Загруженные файлы паспорта' | transloco }}</div>
        <div
          class="files_list"
          *ngIf="currentData.passportDetails?.documentFiles?.length !== 0"
        >
          <ng-container *ngFor="let file of currentData.passportDetails?.documentFiles; let i = index">
            <div
              class="file__wrap"
              *ngIf="file"
            >
              <a
                class="file"
                href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{ userdata.id }}/passport?redirect=true&file={{
                  file.fileName
                }}&access_token={{ token }}"
                target="_blank"
              >
                <span class="file__icon">
                  <img src="/assets/img/svg/icon-file.svg" />
                </span>
                <span class="file__info">
                  <span class="file__name">{{ file.displayFileName }}</span>
                  <span class="file__size">{{ file.fileSize | fileSize }}</span>
                </span>
              </a>
              <div class="file__delete">
                <a (click)="removePassportFile(i)">{{ 'Удалить' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </div>

        <input
          id="passport"
          class="hideinput"
          multiple
          type="file"
          (change)="addPassportFile($event)"
        />
        <label
          for="passport"
          class="btn btn--blue-border"
        >
          <span class="btn__label">{{ 'Загрузить' | transloco }}</span>
        </label>

        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="savePassport()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_specs"
        *ngIf="mod === 'specs'"
      >
        <div class="profile_content__title">{{ 'Специализация' | transloco }}:</div>
        <div class="profile__row">
          <mat-form-field>
            <input
              type="text"
              name="discipline"
              [value]="discipline | transloco"
              [(ngModel)]="discipline"
              [placeholder]="'Предмет' | transloco"
              matInput
              [formControl]="disciplinesControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
            >
              <mat-option
                *ngFor="let discipline of disciplinesFiltered | async"
                [value]="discipline"
              >
                {{ discipline | transloco}}
              </mat-option>
            </mat-autocomplete> </mat-form-field
          ><br />
          <a
            class="btn btn--blue-border"
            (click)="addDiscipline()"
          >
            <span class="btn__label">{{ 'Добавить предмет' | transloco }}</span>
          </a>
        </div>
        <hr />
        <ng-container *ngIf="disciplinesCurrent">
          <div *ngFor="let dis of disciplinesCurrent; let i = index">
            <div
              class="discipline"
              *ngIf="dis"
            >
              <div class="discipline__title">{{ dis.name | transloco}}</div>
              <div class="discipline__list">
                <label *ngFor="let spec of getSpecs(dis.id)">
                  <input
                    type="checkbox"
                    class="spec"
                    name="spec"
                    value="{{ spec.id }}"
                    [checked]="isSpec(spec.id)"
                  />
                  {{ spec.specialization.name | transloco}}
                </label>
              </div>
              <div class="discipline__delete">
                <a (click)="removeDiscipline(i)">{{ 'Личный кабинет' | transloco }}Удалить</a>
              </div>
              <hr />
            </div>
          </div>
        </ng-container>
        <div class="profile_content__title">{{ 'Стоимость события' | transloco }}:</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Быстрое событие (за 5 минут)' | transloco }}</mat-label>
              <input
                matInput
                name="instantLesson5MinuteRate"
                [mask]
                alias="decimal"
                (focus)="selectAll($event)"
                placeholder="Быстрое событие (за 5 минут)"
                value="{{ currentData.specialityDetails?.instantLesson5MinuteRate }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Классическое событие (за 45 минут)' | transloco }}</mat-label>
              <input
                matInput
                name="scheduledLessonHourlyRate"
                [mask]
                alias="decimal"
                (focus)="selectAll($event)"
                placeholder="Классическое событие (за 45 минут)"
                value="{{ currentData.specialityDetails?.scheduledLessonHourlyRate }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="disciplinesError"
          >
            <p>{{ 'Укажите, как минимум одну специализацию у каждого выбранного предмета' | transloco }}</p>
          </div>
          <div
            class="error_label"
            *ngIf="errors.specs"
          >
            <p>{{ 'Добавьте, как минимум один предмет' | transloco }}</p>
          </div>
          <div
            class="error_label"
            *ngIf="errors.price"
          >
            <p>{{ 'Укажите стоимость событий' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveSpecs()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить и продолжить' | transloco }}</span>
          </button>
        </div>
      </div>

      <div
        class="profile_education"
        *ngIf="mod === 'education'"
      >
        <div class="profile_content__title">{{ 'Среднее/Среднее специальное образование' | transloco }}</div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'SecondaryEducation'">
            <div class="group secondary">
              <mat-form-field class="profile_content__input">
                <mat-label>{{ 'Название' | transloco }}</mat-label>
                <input
                  matInput
                  class="name"
                  (blur)="blurValue($event)"
                  maxlength="30"
                  name="educationalInstitutionName{{ ind }}"
                  value="{{ doc.educationalInstitutionName }}"
                />
              </mat-form-field>
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Начало обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="from"
                      matInput
                      mask
                      name="enrollmentDate{{ ind }}"
                      value="{{ doc.enrollmentDate }}"
                      [matDatepicker]="SecEnrollmentDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="SecEnrollmentDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #SecEnrollmentDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Конец обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="to"
                      matInput
                      mask
                      value="{{ doc.graduateDate }}"
                      [matDatepicker]="SecGraduateDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="SecGraduateDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #SecGraduateDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addSecFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label
              ><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/secondary-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ file.fileSize | fileSize }}</span>
                      </span>
                    </a>
                    <div class="file__delete">
                      <a (click)="removeSecFile(i, ind)">{{ 'Удалить' | transloco }}</a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeSec(ind)">{{ 'Удалить запись' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addSec()"
        >
          <span class="btn__label">{{ 'Добавить школу' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__title">{{ 'Высшее образование' | transloco }}</div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'HigherEducation'">
            <div class="group high">
              <mat-form-field class="profile_content__input">
                <mat-label>{{ 'Название' | transloco }} </mat-label>
                <input
                  matInput
                  class="name"
                  (blur)="blurValue($event)"
                  maxlength="30"
                  name="educationalInstitutionName{{ ind }}"
                  value="{{ doc.educationalInstitutionName }}"
                />
              </mat-form-field>
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Начало обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="from"
                      mask
                      (blur)="blurValue($event)"
                      matInput
                      name="enrollmentDate{{ ind }}"
                      value="{{ doc.enrollmentDate }}"
                      [matDatepicker]="HighEnrollmentDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="HighEnrollmentDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #HighEnrollmentDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Конец обучения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="to"
                      mask
                      matInput
                      name="graduateDate{{ ind }}"
                      value="{{ doc.graduateDate }}"
                      [matDatepicker]="HighGraduateDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="HighGraduateDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #HighGraduateDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addHighFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label
              ><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/higher-education?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ file.fileSize | fileSize }}</span>
                      </span>
                    </a>
                    <div class="file__delete">
                      <a (click)="removeHighFile(i, ind)">{{ 'Удалить' | transloco }}</a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeHigh(ind)">{{ 'Удалить запись' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addHigh()"
        >
          <span class="btn__label">{{ 'Добавить вуз' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__title">{{ 'Ученая степень (если есть)' | transloco }}</div>
        <ng-container *ngFor="let doc of currentData.educationDetails.documents; let ind = index">
          <ng-container *ngIf="doc?.documentTypeId === 'AcademicDegree'">
            <div class="group academic">
              <div class="profile_content__cols">
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Название' | transloco }}</mat-label>
                    <select
                      matNativeControl
                      class="name"
                      maxlength="30"
                      name="academicDegreeName{{ ind }}"
                    >
                      <option
                        *ngFor="let item of academicDegrees"
                        [selected]="item.id === doc.academicDegreeId"
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="profile_content__col">
                  <mat-form-field class="profile_content__input">
                    <mat-label>{{ 'Дата присвоения (ДД.ММ.ГГГГ)' | transloco }}</mat-label>
                    <input
                      class="date"
                      matInput
                      mask
                      (blur)="blurValue($event)"
                      name="issueDate{{ ind }}"
                      value="{{ doc.issueDate }}"
                      [matDatepicker]="AcademicIssueDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="AcademicIssueDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #AcademicIssueDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="profile_content__subtitle">{{ 'Загруженные файлы аттестатов' | transloco }}</div>
              <input
                id="sec{{ ind }}"
                class="hideinput"
                multiple
                type="file"
                (change)="addAcademicFile($event)"
              />
              <label
                for="sec{{ ind }}"
                class="btn btn--blue-border"
              >
                <span class="btn__label">{{ 'Загрузить файл' | transloco }}</span> </label
              ><br /><br />
              <div
                class="files_list"
                *ngIf="doc.documentFiles.length !== 0"
              >
                <ng-container *ngFor="let file of doc.documentFiles; let i = index">
                  <div
                    class="file__wrap"
                    *ngIf="file"
                  >
                    <a
                      class="file"
                      href="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                        userdata.id
                      }}/academic-degree?redirect=true&file={{ file.fileName }}&access_token={{ token }}"
                      target="_blank"
                    >
                      <span class="file__icon">
                        <img src="/assets/img/svg/icon-file.svg" />
                      </span>
                      <span class="file__info">
                        <span class="file__name">{{ file.displayFileName }}</span>
                        <span class="file__size">{{ file.fileSize | fileSize }}</span>
                      </span>
                    </a>
                    <div class="file__delete">
                      <a (click)="removeAcademicFile(i, ind)">{{ 'Удалить' | transloco }}</a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="profile_content__delete">
                <a (click)="removeAcademic(ind)">{{ 'Удалить запись' | transloco }}</a>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <button
          class="btn btn--blue-border"
          (click)="addAcademic()"
        >
          <span class="btn__label">{{ 'Добавить ученую степень' | transloco }}</span>
        </button>
        <hr />

        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="educationError"
          >
            <p>{{ 'Заполните все поля по каждой записи' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveEducation()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ '}Сохранить изменения' | transloco }</span>
          </button>
        </div>
      </div>

      <div
        class="profile_reference"
        *ngIf="mod === 'reference'"
      >
        <div class="profile_content__subtitle">{{ 'Загруженные файлы рекомендательных писем' | transloco }}</div>
        <div
          class="reference_list"
          *ngIf="currentData.referenceLetters.documents.length !== 0"
        >
          <ng-container *ngFor="let item of currentData.referenceLetters.documents; let i = index">
            <div
              class="reference_item"
              *ngIf="item"
            >
              <div
                class="reference_item__image"
                *ngIf="item"
              >
                <img
                  src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                    userdata.id
                  }}/reference-letters?redirect=true&file={{ item.documentFiles[0].fileName }}&access_token={{ token }}"
                />
              </div>
              <div
                class="reference_item__content"
                *ngIf="item"
              >
                <div class="reference_item__name">{{ item.documentFiles[0].displayFileName }}</div>
                <div class="reference_item__delete">
                  <a (click)="removeReference(i)">{{ 'Удалить' | transloco }}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <input
          id="reference"
          class="hideinput"
          multiple
          type="file"
          (change)="addReference($event)"
        />
        <label
          for="reference"
          class="btn btn--blue-border"
        >
          <span class="btn__label">{{ 'Загрузить' | transloco }}</span>
        </label>

        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveReference()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_work"
        *ngIf="mod === 'work'"
      >
        <div class="profile_content__title">{{ 'Данные трудовой книжки' | transloco }}</div>

        <ng-container *ngFor="let item of currentData.workExperience.workPlaces; let i = index">
          <div class="workCompany">
            <div
              class="profile_content__cols"
              *ngIf="item"
            >
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Учреждение' | transloco }}</mat-label>
                  <input
                    matInput
                    name="companyName[{{ i }}]"
                    maxlength="30"
                    class="companyName"
                    (blur)="blurValue($event)"
                    value="{{ item.companyName }}"
                  />
                </mat-form-field>
              </div>
            </div>

            <div
              class="profile_content__cols"
              *ngIf="item"
            >
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Начало рабочей деятельности' | transloco }}</mat-label>
                  <input
                    matInput
                    name="enrollmentDate[{{ i }}]"
                    mask
                    (blur)="blurValue($event)"
                    class="enrollmentDate"
                    value="{{ item.enrollmentDate }}"
                    [matDatepicker]="WorkEnrollmentDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="WorkEnrollmentDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WorkEnrollmentDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="profile_content__col">
                <mat-form-field class="profile_content__input">
                  <mat-label>{{ 'Окончание рабочей деятельности' | transloco }}</mat-label>
                  <input
                    matInput
                    name="leavingDate[{{ i }}]"
                    mask
                    class="leavingDate"
                    value="{{ item.leavingDate }}"
                    [matDatepicker]="WorkLeavingDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="WorkLeavingDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #WorkLeavingDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div
              class="profile_content__delete"
              *ngIf="item"
            >
              <a (click)="removeWork(i)">{{ 'Удалить запись' | transloco }}</a>
            </div>
          </div>
        </ng-container>

        <button
          class="btn btn--blue-border"
          (click)="addWork()"
        >
          <span class="btn__label">{{ 'Добавить запись' | transloco }}</span>
        </button>
        <br /><br />

        <div class="profile_content__subtitle">{{ 'Загруженные файлы трудовой книжки' | transloco }}</div>
        <div
          class="reference_list"
          *ngIf="currentData.workExperience.documentFiles.length !== 0"
        >
          <ng-container *ngFor="let item of currentData.workExperience.documentFiles; let i = index">
            <div
              class="reference_item"
              *ngIf="item"
            >
              <div
                class="reference_item__image"
                *ngIf="item"
              >
                <img
                  src="{{ environment.apiUrl }}/api/v1/files/usercontent/{{
                    userdata.id
                  }}/work-experience?redirect=true&file={{ item.fileName }}&access_token={{ token }}"
                />
              </div>
              <div
                class="reference_item__content"
                *ngIf="item"
              >
                <div class="reference_item__name">{{ item.displayFileName }}</div>
                <div class="reference_item__delete">
                  <a (click)="removeWorkFile(i)">{{ 'Удалить' | transloco }}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <input
          id="work"
          class="hideinput"
          multiple
          type="file"
          (change)="addWorkFile($event)"
        />
        <label
          for="work"
          class="btn btn--blue-border"
        >
          <span class="btn__label">Загрузить</span>
        </label>

        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="workError"
          >
            <p>{{ 'Заполните название, начало и окончание рабочей деятельности по каждой записи' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveWork()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_patron"
        *ngIf="mod === 'patron'"
      >
        <div class="profile_content__title">{{ 'Родитель/опекун' | transloco }}</div>
        <div class="profile_content__subtitle">{{ 'Данные родителя' | transloco }}:</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Имя' | transloco }}</mat-label>
              <input
                matInput
                name="firstName"
                placeholder="Имя"
                maxlength="30"
                [(ngModel)]="currentData.studentPatron.patron.firstName"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Фамилия' | transloco }}</mat-label>
              <input
                matInput
                name="lastName"
                placeholder="Фамилия"
                maxlength="30"
                [(ngModel)]="currentData.studentPatron.patron.lastName"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                name="email"
                placeholder="E-mail"
                [(ngModel)]="currentData.studentPatron.patron.email"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Телефон' | transloco }}</mat-label>
              <input
                matInput
                name="phone"
                placeholder="Телефон"
                prefix="+7 "
                mask="(000) 000-00-00"
                [(ngModel)]="currentData.studentPatron.patron.phone"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__action">
          <div
            class="error_label"
            *ngIf="errors.patron"
          >
            <p>{{ 'Заполните корректно все поля' | transloco }}</p>
          </div>
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="savePatron()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_bankcards"
        *ngIf="mod === 'bankcards'"
      >
        <div class="profile_content__title">{{ '}Ваши привязанные карты' | transloco }</div>
        <div class="profile_cardlist">
          <div
            class="profile_card"
            *ngFor="let card of currentData.bankCardBindings.bankCards"
          >
            <div class="profile_card__wrap">
              <div class="profile_card__name">{{ card.cardHolderName }}</div>
              <div class="profile_card__type">{{ card.paymentSystemName }}</div>
              <div class="profile_card__num">{{ card.maskedPan }}</div>
              <div class="profile_card__exp">{{ card.expirationFormatted }}</div>
            </div>
            <mat-radio-button
              name="bankcard"
              value="{{ card.id }}"
              [checked]="currentData.bankCardBindings.defaultBankCardId == card.id"
              >{{ 'Основная карта' | transloco }}</mat-radio-button
            >
            <a
              class="profile_card__delete"
              (click)="removeBankcard(card.id)"
              >{{ 'Удалить карту' | transloco }}</a
            >
          </div>
        </div>
        <button
          class="btn btn--blue-border"
          (click)="addBankcard()"
        >
          <span class="btn__label">{{ 'Добавить новую карту' | transloco }}</span>
        </button>
        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveBankCards()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>
      <div
        class="profile_bank"
        *ngIf="mod === 'bank'"
      >
        <div class="profile_content__title">{{ 'Личный кабинет' | transloco }}Финансовая информация</div>
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Личный кабинет' | transloco }}ИНН</mat-label>
          <input
            matInput
            name="inn"
            maxlength="15"
            value="{{ currentData.financialDetails.inn }}"
          />
        </mat-form-field>
        <hr />
        <div class="profile_content__title">{{ 'Данные банковского счета' | transloco }}</div>
        <mat-form-field class="profile_content__input">
          <mat-label>{{ 'Номер счета' | transloco }}</mat-label>
          <input
            matInput
            name="accountNumber"
            maxlength="32"
            value="{{ currentData.financialDetails.bankDetails.accountNumber }}"
          />
        </mat-form-field>
        <div class="profile_content__title">{{ 'Информация о банке' | transloco }}</div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Полное название' | transloco }}</mat-label>
              <input
                matInput
                name="fullName"
                maxlength="50"
                value="{{ currentData.financialDetails.bankDetails.bank.fullName }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Краткое название' | transloco }}</mat-label>
              <input
                matInput
                name="shortName"
                maxlength="50"
                value="{{ currentData.financialDetails.bankDetails.bank.shortName }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <mat-form-field class="profile_content__input">
            <mat-label>{{ 'Корр. счет' | transloco }}</mat-label>
            <input
              matInput
              name="correspondentAccountNumber"
              value="{{ currentData.financialDetails.bankDetails.bank.correspondentAccountNumber }}"
            />
          </mat-form-field>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'БИК' | transloco }}</mat-label>
              <input
                matInput
                name="bic"
                maxlength="15"
                value="{{ currentData.financialDetails.bankDetails.bank.bic }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>SWIFT</mat-label>
              <input
                matInput
                name="swift"
                maxlength="15"
                value="{{ currentData.financialDetails.bankDetails.bank.swift }}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="profile_content__cols">
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'КПП' | transloco }}</mat-label>
              <input
                matInput
                name="kpp"
                maxlength="15"
                value="{{ currentData.financialDetails.bankDetails.bank.kpp }}"
              />
            </mat-form-field>
          </div>
          <div class="profile_content__col">
            <mat-form-field class="profile_content__input">
              <mat-label>{{ 'Адрес' | transloco }}</mat-label>
              <input
                matInput
                name="address"
                value="{{ currentData.financialDetails.bankDetails.bank.address }}"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="profile_content__action">
          <mat-spinner
            diameter="30"
            class="loader"
            *ngIf="loading"
          ></mat-spinner>
          <button
            class="btn btn--blue btn--submit"
            (click)="saveBank()"
            *ngIf="!loading"
          >
            <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
