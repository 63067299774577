import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LangStoreService } from '@ezteach/shared/services/lang-store.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ezteach-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  @Input() userdata: any;
  @Output() onOpenSubscribe = new EventEmitter<void>();
  @Output() onOpenFeedback = new EventEmitter<void>();
  @Output() onOpenProfile = new EventEmitter<void>();
  @Output() onLogout = new EventEmitter<void>();
  lang: string;
  environment = environment;
  constructor(
    private langStoreService: LangStoreService
  ) { }

  ngOnInit() {
    this.environment = environment;
    this.lang = this.langStoreService.getCurrentLang();
  }

  openSubscribe() {
    this.onOpenSubscribe.emit();
  }

  openFeedback() {
    this.onOpenFeedback.emit();
  }

  openProfile() {
    this.onOpenProfile.emit();
  }

  logout() {
    this.onLogout.emit();
  }
}
