<div class="search-regular__header">
  <div class="search-regular__bread-crumbs">
    <a
      href="/"
      class="crumb home"
    ></a>
    <div class="crumb">{{'Поиск преподавателя' | transloco}}</div>
  </div>
  <div class="search-regular__settings" (click)="openViewSettings()">
    
      <ng-container>
        <div
          class="action"
          type="button"
          cdkOverlayOrigin
          #trigger2="cdkOverlayOrigin"
          text="Open menu"
          (click)="openViewSettings()"
        >
        </div>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger2"
        [cdkConnectedOverlayOpen]="viewSettingsOpen"
        [cdkConnectedOverlayPositions]="positionsView"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
        (backdropClick)="closeViewSettings()"
      >
        <div
          class="action overlay"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          text="Open menu"
          (click)="openViewSettings()"
        >
          
        </div>
        <div class="search-regular__menu-popup narrow">
          <div class="title">{{'Вид' | transloco}}</div>
          <div class="grid-view" [ngClass]="{ 'choosen': !isList}" (click)="setIsList(false)">{{'Плитка' | transloco}}</div>
          <div class="list-view" [ngClass]="{ 'choosen': isList}" (click)="setIsList(true)">{{'Список' | transloco}}</div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>


<div class="search-regular__title">{{'Поиск преподавателя' | transloco}}</div>
<div class="search-regular__filters-form">
  <div class="search-regular__filters-row">
    <div class="search-regular__filter full-width">
      <div
        class="option"
        (click)="setFilter(0,'classId')" [ngClass]="{ 'selected-option': filters.classId==0}"
      >{{'Все классы' | transloco}}</div>
      <div
        class="option"
        *ngFor="let class of classesAll"
        (click)="setFilter(class.id,'classId')"
        [ngClass]="{ 'selected-option': filters.classId==class.id}"
      > {{class.name | transloco}}</div>
    </div>
  </div>

  <div class="search-regular__filters-row">
    <div class="search-regular__filter select">
      <mat-form-field [floatLabel]="'always'">
        <mat-label></mat-label>
        <input
          type="text"
          name="discipline"
          (ngModelChange)="onChange()"
          [value]="filters.subject | transloco"
          [(ngModel)]="filters.subject"
          [placeholder]="'Предмет' | transloco"
          matInput
          [formControl]="disciplinesControl"
          [matAutocomplete]="auto"
          [matAutocompletePosition]="autocompletePosition"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          (optionSelected)="onDisciplineChange($event)"
        >
          <mat-option class="discipline-options"
            *ngFor="let discipline of disciplinesFiltered | async"
            [value]="discipline | transloco"
          >
            {{ discipline | transloco }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <mat-form-field
      class="search-regular__filter datetime"
      appearance="outline"
    >
      <input
        #dateFilterInput
        class="ez-date-input"
        readonly
        [placeholder]="'Дата занятия' | transloco"
        matInput
        [(ngModel)]="filters.dateFilter"
        (dateChange)="dateChanged($event.value)"
        [matDatepicker]="picker"
        required
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="picker"
      >
        <img
          matDatepickerToggleIcon
          src="/assets/img/svg/calendar-outline.svg"
          alt="calendar-outline.svg"
        />
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    
    <div class="search-regular__filter time">
      <div class="label">
      {{'Время занятия' | transloco}}
    </div>
    <div class="select">
      <mat-select
              (selectionChange)="setFilter($event.value,'timeFilterMin')"
              class="filter-input"
              [placeholder]="'От' | transloco"
              [value]="filters.timeFilterMin"
              >
                <mat-option class="discipline-options"
                [value]="'08:00'"
                >
                08 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'08:30'"
                >
                08 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'09:00'"
                >
                09 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'09:30'"
                >
                09 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'10:00'"
                >
                10 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'10:30'"
                >
                10 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'11:00'"
                >
                11 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'11:30'"
                >
                11 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'12:00'"
                >
                12 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'12:30'"
                >
                12 - 30
          
                </mat-option>

                <mat-option class="discipline-options"
                [value]="'13:00'"
                >
                13 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'13:30'"
                >
                13 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'14:00'"
                >
                14 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'14:30'"
                >
                14 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'15:00'"
                >
                15 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'15:30'"
                >
                15 - 30
          
                </mat-option>

                <mat-option class="discipline-options"
                [value]="'16:00'"
                >
                16 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'16:30'"
                >
                16 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'17:00'"
                >
                17 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'17:30'"
                >
                17 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'18:00'"
                >
                18 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'18:30'"
                >
                18 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'19:00'"
                >
                19 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'19:30'"
                >
                19 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'20:00'"
                >
                20 - 00
          
                </mat-option>

              </mat-select>
            </div><div class="select">
              <mat-select
              (selectionChange)="setFilter($event.value,'timeFilterMax')"
              class="filter-input"
              [placeholder]="'До' | transloco"
              [value]="filters.timeFilterMax"
              >
                <mat-option class="discipline-options time"
                [value]="'08:00'"
                >
                08 - 00
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'08:30'"
                >
                08 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'09:00'"
                >
                09 - 00
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'09:30'"
                >
                09 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'10:00'"
                >
                10 - 00
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'10:30'"
                >
                10 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'11:00'"
                >
                11 - 00
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'11:30'"
                >
                11 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'12:00'"
                >
                12 - 00
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'12:30'"
                >
                12 - 30
          
                </mat-option>

                <mat-option class="discipline-options time"
                [value]="'13:00'"
                >
                13 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'13:30'"
                >
                13 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'14:00'"
                >
                14 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'14:30'"
                >
                14 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'15:00'"
                >
                15 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'15:30'"
                >
                15 - 30
          
                </mat-option>

                <mat-option class="discipline-options time"
                [value]="'16:00'"
                >
                16 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'16:30'"
                >
                16 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'17:00'"
                >
                17 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'17:30'"
                >
                17 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'18:00'"
                >
                18 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'18:30'"
                >
                18 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'19:00'"
                >
                19 - 00
          
                </mat-option><mat-option class="discipline-options time"
                [value]="'19:30'"
                >
                19 - 30
          
                </mat-option>
                <mat-option class="discipline-options time"
                [value]="'20:00'"
                >
                20 - 00
          
                </mat-option>

              </mat-select>
            </div>
    </div>

    <div class="search-regular__filter price">
      <div class="name">{{'Цена' | transloco}}</div>
      <div class="input"><input
          type="number"
          [(ngModel)]="filters.minPrice"
          placeholder="{{'От' | transloco}}"
        /></div>
      <div class="input"><input
          type="number"
          [(ngModel)]="filters.maxPrice"
          placeholder="{{'До' | transloco}}"
        /></div>
    </div>
  </div>
  <div class="search-regular__filters-row">
    <div class="all-filters__left">
      <div
        class="all-filters" (click)="openAllFilterMenu()"
      >{{'Все параметры' | transloco}}
      <ng-container>
        <div
          class="action"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          text="Open menu"
          (click)="openAllFilterMenu()"
        >
        </div>
  
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="allFiltersMenuOpen"
          [cdkConnectedOverlayPositions]="positions"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeAllFilters()"
        >
          <div
            class="action overlay"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            text="Open menu"
            (click)="openAllFilterMenu()"
          >
            
          </div>
          <div class="search-regular__menu-popup">
            <!--div class="search-regular__menu-filter">
              <div class="label">
                {{'Категория' | transloco}}
              </div>
              <mat-select
              (selectionChange)="setFilter($event.value,'classId')"
              class="filter-input"
              [placeholder]="'Категория' | transloco"
              [value]="filters.classId"
              >
                <mat-option class="discipline-options"
                  *ngFor="let class of classesAll"
                  [value]="class.id"
                >
                  {{ class.name | transloco}}
          
                </mat-option>          
              </mat-select>
            </div>
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Предмет' | transloco}}
              </div>
              <mat-select
              (selectionChange)="setFilter($event.value,'subject')"
              class="filter-input"
              [placeholder]="'Предмет' | transloco"
              [value]="filters.subject"
              >
                <mat-option class="discipline-options"
                *ngFor="let discipline of disciplinesFiltered | async"
                [value]="discipline | transloco"
                >
                  {{ discipline | transloco}}
          
                </mat-option>
          
              </mat-select>
            </div>
            
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Дата занятия' | transloco}}
              </div>
              <mat-form-field
              class="search-regular__filter datetime filter-input"
              appearance="outline"
            >
              <input
                #dateFilterInput
                class="ez-date-input"
                [placeholder]="'Дата занятия' | transloco"
                matInput
                [(ngModel)]="filters.dateFilter"
                (dateChange)="dateChanged($event.value)"
                [matDatepicker]="picker"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              >
                <img
                  matDatepickerToggleIcon
                  src="/assets/img/svg/calendar-outline.svg"
                  alt="calendar-outline.svg"
                />
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            </div>
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Время занятия' | transloco}}
              </div>
              <mat-select
              (selectionChange)="setFilter($event.value,'timeFilter')"
              class="filter-input"
              [placeholder]="'Время занятия' | transloco"
              [value]="filters.timeFilter"
              >
                <mat-option class="discipline-options"
                [value]="'08:00'"
                >
                08 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'08:30'"
                >
                08 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'09:00'"
                >
                09 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'09:30'"
                >
                09 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'10:00'"
                >
                10 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'10:30'"
                >
                10 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'11:00'"
                >
                11 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'11:30'"
                >
                11 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'12:00'"
                >
                12 - 00
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'12:30'"
                >
                12 - 30
          
                </mat-option>

                <mat-option class="discipline-options"
                [value]="'13:00'"
                >
                13 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'13:30'"
                >
                13 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'14:00'"
                >
                14 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'14:30'"
                >
                14 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'15:00'"
                >
                15 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'15:30'"
                >
                15 - 30
          
                </mat-option>

                <mat-option class="discipline-options"
                [value]="'16:00'"
                >
                16 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'16:30'"
                >
                16 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'17:00'"
                >
                17 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'17:30'"
                >
                17 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'18:00'"
                >
                18 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'18:30'"
                >
                18 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'19:00'"
                >
                19 - 00
          
                </mat-option><mat-option class="discipline-options"
                [value]="'19:30'"
                >
                19 - 30
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'20:00'"
                >
                20 - 00
          
                </mat-option>

              </mat-select>
              
            </div>
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Цена' | transloco}}
              </div>
              <div class="input filter-input half">{{'От' | transloco}}<input
                type="number"
                [(ngModel)]="filters.minPrice"
                
              />₽</div>
              <div class="input filter-input half">{{'До' | transloco}}<input
                type="number"
                [(ngModel)]="filters.maxPrice"
              />₽</div>
            </div-->
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Пол' | transloco}}
              </div>
              <mat-select
              (selectionChange)="setFilter($event.value,'sex')"
              class="filter-input"
              [placeholder]="'Пол' | transloco"
              [value]="filters.sex"
              >
                <mat-option class="discipline-options"
                [value]="'Male'"
                >
                  {{ 'Мужчина' | transloco}}
          
                </mat-option>
                <mat-option class="discipline-options"
                [value]="'Female'"
                >
                  {{ 'Женщина' | transloco}}
          
                </mat-option>
          
              </mat-select>
            </div>
            <div class="search-regular__menu-filter">
              <div class="search-regular__menu-filter">
                <div class="label">
                  {{'Возраст' | transloco}}
                </div>
                <div class="input filter-input half">{{'От' | transloco}}<input
                  type="number" 
                  min="18"
                  [(ngModel)]="filters.minAge"
                  
                /></div>
                <div class="input filter-input half">{{'До' | transloco}}<input
                  min="18"
                  type="number"
                  [(ngModel)]="filters.maxAge"
                /></div>
              </div>
            </div>
            <div class="search-regular__menu-filter">
              <div class="label">
                {{'Стаж' | transloco}}
              </div>
              <mat-select
              (selectionChange)="setFilter($event.value,'stage')"
              class="filter-input"
              [placeholder]="'Стаж' | transloco"
              [value]="filters.stage"
              >
              <mat-option class="discipline-options"
              [value]="0"
              >
                {{ 'Нет стажа' | transloco }}
        
              </mat-option>
              <mat-option class="discipline-options"
              [value]="1"
              >
              {{ 'Менее'| transloco }} 1 {{ 'года'| transloco }} 
        
              </mat-option>
              <mat-option class="discipline-options"
              [value]="2"
              >
              1-3 {{ 'года' | transloco }}
        
              </mat-option>
              
              <mat-option class="discipline-options"
              [value]="3"
              >
              3-4 {{ 'года' | transloco }}
        
              </mat-option>
              <mat-option class="discipline-options"
              [value]="4"
              >
              5-10 {{ 'лет' | transloco }}
        
              </mat-option>
              <mat-option class="discipline-options"
              [value]="5"
              >
              {{ 'Больше'| transloco }} 10 {{'лет' | transloco }}
        
              </mat-option>
              </mat-select>
            </div>
          </div>
        </ng-template>
      </ng-container>
    
      </div>
      <div class="sort" (click)="openSortMenu()">
        {{'Сортировка' | transloco}}
        <ng-container>
          <div
            class="action"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            text="Open menu"
            (click)="openSortMenu()"
          >
          </div>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="sortingOpen"
          [cdkConnectedOverlayPositions]="positions"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
          (backdropClick)="closeSortFilters()"
        >
          <div
            class="action overlay"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            text="Open menu"
            (click)="openSortMenu()"
          >
            
          </div>
          <div class="search-regular__menu-popup">
            <mat-radio-group [(ngModel)]="sort.field">              
              <!--mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'registrationDate'"
              >

              {{'По дате регистрации' | transloco}}
              </mat-radio-button>
              <mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'price'"
              >

              {{'По цене' | transloco}}
              </mat-radio-button-->
              <mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'reviews'"
              >

              {{'По отзывам' | transloco}}
              </mat-radio-button>
              <mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'rating'"
              >

              {{'По рейтингу' | transloco}}
              </mat-radio-button>
              <!--mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'popularity'"
              >

              {{'По популярности' | transloco}}
              </mat-radio-button>
              <mat-radio-button class="sort-field" (change)="setSort($event)"
                [value]="'date'"
              >

              {{'По дате' | transloco}}
              </mat-radio-button-->
            </mat-radio-group>            
          </div>
        </ng-template>
      </ng-container>
      </div>
      <div class="reset-filters" (click)="rejectFilters()" 
      *ngIf="filters.dateFilter!= undefined ||
      filters.timeFilterMin!=undefined ||
      filters.timeFilterMax!=undefined ||
      filters.maxPrice!=undefined ||
      filters.minPrice!=undefined ||
      filters.subject!=undefined ||
      filters.classId ||
      filters.sex!=undefined ||
      filters.minAge!=undefined ||
      filters.maxAge!=undefined ||
      filters.stage!=undefined || sort.field!='registrationDate'" >
        {{'Сбросить' | transloco}}
      </div>
    </div>
    <div class="show-all" (click)="showFilters()"><input type="button" class="btn btn--blue" value="{{'Показать записи' | transloco}}"/></div>
  </div>

</div>
<div *ngIf="loaded">
  <!--<div class="filter">
		<div class="filter__title">Найдено организаторов: {{results.totalRecords}}</div>
	</div>-->
  <div
    class="results_none"
    *ngIf="results.totalRecords == 0"
  >
    <div class="global__icon">
      <svg-icon-sprite
        [src]="'icon-smile-bad'"
        [width]="'78px'"
        [height]="'78px'"
        [viewBox]="'0 0 78 78'"
      ></svg-icon-sprite>
    </div>
    <div class="global__title"> {{ 'К сожалению, по вашему запросу нет свободных организаторов' | transloco }}</div>
    <div class="global__text"> {{ 'Попробуйте изменить критериии поиска' | transloco }}</div>
  </div>
  <div
    class="search__list"
    *ngIf="results.totalRecords > 0"
  >
    <tutor-user-search-result
      [item]="item"
      [type]="'regular'"
      [marked]="item.marked"
      (bookmarksUpdated)="bookmarksUpdated($event)"
      [ngClass]="{list:isList}"
      *ngFor="let item of teachers"
    ></tutor-user-search-result>
  </div>
  <div
    class="search__action"
    *ngIf="results.totalPages > results.pageNumber"
  >
    <button class="btn btn--blue">
      <span
        class="btn__label"
        (click)="getMore()"
      > {{ 'Показать еще' | transloco }} </span>
    </button>
  </div>
</div>