<div
  class="messages"
  [ngClass]="{
    'without-note': !note,
    tutor: userdata?.isTutor
  }"
>
  <div class="messages__left">
    <div class="messages__top">
      <a
        class="btn btn--circle btn--border"
        (click)="members()"
      >
        <span class="btn__label">+</span>
      </a>
    </div>
    <div class="messages__list">
      <div
        class="hint_block"
        *ngIf="!this.rooms"
      >
        {{ 'Пока что у вас нет сообщений' | transloco }} . <br />
        {{ 'Но в будущем тут будут отображаться ваши диалоги и адресаты' | transloco }}
      </div>
      <div
        class="messages_list_item"
        [class]="{ current: room.id === roomId }"
        *ngFor="let room of rooms"
        (click)="setRoom(room.id)"
      >
        <div *ngFor="let user of room.chatRoomUsers">
          <div
            class="messages_list_item__author"
            *ngIf="user.id != userId"
          >
            <div class="messages_list_item__image">
              <div
                class="avatar-image"
                *ngIf="image(userAvatarData?.[user.id])"
                [style.background]="image(userAvatarData?.[user.id])"
              ></div>
              <span *ngIf="!image(user)">{{ initials(user) }}</span>
              <div
                class="online"
                *ngIf="user.isOnline"
              ></div>
            </div>
            <div class="messages_list_item__time">{{ getMessageTime(room.messages) }}</div>
            <span
              class="badge"
              *ngIf="unreadMessages[room.id]?.length > 0"
            >{{ unreadMessages[room.id]?.length }}</span>
            <div class="messages_list_item__info">
              <div class="messages_list_item__name">{{ user.name }}</div>
              <div class="messages_list_item__text">
                {{ getLastMessageRoom(room) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="messages__right"
    [ngClass]="{ '--chatIsOpen': roomId }"
  >
    <a
      class="messages__back"
      (click)="clearRoom()"
    >
      <svg-icon-sprite
        [src]="'icon-back'"
        [width]="'33px'"
        [height]="'33px'"
        [viewBox]="'0 0 33 33'"
      ></svg-icon-sprite>
    </a>
    <messages_top
      class="messages__top"
      [roomId]="roomId"
      [roomUser]="roomUser"
      [stat]="stat"
      *ngIf="roomId"
    ></messages_top>

    <div
      class="room"
      [class]="{ isActive: room.id === roomId }"
      *ngFor="let room of rooms"
    >
      <ezteach-group-lesson-chat-merge
        class="widget-call-chat"
        *ngIf="room.id"
        [isMobile]="isMobile"
        (onMessagesChange)="onChangeMessages($event, room.id)"
        (onUnreadMessagesChange)="onChangeUnreadMessages($event, room.id)"
        [isGroupLessonChat]="false"
        [chatRoomId]="room.id"
        [chatIsOpen]="room.id === roomId"
        [roomIdChangeTrigger]="room.id === roomId"
      ></ezteach-group-lesson-chat-merge>
    </div>

    <div
      class="messages_none"
      *ngIf="!rooms"
    >
      <div class="messages_none__wrap">
        <div class="messages_none__icon">
          <img src="/assets/img/content/icon_messages.svg" />
        </div>
        <div class="messages_none__title">{{ 'Еще никто не написал' | transloco }}?</div>
        <div class="messages_none__text">
          {{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' | transloco }}
        </div>
        <div class="messages_none__action">
          <a
            class="btn btn--blue"
            (click)="members()"
          >
            <span class="btn__label"> {{ 'Новое сообщение' | transloco }}</span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="messages_none"
      *ngIf="rooms && !roomId"
    >
      <div class="messages_none__wrap">
        <div class="messages_none__icon">
          <img src="/assets/img/content/icon_messages.svg" />
        </div>
        <div class="messages_none__title">{{ 'Выберите чат, чтобы начать переписку' | transloco }}</div>
      </div>
    </div>
  </div>
</div>