import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { ActiveService } from '@ezteach/_services/active.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { OrientationService } from '@ezteach/_services/orientation.service';
import { RequestsService } from '@ezteach/_services/requests.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from '@ezteach/_services/user.service';
import { ChatRequest } from '@ezteach/api/models';
import { LessonsService, NotificationsService } from '@ezteach/api/services';
import { ModalLogoutConfirm } from '@ezteach/modals/logout-confirm/modal-logout-confirm.component';
import { NOTIFICATION_IDS_NEW_KEY, NotificationsComponent } from '@ezteach/notifications/notifications.component';
import { LangStoreService, Langs } from '@ezteach/shared/services/lang-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { OrientationType } from 'ngx-device-detector';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DialogService } from '../../_services/dialog.service';
import { LocalStorageService } from '../../_services/local-storage.service';

@UntilDestroy()
@Component({
  selector: 'header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() isTutor: boolean;

  userdata;

  @ViewChild(MatTooltip) tooltip: MatTooltip;

  matTooltipDisabled = true;
  activeTemporarySolution: boolean;
  excludedRoutes = ['wait', 'lesson'];
  includesRoutes = ['mylessons'];
  showOnline: boolean;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  isMobile: boolean;
  isUserMenuOpen = false;
  isUnwatchedNotifications = false;
  private readonly updateNotifications$ = new Subject<void>();

  hideHeader = false;

  profileMenuPositions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 4),
  ];

  mobileProfileMenuPositions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 4),
  ];
  lang = Langs.RU;
  avatarBack: string;
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly dialog: DialogService,
    private readonly activeService: ActiveService,
    private readonly matDialog: MatDialog,
    private readonly lessonsService: LessonsService,
    private readonly oauthService: OAuthService,
    private readonly notificationsService: NotificationsService,
    private readonly signalrService: SignalrService,
    private readonly requestsService: RequestsService,
    private orientationService: OrientationService,
    private userService: UserService,
    private langStoreService: LangStoreService
  ) { }

  ngOnInit() {
    this.lang = this.langStoreService.getCurrentLang();
    this.updateNotifications$
      .pipe(
        debounceTime(1000),
        switchMap(() => this.notificationsService.apiV1NotificationsNewGet({})),
        tap(result => {
          this.isUnwatchedNotifications = result.data.length > 0;
          this.localStorageService.set(NOTIFICATION_IDS_NEW_KEY, result.data);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    this.updateNotifications$.next();
    this.signalrService.onUserNotificationCreated
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateNotifications$.next());
    this.signalrService.onUserNotificationUpdated
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateNotifications$.next());

    this.signalrService.onUserLogOut
      .pipe(
        filter(x => x?.arguments?.indexOf(x => x?.userId === this.userService.userData$?.value?.id) !== -1),
        untilDestroyed(this),
      )
      .subscribe(x => {
        this.logout();
      });

    this.activeService.activeTemporarySolution$
      .pipe(untilDestroyed(this))
      .subscribe(v => (this.activeTemporarySolution = v));
    this.isOnlineBarVisible();

    this.requestsService.onChanged
      .pipe(
        untilDestroyed(this),
        tap(data => {
          if (this.isTutor) {
            const requests: ChatRequest[] = this.localStorageService.get('requests') ?? [];
            this.isUnwatchedNotifications = requests.length > 0;
          }
        }),
      )
      .subscribe();

    this.router.events
      .pipe(
        tap(val => {
          this.isOnlineBarVisible();
          document.querySelector('aside').classList.remove('is-open-menu');
          document.querySelector('main').classList.remove('is-open-menu');
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.mediaQueryService.match$.pipe(untilDestroyed(this)).subscribe(value => (this.isMobile = value));

    this.layoutSubscribe();
    this.userService.userData$
      .pipe(
        untilDestroyed(this),
        tap(data => {
          this.userdata = data;
        })
      ).subscribe();
  }

  isOnlineBarVisible() {
    const currentUrl = this.router.url;
    this.showOnline =
      this.includesRoutes.some(v => currentUrl.includes(v)) || !this.excludedRoutes.some(v => currentUrl.includes(v));
  }

  openFeedbackDialog() {
    this.toggleMenu();
    this.dialog.openFeedbackDialog();
  }

  handleToggleButtonClick() {
    document.querySelector('aside').classList.toggle('is-open-menu');
    document.querySelector('main').classList.toggle('is-open-menu');
  }

  toggleMenu(): void {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  openModalLogoutConfirm() {
    this.matDialog
      .open(ModalLogoutConfirm, {
        panelClass: 'modal-logout-confirm',
        data: {
          isTutor: this.isTutor,
        },
      })
      .afterClosed()
      .pipe(
        tap(resp => {
          if (resp === 'logout') {
            this.logout();
          }
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  openNotificationDialog(): void {
    this.matDialog
      .open(NotificationsComponent, {
        width: this.isMobile ? '100%' : '544px',
        position: { top: '64px', right: this.isMobile ? undefined : '32px' },
      })
      .afterClosed()
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }

  isParticipateActiveLesson() {
    this.lessonsService
      .apiV1LessonsGet()
      .pipe(
        map(({ data }) => data && data.length > 0),
        tap(isParticipate => {
          if (isParticipate) {
            this.openModalLogoutConfirm();
          } else {
            this.logout();
            this.toggleMenu();
          }
        }),
        take(1),
        untilDestroyed(this),
      )
      .subscribe();
  }

  layoutSubscribe() {
    const orientation$ = this.orientationService.orientation$;
    const isMobile$ = this.mediaQueryService.match$;
    const routeEvent$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    combineLatest([orientation$, isMobile$, routeEvent$])
      .pipe(untilDestroyed(this))
      .subscribe(([orientation, isMobile, routeEvent]) => {
        const end = routeEvent as NavigationEnd;
        if (isMobile && orientation === OrientationType.Landscape && end?.url?.indexOf('group-lesson') !== -1) {
          this.hideHeader = true;
        } else {
          this.hideHeader = false;
        }
      });
  }

  logout(): void {
    this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
    this.localStorageService.clear();
    this.localStorageService.set('CurrentLang', this.lang);
  }
  setLogo() {
    switch (this.lang) {
      case Langs.EN: return 'header-logotype-easytalk-en';
      case Langs.KK: return 'header-logotype-easytalk-kk';
      default: return 'header-logotype-easytalk';
    }
  }
}
