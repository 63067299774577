import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@ezteach/api/api-configuration';
import { BaseService } from '@ezteach/api/base-service';
import {
  BaseApiResponse,
  LessonPaymentTypeEnum,
  ScheduledLessonApiResponse,
  ScheduledLessonStatusEnum
} from '@ezteach/api/models';
import { RequestBuilder } from '@ezteach/api/request-builder';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { CalendarEventApiResponse, CalendarEventIEnumerableApiResponse } from '@ezteach/calendar/models';
import { SelectedPublishingPolicy } from '@ezteach/group-lesson/services/group-lesson.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CalendarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1CalendarEventsGet
   */
  static readonly ApiV1CalendarEventsGetPath = '/api/v1/calendar/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarEventsGet()` instead.
   *
   * This method doesn't expect any request body.
   */

  apiV1CalendarScheduledLessonId(params: {
    scheduledLessonId: number;
    homeWorkTemplateId?: number;
  }): Observable<number> {
    let url = `${this.rootUrl}/api/v1/calendar/${params.scheduledLessonId}/homework`;
    if (params.homeWorkTemplateId != null) {
      url += `?homeWorkTemplateId=${params.homeWorkTemplateId}`;
    }
    return this.http.patch<number>(url, null);
  }
  apiV1CalendarEventsGet$Response(params?: {
    LinkedMemberId?: null | number;
    StartDate?: string;
    EndDate?: string;
    CalendarMode?;
  }): Observable<StrictHttpResponse<CalendarEventIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarEventsGetPath, 'get');
    if (params) {
      rb.query('LinkedMemberId', params.LinkedMemberId, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('CalendarMode', params.CalendarMode, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => { console.log(r); return r instanceof HttpResponse }),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarEventsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarEventsGet(params?: {
    LinkedMemberId?: null | number;
    StartDate?: string;
    EndDate?: string;
    CalendarMode?;
  }): Observable<CalendarEventIEnumerableApiResponse> {
    return this.apiV1CalendarEventsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CalendarEventIEnumerableApiResponse>) => r.body as CalendarEventIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1CalendarSlotsGet
   */
  static readonly ApiV1CalendarSlotsGetPath = '/api/v1/calendar/slots';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarSlotsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarSlotsGet$Response(params?: {
    LinkedMemberId?: null | number;
    StartDate?: string;
    EndDate?: string;
    CalendarMode?;
  }): Observable<StrictHttpResponse<CalendarEventIEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarSlotsGetPath, 'get');
    if (params) {
      rb.query('LinkedMemberId', params.LinkedMemberId, {});
      rb.query('StartDate', params.StartDate, {});
      rb.query('EndDate', params.EndDate, {});
      rb.query('CalendarMode', params.CalendarMode, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventIEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarSlotsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarSlotsGet(params?: {
    LinkedMemberId?: null | number;
    StartDate?: string;
    EndDate?: string;
    CalendarMode?;
  }): Observable<CalendarEventIEnumerableApiResponse> {
    return this.apiV1CalendarSlotsGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CalendarEventIEnumerableApiResponse>) => r.body as CalendarEventIEnumerableApiResponse,
      ),
    );
  }

  /**
   * Path part for operation apiV1CalendarSlotsPost
   */
  static readonly ApiV1CalendarSlotsPostPath = '/api/v1/calendar/slots';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarSlotsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarSlotsPost$Response(params?: {
    body?: { StartDate?: string; EndDate?: string };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarSlotsPostPath, 'post');
    rb.body(params.body, 'multipart/form-data');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarSlotsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarSlotsPost(params?: {
    body?: { StartDate?: string; EndDate?: string };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarSlotsPost$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarSlotsEventIdPut
   */
  static readonly ApiV1CalendarSlotsEventIdPutPath = '/api/v1/calendar/slots/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarSlotsEventIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarSlotsEventIdPut$Response(params: {
    eventId: number;
    body?: { Id?: number; StartDate?: string; EndDate?: string };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarSlotsEventIdPutPath, 'put');
    if (params) {
      rb.path('eventId', params.eventId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarSlotsEventIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarSlotsEventIdPut(params: {
    eventId: number;
    body?: { Id?: number; StartDate?: string; EndDate?: string };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarSlotsEventIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarSlotsEventIdDelete
   */
  static readonly ApiV1CalendarSlotsEventIdDeletePath = '/api/v1/calendar/slots/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarSlotsEventIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarSlotsEventIdDelete$Response(params: {
    eventId: number;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarSlotsEventIdDeletePath, 'delete');
    if (params) {
      rb.path('eventId', params.eventId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarSlotsEventIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarSlotsEventIdDelete(params: { eventId: number }): Observable<BaseApiResponse> {
    return this.apiV1CalendarSlotsEventIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarLessonsPost
   */
  static readonly ApiV1CalendarLessonsPostPath = '/api/v1/calendar/lessons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarLessonsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarLessonsPost$Response(params?: {
    body?: {
      LessonDate?: string;
      LessonStartTime?: string;
      LessonEndTime?: string;
      TutorUserId?: number;
      DisciplineId?: number;
    };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarLessonsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarLessonsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarLessonsPost(params?: {
    body?: {
      LessonDate?: string;
      LessonStartTime?: string;
      LessonEndTime?: string;
      TutorUserId?: number;
      DisciplineId?: number;
    };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarLessonsPost$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarLessonsLessonIdGet
   */
  static readonly ApiV1CalendarLessonsLessonIdGetPath = '/api/v1/calendar/lessons/{lessonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarLessonsLessonIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdGet$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<ScheduledLessonApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarLessonsLessonIdGetPath, 'get');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<ScheduledLessonApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarLessonsLessonIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdGet(params: { lessonId: number }): Observable<ScheduledLessonApiResponse> {
    return this.apiV1CalendarLessonsLessonIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<ScheduledLessonApiResponse>) => r.body as ScheduledLessonApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarLessonsLessonIdPut
   */
  static readonly ApiV1CalendarLessonsLessonIdPutPath = '/api/v1/calendar/lessons/{lessonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarLessonsLessonIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarLessonsLessonIdPut$Response(params: {
    lessonId: number;
    body?: {
      Id?: number;
      PricePerMinute?: number;
      LessonDate?: string;
      LessonStartTime?: string;
      LessonEndTime?: string;
      DisciplineId?: number;
      DisciplineName?: string;
      StudentUserId?: number;
      StudentName?: string;
      TutorUserId?: number;
      TutorName?: string;
      StatusId?: ScheduledLessonStatusEnum;
      CalendarMode?;
    };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarLessonsLessonIdPutPath, 'put');
    if (params) {
      rb.path('lessonId', params.lessonId, {});

      rb.body(params.body, 'multipart/form-data');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarLessonsLessonIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1CalendarLessonsLessonIdPut(params: {
    lessonId: number;
    body?: {
      Id?: number;
      PricePerMinute?: number;
      LessonDate?: string;
      LessonStartTime?: string;
      LessonEndTime?: string;
      DisciplineId?: number;
      DisciplineName?: string;
      StudentUserId?: number;
      StudentName?: string;
      TutorUserId?: number;
      TutorName?: string;
      StatusId?: ScheduledLessonStatusEnum;
      CalendarMode?;
    };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarLessonsLessonIdPut$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarLessonsLessonIdCancelPatch
   */
  static readonly ApiV1CalendarLessonsLessonIdCancelPatchPath = '/api/v1/calendar/lessons/{lessonId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarLessonsLessonIdCancelPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdCancelPatch$Response(params: {
    lessonId: number;
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarLessonsLessonIdCancelPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarLessonsLessonIdCancelPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdCancelPatch(params: { lessonId: number }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarLessonsLessonIdCancelPatch$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  /**
   * Path part for operation apiV1CalendarLessonsLessonIdAcceptPatch
   */
  static readonly ApiV1CalendarLessonsLessonIdAcceptPatchPath = '/api/v1/calendar/lessons/{lessonId}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CalendarLessonsLessonIdAcceptPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdAcceptPatch$Response(params: {
    lessonId: number;
    paymentType: LessonPaymentTypeEnum;
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarLessonsLessonIdAcceptPatchPath, 'patch');
    if (params) {
      rb.path('lessonId', params.lessonId, {});

      if (params.paymentType == LessonPaymentTypeEnum.Free) {
        rb.query('lessonPaymentType', params.paymentType);
      }
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1CalendarLessonsLessonIdAcceptPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CalendarLessonsLessonIdAcceptPatch(params: {
    lessonId: number;
    paymentType: LessonPaymentTypeEnum;
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarLessonsLessonIdAcceptPatch$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  static readonly ApiV1CalendarGroupPostPath = '/api/v1/calendar/lessons/group';

  apiV1CalendarGroupPost$Response(params: {
    body?: {
      lessonDate: string;
      lessonStartTime: string;
      lessonEndTime: string;
      disciplineId: number;
      publishingPolicy: SelectedPublishingPolicy;
      invitedUsers: number[];
      homeWork_template_id: number;
    };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarGroupPostPath, 'post');
    rb.body({ ...params.body, lessonWillBeRecorded: params.body.publishingPolicy.lessonWillbeRecorded }, 'application/json');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  apiV1CalendarGroupPost(params: {
    body: {
      lessonDate: string;
      lessonStartTime: string;
      lessonEndTime: string;
      disciplineId: number;
      publishingPolicy: SelectedPublishingPolicy;
      invitedUsers: number[];
      subject: string;
      homeWork_template_id: number;
    };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarGroupPost$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  static readonly ApiV1CalendarUpdateScheduledLessonPath = '/api/v1/calendar/lessons/{lessonId}';

  apiV1CalendarUpdateScheduledLesson$Response(params: {
    lessonId: number;
    body?: {
      lessonDate: string;
      lessonStartTime: string;
      lessonEndTime: string;
      disciplineId: number;
      publishingPolicy: SelectedPublishingPolicy;
      invitedUsers: number[];
    };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarUpdateScheduledLessonPath, 'put');
    rb.body({ ...params.body, lessonWillBeRecorded: params.body.publishingPolicy.lessonWillbeRecorded }, 'application/json');
    if (params.body) {
      rb.path('lessonId', params.lessonId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  apiV1CalendarUpdateScheduledLesson(params: {
    lessonId: number;
    body: {
      lessonDate: string;
      lessonStartTime: string;
      lessonEndTime: string;
      disciplineId: number;
      publishingPolicy: SelectedPublishingPolicy;
      invitedUsers: number[];
    };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarUpdateScheduledLesson$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }

  static readonly ApiV1CalendarUpdateSlotPath = '/api/v1/calendar/slots/{eventId}';

  apiV1CalendarUpdateSlot$Response(params: {
    eventId: number;
    body?: {
      Id: number;
      startDate: Date | string;
      endDate: Date | string;
    };
  }): Observable<StrictHttpResponse<CalendarEventApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarService.ApiV1CalendarUpdateSlotPath, 'put');
    rb.body(params.body, 'multipart/form-data');

    if (params) {
      rb.path('eventId', params.eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<CalendarEventApiResponse>),
      );
  }

  apiV1CalendarUpdateSlot(params: {
    eventId: number;
    body: {
      Id: number;
      startDate: Date | string;
      endDate: Date | string;
    };
  }): Observable<CalendarEventApiResponse> {
    return this.apiV1CalendarUpdateSlot$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventApiResponse>) => r.body as CalendarEventApiResponse),
    );
  }
}
